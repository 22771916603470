import React from "react";
import {
  SimpleForm,
  Create,
  Datagrid,
  Edit,
  List,
  TextField,
  TextInput,
  Filter,
  RichTextField 
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';

const NotificationFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <TextInput label="Pesquisar" source="search" alwaysOn />
  </Filter>
);

const required = (message = "Required") => (value) =>
  value ? undefined : message;

export const NotificationList = ({ permissions, ...props }) => (
  <List
    {...props}
    exporter={false}
    bulkActionButtons={false}
    title="Notificações"
    filters={<NotificationFilter permissions={permissions} />}
  >
    <Datagrid rowClick="edit">
      <TextField source="title" label="Título" />
      <RichTextField source="body" label="Descrição" />
    </Datagrid>
  </List>
);

export const NotificationEdit = ({ permissions, ...props }) => {
  return (
    <Edit title="Editar notificação" {...props}>
      <SimpleForm>
        <TextInput source="title" label="Título" validate={required()} />
        <RichTextInput source="body" label="Descrição" validate={required()}/>
      </SimpleForm>
    </Edit>
  );
};

export const NotificationCreate = ({ permissions, ...props }) => {
  return (
    <Create title="Nova Notificação" {...props}>
      <SimpleForm>
        <TextInput source="title" label="Título" validate={required()} />
        <RichTextInput source="body" label="Descrição" validate={required()}/>
      </SimpleForm>
    </Create>
  );
};
