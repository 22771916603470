import React from 'react';
import PropTypes from 'prop-types';
import { format } from '@fnando/cpf';
import { formatCellphone, formatCnpj, formatId } from "../utils/utils";

export const CNPJField = ({ source, record = {} }) => <span>{formatCnpj(record[source])}</span>;

CNPJField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

CNPJField.defaultProps = {
  addLabel: true,
};

export const CPFField = ({ source, record = {} }) => <span>{format(record[source])}</span>;

CPFField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

CPFField.defaultProps = {
  addLabel: true,
};

export const PhoneField = ({ source, record = {} }) => <span>{formatCellphone(record[source])}</span>;

PhoneField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

PhoneField.defaultProps = {
  addLabel: true,
};

export const IdField = ({ source, record = {} }) => <span>{formatId(record[source])}</span>;

IdField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

IdField.defaultProps = {
  addLabel: true,
};