import React from 'react';
import { Create, Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { parse } from 'query-string';

const required = (message = 'Required') =>
  value => value || value === 0 ? undefined : message;

const minValue = (message = 'Pontos por litro não deve ser negativo') =>
  value => !value || value >= 0 ? undefined : message;

export const FuelEdit = (props) => (
  <Edit title='Editar combustível filho' {...props}>
    <SimpleForm redirect={`/parent-fuels`}>
      <TextInput disabled label="ID" source="id" />
      <TextInput source="acronym" label="Sigla" maxlength="3" />
      <TextInput source="name" label="Nome" />
      <NumberInput source="pointsPerLiter" label='Quantidade de pontos por litro' validate={minValue()} />
      <NumberInput source="fuelType" label='Tipo do combustível (ConectTec)' />
      <ReferenceInput label="Combustível pai" source="parentFuelId" reference="parent-fuels" perPage={false}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const FuelCreate = (props) => {
  const { parentFuelId, acronym } = parse(props.location.search);
  return (
    <Create title='Adicionar combustível' {...props}>
      <SimpleForm defaultValue={{ parentFuelId, acronym }} redirect={`/parent-fuels`}>
        <TextInput source="acronym" label="Sigla" maxLength="3" validate={required()} />
        <TextInput source="name" label="Nome" validate={required()} />
        <NumberInput source="pointsPerLiter" label='Quantidade de pontos por litro' validate={[minValue(), required()]} />
        <NumberInput source="fuelType" label='Tipo do combustível (ConectTec)' />
        <ReferenceInput label="Combustível pai" source="parentFuelId" reference="parent-fuels" perPage={false}>
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
};