import React, { useState } from "react";
import { StateInput, GenderInput, CEPInput, CPFInput, PhoneInput } from "../utils/Inputs";
import { CPFField, PhoneField } from "../components/Fields";
import BalanceButton from '../components/BalanceButton/BalanceButton'
import Permission from "../components/Permission";
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  DeleteButton,
  List,
  TextField,
  TextInput,
  TabbedForm,
  FormTab,
  email,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Pagination,
  EmailField,
  NumberField,
  FunctionField,
  TopToolbar,
} from "react-admin";
import { FillinDatagrid } from "./Fillin";
import { VehicleDatagrid } from "./Vehicle";
import { SupportTicketDatagrid } from "./SupportTicket";
import { SearchFilter } from "../components/SearchFilter";
import { RatingDatagrid } from "./Rating";
import { dateInputFormatter } from "../utils/utils";
import { BlockCustomerButton } from "../components";

const TransactionType = {
  refuel: 'refuel',
  redemption: 'redemption',
  adition: 'adition',
  discount: 'discount',
  marketing: 'marketing',
}

const transform = data => {
  delete data.balance;
  return data;
}

const translateTransactionType = (type) => {
  switch (type) {
    case TransactionType.refuel:
      return 'Abastecimento';
    case TransactionType.redemption:
      return 'Resgate';
    case TransactionType.adition:
      return 'Adição';
    case TransactionType.discount:
      return 'Desconto';
    case TransactionType.marketing:
      return 'Recompensa';
    default: return "";
  }
}

const required = (message = "Required") => value =>
  value ? undefined : message;

export const CustomerList = ({ permissions, ...props }) => (
  <List {...props} title="Clientes" filters={<SearchFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <CPFField source="cpf" label="CPF" sortable={false} />
      <PhoneField source="cellphone" label="Telefone" />
      <TextField source="balance.balance" label="Saldo" sortBy="balance" />
      <DateField
        source="lastLogin"
        label="Último login"
        locales="pt-BR"
        showTime
      />
      {permissions === "admin" && <EditButton />}
      {permissions === "admin" && <DeleteButton />}
    </Datagrid>
  </List >
);

const ShowPassText = props => (
  <p
    onClick={() => props.onClick()}
    style={{
      margin: 0,
      padding: 0,
      color: "#303f9f",
      fontSize: 10,
      width: 85,
      cursor: "pointer"
    }}
  >
    {props.showPass ? "Esconder a senha" : "Mostrar senha"}
  </p>
);

const EditActions = (props) => {
  const {
    data,
  } = props;
  return (
    <TopToolbar>
      {props && props.data &&
        <Permission permission="admin">
          <div style={{ display: 'flex' }}>
            <BalanceButton clientId={props.data.id} style={{ marginRight: 20 }} />
            <BlockCustomerButton customer={data} />
          </div>
        </Permission>
      }
    </TopToolbar>
  );
}
export const CustomerEdit = props => {
  const [showPass, setShowPass] = useState(false);

  return (
    <Edit title="Editar cliente" {...props} transform={transform} actions={<EditActions />}>
      <TabbedForm>
        <FormTab label="Identificação">
          <TextInput disabled label="ID" source="id" />
          <TextInput source="name" label="Nome" />
          <TextInput source="email" type="email" validate={email()} />
          <DateInput
            source="birthDate"
            type="date"
            label="Data de Nascimento"
            format={dateInputFormatter}
          />
          <TextInput
            source="password"
            type={showPass ? "text" : "password"}
            label="Senha"
          />
          <ShowPassText
            onClick={() => setShowPass(!showPass)}
            showPass={showPass}
          />
          <CPFInput
            source="cpf"
            label="CPF"
          />
          <PhoneInput
            source="cellphone"
            label="Telefone"
            validate={required()}
          />
          <GenderInput source="gender" label="Sexo" />
        </FormTab>
        <FormTab label="Endereço">
          <TextInput source="street" label="Rua" />
          <TextInput source="streetNumber" label="Número" />
          <TextInput source="complement" label="Complemento" />
          <TextInput source="neighbourhood" label="Bairro" />
          <TextInput source="city" label="Cidade" />
          <StateInput source="state" label="Estado" />
          <CEPInput source="cep" label="CEP" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const CustomerCreate = props => (
  <Create title="Adicionar cliente" {...props}>
    <TabbedForm>
      <FormTab label="Identificação">
        <TextInput source="name" label="Nome" validate={required()} />
        <TextInput
          source="email"
          type="email"
          validate={[required(), email()]}
        />
        <DateInput
          source="birthDate"
          type="date"
          label="Data de Nascimento"
          format={dateInputFormatter}
        />
        <CPFInput
          source="cpf"
          label="CPF"
          validate={required()}
        />
        <PhoneInput
          source="cellphone"
          label="Telefone"
          validate={required()}
        />
        <GenderInput source="gender" label="Sexo" />
        <TextInput
          source="password"
          label="Senha"
          type="password"
          defaultValue=""
          validate={required()}
        />
      </FormTab>
      <FormTab label="Endereço">
        <TextInput source="street" label="Rua" />
        <TextInput source="streetNumber" label="Número" />
        <TextInput source="complement" label="Complemento" />
        <TextInput source="neighbourhood" label="Bairro" />
        <TextInput source="city" label="Cidade" />
        <StateInput source="state" label="Estado" />
        <CEPInput source="cep" label="CEP" />
      </FormTab>
    </TabbedForm>
  </Create>
);

const CustomerName = ({ record }) => {
  return <span>{record.name}</span>;
};

export const CustomerShow = ({ permissions, ...props }) => (
  <Show title={<CustomerName />} {...props}>
    <TabbedShowLayout>
      <Tab label="Resumo">
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <CPFField source="cpf" label="CPF" />
        <EmailField source="email" />
        <PhoneField source="cellphone" label="Telefone" />
        <TextField source="balance.balance" label="Saldo" />
        <DateField
          source="lastLogin"
          label="Último login"
          locales="pt-BR"
          showTime
        />
      </Tab>

      <Tab label="Veículos" path="vehicles">
        <ReferenceManyField
          reference="vehicles"
          target="customerId"
          addLabel={false}
          pagination={<Pagination />}
        >
          <VehicleDatagrid permissions={permissions} />
        </ReferenceManyField>
      </Tab>

      <Tab label="Abastecimentos" path="fillins">
        <ReferenceManyField
          reference="fillins"
          target="customerId"
          addLabel={false}
          filter={permissions === "manager" && { chainId: localStorage.getItem("chainId") }}
          sort={{ field: "createdAt", order: "DESC" }}
          pagination={<Pagination />}
        >
          <FillinDatagrid permissions={permissions} />
        </ReferenceManyField>
      </Tab>
      {permissions === "admin" && (
        <Tab label="Chamados" path="support-tickets">
          <ReferenceManyField
            reference="support-tickets"
            target="customerId"
            addLabel={false}
            sort={{ field: "createdAt", order: "DESC" }}
            pagination={<Pagination />}
          >
            <SupportTicketDatagrid />
          </ReferenceManyField>
        </Tab>
      )}
      <Tab label="Avaliações" path="ratings">
        <ReferenceManyField
          reference="ratings"
          addLabel={false}
          target="customerId"
          sort={{ field: 'createdAt', order: 'DESC' }}
          pagination={<Pagination />}
        >
          <RatingDatagrid />
        </ReferenceManyField>
      </Tab>
      {permissions === "admin" &&
        <Tab label="Extrato" path="statement">
          <ReferenceManyField
            reference="transactions"
            addLabel={false}
            target="customerId"
            sort={{ field: 'createdAt', order: 'DESC' }}
            pagination={null}
            perPage={null}
          >
            <Datagrid  {...props}>
              <NumberField source="value" label="Valor" />
              <FunctionField render={record => translateTransactionType(record.type)} label='Tipo' />
              <NumberField source="balance" label="Saldo Parcial" />
              <DateField source="createdAt" label="Data" locales="pt-BR" showTime />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      }
    </TabbedShowLayout>
  </Show>
);
