import React, { Fragment, useEffect, useState } from "react";
import { useNotify } from 'react-admin';
import { ReportLayout, useReportContext, GenericDatagrid, GenericDatagridField, GenericSelect } from '../../components';
import { format as formatCpf } from '@fnando/cpf';
import moment from 'moment';

const Coupons = ({ couponSelect }) => {
    const { data, error, setSort, sort, pagination, setPagination } = useReportContext();
    const notify = useNotify();
    const [couponData, setCouponData] = useState([]);
    const [totalData, setTotalData] = useState();

    if (error) { notify('Erro ao carregar Relatório de Cupons', 'warning'); };

    useEffect(() => {
        if (data && data.data) {
            if (couponSelect === 'redeemed') {
                setCouponData(data.data.filter(c => c.redeemed === true));
            } else if (couponSelect === 'active') {
                setCouponData(data.data.filter(c => c.redeemed === false));
            } else {
                setCouponData(data.data);
            }
        }
    }, [couponSelect, data])

    useEffect(() => {
        const formattedData = { coupons: 0, points: 0 };
        formattedData.coupons = couponData.length || '0';
        formattedData.points = couponData.reduce((acc, cur) => acc += cur.value, 0) || '0';

        setTotalData([formattedData]);
    }, [couponData]);


    return (
        <Fragment>
            <GenericDatagrid
                style={{ margin: '0 0 50px 0' }}
                data={totalData && totalData}
                title='Totais'
            >
                <GenericDatagridField
                    source='coupons'
                    label='Cupons'
                />
                <GenericDatagridField
                    source='points'
                    label='Pontos'
                />
            </GenericDatagrid>
            <GenericDatagrid
                data={couponData}
                title='Relatório de Cupons'
                textAlign='center'
                sort={sort}
                setSort={setSort}
                pagination={pagination}
                setPagination={setPagination}
                total={data && data.total}
            >
                <GenericDatagridField
                    source='createdAt'
                    label='Emissão'
                    render={({ createdAt }) => createdAt ? moment(createdAt).format('DD/MM/YYYY    hh:mm') : ''}
                />
                <GenericDatagridField
                    source='product.name'
                    label='Produto'
                    render={({ product: {name} }) => name ? name : 'Não identificado'}
                />
                <GenericDatagridField
                    source='redeemedAt'
                    label='Resgate'
                    render={({ redeemedAt }) => redeemedAt ? moment(redeemedAt).format('DD/MM/YYYY    hh:mm') : 'Não resgatado'}
                />
                <GenericDatagridField
                    source='login'
                    label='Funcionário'
                    render={({ employee }) => `${employee ? employee.login : 'Não identificado'}`}
                />
                <GenericDatagridField
                    source='name'
                    label='Nome do Cliente'
                    render={({ transaction: { customer } }) => `${customer ? customer.name : ''}`}
                />
                <GenericDatagridField
                    label='CPF do Cliente'
                    render={({ transaction: { customer } }) => customer ? formatCpf(customer.cpf) : ''}
                    unsortable
                />
                <GenericDatagridField
                    label='Local'
                    render={({ place }) => place ? place.name : ''}
                    unsortable
                />
            </GenericDatagrid>
        </Fragment>
    );
};

const CouponsReport = () => {
    const [couponSelect, setcouponSelect] = useState('all');
    return (
        <ReportLayout
            permissions={['admin', 'manager', 'cashier', 'partner']}
            chainSelect
            placeSelect={localStorage.getItem('role') !== 'partner'}
            resource='coupons'
            initialSort={{ field: 'createdAt', order: 'DESC' }}
            initialPagination={{ page: 1, perPage: 10 }}
            filtersValue={(localStorage.getItem('role') === 'cashier') && { employeeId: localStorage.getItem('id') }}
            filtersComponent={(localStorage.getItem('role') !== 'cashier') && [
                <GenericSelect
                    label='Cupom'
                    options={[{ id: 'redeemed', name: 'Resgatados' }, { id: 'active', name: 'Ativos' }, { id: 'all', name: 'Todos' }]}
                    value={couponSelect}
                    setValue={setcouponSelect}
                />
            ]}
        >
            <Coupons couponSelect={couponSelect} />
        </ReportLayout>
    );
}

export default CouponsReport;