import React from 'react';
import {
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  Pagination,
  NumberField,
  List,
  ReferenceField
} from 'react-admin';
import { SearchFilter } from '../components/SearchFilter';

import { FillinDatagrid } from "./Fillin";

const VehicleTitle = ({ record }) => {
  return <span>{`${record.nickname} - ${record.licensePlate}`}</span>;
};

export const VehicleDatagrid = ({ permissions, ...props }) => (
  <Datagrid rowClick="show" {...props}>
    <TextField source="nickname" label="Apelido" />
    <TextField source="licensePlate" label="Placa" />
    <NumberField source="odometer" label="Odômetro" locales="pt-BR" />
  </Datagrid>
);


export const VehicleShow = ({ permissions, ...props }) => (
  <Show title={<VehicleTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Resumo">
        <TextField source="id" label="ID" />
        <TextField source="nickname" label="Apelido" />
        <TextField source="licensePlate" label="Placa" />
        <NumberField source="odometer" label="Odômetro" locales="pt-BR" />
      </Tab>

      <Tab label="Abastecimentos" path="fillins">
        <ReferenceManyField
          pagination={<Pagination />}
          reference="fillins"
          target="vehicleId"
          addLabel={false}
          filter={permissions === 'manager' ? { chainId: localStorage.getItem('chainId') } : {}}
          sort={{ field: "createdAt", order: "DESC" }}
        >
          <FillinDatagrid permissions={permissions} />
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const VehicleList = (props) => (
  <List {...props} title="Veículos" filters={<SearchFilter />} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="licensePlate" label="Placa" />
      <TextField source="model" label="Modelo" emptyText="Não identificado" />
      <ReferenceField reference="customers" source="ownerId" label="Cliente" link="show">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);