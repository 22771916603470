import React from 'react';
import {
  Button,
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  SearchInput,
  SelectInput,
  TextField,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import ListPagination from '../components/ListPagination';

const PotencialFraudFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    <SelectInput
      source="isOpen"
      label='Situação'
      choices={[
        { id: true, name: 'Em aberto' },
        { id: false, name: 'Fechadas' }
      ]}
      allowEmpty
      emptyText="Todos"
      alwaysOn
    />
  </Filter>
);

export const PotencialFraudList = props => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const doUpdate = ({ resource, id, data, previousData, successMessage, errorMessage }) => {
    dataProvider.UPDATE(resource, { id, data, previousData })
      .then(() => {
        notify(successMessage);
        refresh();
      })
      .catch(() => { notify(errorMessage, 'warning'); })
  }

  const BlockCustomerButton = ({ record: { customer } }) => (
    <Button
      label={customer.isBlocked ? 'Desbloquear' : 'Bloquear'}
      onClick={() => {
        const successMessage = `Cliente ${customer.isBlocked ? 'desbloqueado' : 'bloqueado'}`;
        const errorMessage = `Erro ao ${customer.isBlocked ? 'desbloquear' : 'bloquear'} cliente`
        doUpdate({
          resource: 'customers',
          id: customer.id,
          data: { isBlocked: !customer.isBlocked },
          previousData: customer,
          successMessage,
          errorMessage
        })
      }}
    />
  );

  const IgnoreFraudButton = ({ record, resource }) => (
    <Button
      disabled={record.isIgnored}
      label={record.isIgnored ? 'Ignorada' : 'Ignorar'}
      onClick={() =>
        doUpdate({
          resource: resource,
          id: record.id,
          data: { isIgnored: true },
          previousData: record,
          successMessage: 'Possível fraude ignorada',
          errorMessage: 'Erro ao ignorar possível fraude'
        })
      }
    />
  );

  const IgnoreCustomerFraudButton = ({ record: { customer } }) => (
    <Button
      label={customer.ignoreFraud ? 'Considerar novamente' : 'Ignorar Sempre'}
      onClick={() => {
        const successMessage = `Cliente ${customer.ignoreFraud ? 'removido da' : 'adicionado à'} lista branca`
        const errorMessage = `Erro ao ${customer.ignoreFraud ? 'remover cliente da' : 'adicionar cliente à'} lista branca`
        doUpdate({
          resource: 'customers',
          id: customer.id,
          data: { ignoreFraud: !customer.ignoreFraud },
          previousData: customer,
          successMessage,
          errorMessage
        })
      }}
    />
  );

  return (
    <List
      {...props}
      perPage={25}
      pagination={<ListPagination />}
      exporter={false}
      bulkActionButtons={false}
      title="Possíveis fraudes"
      filters={<PotencialFraudFilter />}
      filterDefaultValues={{ isOpen: true }}
      sort={{ field: 'fraudLog.createdAt', order: 'DESC' }}
    >
      <Datagrid>
        <ReferenceField label="Cliente" reference="customers" source="customerId" link="show">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="customer.cpf" label="CPF" />
        <TextField source="fraud.label" label="Motivo da suspeita" />
        <DateField source="createdAt" label="Adicionado em" locales="pt-BR" />
        <BlockCustomerButton />
        <IgnoreFraudButton />
        <IgnoreCustomerFraudButton />
      </Datagrid>
    </List>
  );
}
