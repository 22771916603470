import React from 'react';
import {
    TextField,
    useListContext,
    ReferenceField,
    ReferenceInput,
    DateField,
    Datagrid,
    SelectInput,
    usePermissions,
    downloadCSV
} from 'react-admin';
import ListPagination from '../../components/ListPagination';
import { makeStyles, Typography } from '@material-ui/core';
import { DateInput } from '../../components/DateInput';
import ReportList from '../../components/ReportList';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    title: {
        margin: '10px 0 10px 0',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    headerCell: {
        backgroundColor: '#E5E5E5',
    },
}));

const RecurringCustomersList = () => {
    const classes = useStyles();
    const props = useListContext();

    const recurringCustomersInfo = props.data['recurringCustomers'];
    let data = {};
    let ids = [];
    let summaryData = {};

    if (recurringCustomersInfo) {
        // convert array to object passing 'id' as key
        recurringCustomersInfo.data.forEach(element => {
            data[element.id] = element;
        });

        ids = recurringCustomersInfo.data.map(item => item.id);
        summaryData = { summary: recurringCustomersInfo.summaryData };
    }

    return (
        <>
            <Typography className={classes.title}>Clientes que Abasteceram</Typography>
            <Datagrid classes={{ headerCell: classes.headerCell }} style={{ marginBottom: 60 }} data={summaryData} ids={['summary']} total={1}>
                <TextField source="one" label="Uma vez" textAlign="center" emptyText="0" sortable={false} />
                <TextField source="two" label="Duas vezes" textAlign="center" emptyText="0" sortable={false} />
                <TextField source="three" label="Três vezes" textAlign="center" emptyText="0" sortable={false} />
                <TextField source="four" label="Quatro vezes" textAlign="center" emptyText="0" sortable={false} />
                <TextField source="five" label="Cinco ou mais vezes" textAlign="center" emptyText="0" sortable={false} />
            </Datagrid>

            <Typography className={classes.title}>Clientes e Reabastecimentos</Typography>
            <Datagrid classes={{ headerCell: classes.headerCell }} resource={props.resource} data={data} ids={ids}>
                <TextField source="name" label="Nome do Cliente" textAlign="center" />
                <TextField source="cpf" label="CPF do Cliente" textAlign="center" />
                <TextField source="refuels" label="Total de Abastecimentos" textAlign="center" />
            </Datagrid>
        </>
    );
};

const amountChoices = [
    { id: 1, name: 'Uma vez' },
    { id: 2, name: 'Duas vezes' },
    { id: 3, name: 'Três vezes' },
    { id: 4, name: 'Quatro vezes' },
    { id: 5, name: 'Cinco ou mais vezes' },
];

const FilterTablePrint = () => {
    const classes = useStyles();
    const { filterValues } = useListContext();
    return (
        <Datagrid classes={{ headerCell: classes.headerCell }} data={{ filters: { ...filterValues, generatedReportAt: moment() } }} ids={['filters']} total={1}>
            <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
            <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
            <ReferenceField label="Posto" emptyText="Todos" source="stationId" textAlign="center" basePath="stations" reference="stations" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField label='Qnt. de Abastecimentos' source="amount" textAlign="center" emptyText="Todos" />
            <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
        </Datagrid>
    );
};

const recurringCustomersExporter = async (data) => {
    jsonExport(data[0].data, (err, csv) => {
        downloadCSV(csv, 'recurring-customers');
    });
}

const ReportRecurringCustomers = (props) => {
    const { permissions } = usePermissions();

    const listFilters = [
        <DateInput source="from" label="De" options={{ format: 'DD/MM/YYYY' }} alwaysOn />,
        <DateInput source="to" label="Até" options={{ format: 'DD/MM/YYYY' }} alwaysOn />,
        <ReferenceInput
            label="Posto"
            source="stationId"
            reference="stations"
            sort={{ field: "name", order: "ASC" }}
            emptyText="Todos"
            perPage={false}
            filter={permissions === 'manager' ? { chainId: localStorage.getItem('chainId') } : {}}
            alwaysOn
        >
            <SelectInput
                optionText={'name'}
            />
        </ReferenceInput>,
        <SelectInput
            label="Quantidade"
            source="amount"
            emptyText="Todos"
            alwaysOn
            choices={amountChoices}
        />
    ];

    if (permissions === 'admin') {
        listFilters.splice(2, 0,
            <ReferenceInput label="Rede" source="chainId" reference="chains" perPage={false} sort={{ field: 'name', order: 'ASC' }} emptyText="Todos" alwaysOn>
                <SelectInput
                    optionText={'name'} />
            </ReferenceInput>,
        )
    }

    return (
        <ReportList
            {...props}
            basePath="fillins"
            title="Clientes Recorrentes"
            resource="reports/recurring-customers"
            filters={listFilters}
            sort={{ field: 'refuels', order: 'DESC' }}
            perPage={25}
            filterDefaultValues={{
                from: moment().startOf('day').subtract(1, "month").toISOString(),
                to: moment().endOf('day').toISOString()
            }}
            pagination={<ListPagination />}
            bulkActionButtons={false}
            exporter={recurringCustomersExporter}
            titleOnPrint="Relatório de clientes recorrentes"
            filterTablePrint={<FilterTablePrint />}
            landscape={true}
        >
            <RecurringCustomersList />
        </ReportList>
    );
};

export default ReportRecurringCustomers;