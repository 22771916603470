import React, { useState, useEffect } from 'react';
import { Wrapper } from '../components/PageComponents';
import {
  useNotify,
  NumberInput,
  useDataProvider,
  FormDataConsumer,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  TextField,
  BooleanInput,
  number,
  minValue,
} from 'react-admin';

const VALUE_VALIDATION = [number(), minValue(1)]

export default ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const patchRules = data => {
    setLoading(true);
    dataProvider
      .CREATE(`rules`, { data })
      .then(() => notify('Regras salvas com sucesso'))
      .catch(() => notify('Erro ao salvar regras', 'warning'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    dataProvider.getList(`rules`, { sort: { field: 'label', order: 'ASC' }})
      .then(({ data }) => setInitialValues({ rules: data }))
      .catch((err) => notify('Erro ao buscar regras', 'warning'))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Wrapper>
      <SimpleForm save={patchRules} saving={loading} initialValues={initialValues}>
        <ArrayInput source="rules" label="">
          <SimpleFormIterator disableAdd disableRemove>
            <FormDataConsumer>
              {({ scopedFormData, getSource }) => {
                getSource && getSource('');
                return (
                  <>
                    <TextField
                      source={"label"}
                      record={scopedFormData}
                    />
                  </>
                );
              }}
            </FormDataConsumer>
            <BooleanInput label="Habilitar" source={"isActive"} />
            <NumberInput
              source="value"
              label="Valor"
              validate={VALUE_VALIDATION}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Wrapper>
  );
};

