import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { DashboardWrapper } from '../../components';
import Typography from '@material-ui/core/Typography'
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    fontSize: '2.5625rem'
  }
});

const FillinsAverageTime = () => {
  const dataProvider = useDataProvider();
  const [avg, setAvg] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    dataProvider.getList('reports/fillins', {
      filter: {
        from: moment().startOf('day').subtract(1, 'month'),
        to: moment().endOf('day')
      }
    }).then(({ total }) => {
      const average = ((30 * 14 * 60) / total).toFixed(2);
      setAvg(moment('0:0:0', 'H:m:s').add(average * 60000, 'milliseconds').format('HH:mm:ss'));
    }).catch(err => console.error(err));
  }, []);

  return (
    <DashboardWrapper
      permissions={['manager', 'admin']}
      title="Um abastecimento a cada"
      content={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography className={classes.title} variant="h3">{avg}</Typography>
        </div>
      }
    />
  );
};

export default FillinsAverageTime;