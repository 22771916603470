import { stringify } from 'query-string';
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import * as diff from 'object-diff';

const toFormData = object => Object.keys(object).reduce((formData, key) => {
  if (object[key] instanceof File || object[key].rawFile instanceof File) {
    formData.append(key, object[key].rawFile);
  } else if (Array.isArray(object[key])) {
    formData.append(key, JSON.stringify(object[key]));
  } else {
    formData.append(key, object[key]);
  }
  return formData;
}, new FormData());

const formDataResources = ['chains', 'products', 'product-categories', 'stations', 'partners', 'services'];

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    const options = {};
    const chainId = localStorage.getItem('chainId');
    switch (type) {
      case GET_LIST: {
        const query = {};
        if (params.pagination) {
          const { page, perPage } = params.pagination;
          if(page && perPage){
            query.skip = (page - 1) * perPage;
            query.take = perPage;
          }
        };
        if (params.sort) {
          const { field, order } = params.sort;
          query.order = JSON.stringify({ [field]: order });
        };
        if (params.filter) {
          query.filter = JSON.stringify(params.filter);
        };
        if (chainId) {
          query.chainId = chainId;
        }
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_ONE:
        const query = {};
        if (params.filter) {
          query.filter = JSON.stringify(params.filter);
        };
        url = `${apiUrl}/${resource}/${params.id}?${stringify(query)}`;
        break;
      case GET_MANY: {
        const query = {
          filter: JSON.stringify({ ids: params.ids }),
        };
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const { field, order } = params.sort;
        const query = {
          order: JSON.stringify({ [field]: order }),
          filter: JSON.stringify({
            ...params.filter,
            [params.target]: params.id,
          }),
        };
        if (chainId) {
          query.chainId = chainId;
        }
        if (params.pagination) {
          const { page, perPage } = params.pagination;
          if(page && perPage){
            query.skip = (page - 1) * perPage;
            query.take = perPage;
          }
        };
        url = `${apiUrl}/${resource}?${stringify(query)}`;
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}${params.id ? `/${params.id}` : ''}`;
        options.method = 'PATCH';
        const data = diff(params.previousData, params.data); //send only the changed records to backend
        if (formDataResources.includes(resource)) {
          options.body = toFormData(data);
        } else {
          options.body = JSON.stringify(data);
        }
        break;
      case CREATE:
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        if (formDataResources.includes(resource)) {
          options.body = toFormData(params.data);
        } else {
          options.body = JSON.stringify(params.data);
        }
        break;
      case "createMany":
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        const dataArray = JSON.stringify(params.data);
        options.body = dataArray;
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { json } = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
      case CREATE:
      default:
        return json;
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PATCH',
            body: JSON.stringify(params.data),
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json),
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json),
      }));
    }

    const { url, options } = convertDataRequestToHTTP(
      type,
      resource,
      params
    );
    return httpClient(url, options).then(response =>
      convertHTTPResponse(response, type, resource, params)
    );
  };
};