import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";
import { List, ListItem, Paper, makeStyles } from '@material-ui/core';
import React, { useState, Children, createContext, useContext, useEffect } from "react";
import { useDataProvider } from 'react-admin';
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import { Permissions, GenericSelect } from '.';

const ReportContext = createContext();

const useStyles = makeStyles(theme => ({
    filterRow: {
        flexWrap: 'wrap',
        alignItems: 'flex-end'
    },
    filter: {
        margin: '0 25px 25px 0'
    }
}));

const amountChoices = [
    { id: 1, name: 'Uma vez' },
    { id: 2, name: 'Duas vezes' },
    { id: 3, name: 'Três vezes' },
    { id: 4, name: 'Quatro vezes' },
    { id: 5, name: 'Cinco ou mais vezes' },
];

const FilterRow = ({ children }) => {
    const classes = useStyles();
    return (
        <ListItem className={classes.filterRow}>
            {Children.map(children, child => child &&
                <div className={classes.filter}>
                    {child}
                </div>
            )}
        </ListItem>
    );
};

export const ReportLayout = ({
    filtersComponent,
    children,
    chainSelect,
    stationSelect,
    placeSelect,
    amountSelect,
    permissions,
    resource,
    initialSort,
    initialPagination,
    initialFrom,
    initialTo,
    filtersValue
}) => {
    const [from, setFrom] = useState(initialFrom || moment().startOf('day'));
    const [to, setTo] = useState(initialTo || moment().endOf('day'));
    const [chainId, setChainId] = useState(localStorage.getItem('chainId'));
    const [amountId, setAmountId] = useState(null)
    const [stationId, setStationId] = useState(localStorage.getItem('stationId'));
    const [placeId, setPlaceId] = useState(localStorage.getItem('stationId'));
    const [data, setData] = useState(null);
    const [filters, setFilters] = useState({});
    const [error, setError] = useState(null);
    const [sort, setSort] = useState(initialSort);
    const [pagination, setPagination] = useState(initialPagination);

    const dataProvider = useDataProvider();

    if (filtersValue && filtersValue !== filters) {
        setFilters(filtersValue)
    }

    useEffect(() => {
        if (sort && pagination) {
            const perPage = pagination.perPage;
            setPagination({ page: 1, perPage });
        };
    }, [sort]);

    useEffect(() => {
        if (stationSelect && stationId) setStationId(null);
        if (placeSelect && placeId) setPlaceId(null);
    }, [chainId]);

    useEffect(() => {
        const filter = { ...filters, from, to };
        if (stationId) { filter.stationId = stationId; }
        if (placeId) { filter.placeId = placeId; }
        if (chainId) { filter.chainId = chainId; }
        if (amountId) { filter.amountId = amountId; }

        dataProvider.getList(`reports/${resource}`, { filter, sort, pagination })
            .then(response => {
                setError(null);
                setData(response)
            }).catch(error => {
                setError(error);
                setData(null);
            });
    }, [from, to, chainId, stationId, placeId, amountId, filters, pagination]);

    return (
        <ReportContext.Provider value={{
            from,
            setFrom,
            to,
            setTo,
            chainId,
            setChainId,
            amountId,
            setAmountId,
            stationId,
            setStationId,
            data,
            setData,
            error,
            setError,
            sort,
            setSort,
            pagination,
            setPagination,
            filters
        }}>
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale="pt-br">
                <Paper>
                    <Permissions permissions={permissions}>
                        <List>
                            <FilterRow>
                                <DatePicker
                                    format={"DD/MM/YYYY"}
                                    value={from}
                                    onChange={setFrom}
                                    label='De'
                                />
                                <DatePicker
                                    format={"DD/MM/YYYY"}
                                    value={to}
                                    onChange={setTo}
                                    label='Até'
                                />
                                {chainSelect && !localStorage.getItem('chainId') &&
                                    <GenericSelect
                                        label='Rede'
                                        setValue={setChainId}
                                        allowEmpty
                                        reference='chains'
                                        errorMessage='Erro ao carregar Redes de Postos'
                                        value={chainId}
                                    />}
                                {stationSelect &&
                                    <GenericSelect
                                        label='Posto'
                                        setValue={setStationId}
                                        allowEmpty
                                        reference='stations'
                                        afterQueryFilter={chainId && { chainId }}
                                        errorMessage='Erro ao carregar Postos'
                                        value={stationId}
                                    />}
                                {placeSelect &&
                                    <GenericSelect
                                        label='Local'
                                        setValue={setPlaceId}
                                        allowEmpty
                                        reference='places'
                                        afterQueryFilter={chainId && { chainId }}
                                        errorMessage='Erro ao carregar Locais'
                                        value={placeId}
                                    />}
                                {amountSelect &&
                                    <GenericSelect
                                        label='Quantidade'
                                        setValue={setAmountId}
                                        value={amountId}
                                        options={amountChoices}
                                        errorMessage='Erro ao carregar quantidades'
                                        allowEmpty
                                    />}
                                {filtersComponent}
                            </FilterRow>
                            {children}
                        </List>
                    </Permissions>
                </Paper>
            </MuiPickersUtilsProvider>
        </ReportContext.Provider>
    );
};

export const useReportContext = () => {
    const {
        from,
        setFrom,
        to,
        setTo,
        chainId,
        setChainId,
        amountId,
        setAmountId,
        stationId,
        setStationId,
        placeId,
        setPlaceId,
        data,
        setData,
        error,
        setError,
        sort,
        setSort,
        pagination,
        setPagination,
        filters
    } = useContext(ReportContext);

    return {
        from,
        setFrom,
        to,
        setTo,
        chainId,
        setChainId,
        amountId,
        setAmountId,
        stationId,
        setStationId,
        placeId,
        setPlaceId,
        data,
        setData,
        error,
        setError,
        sort,
        setSort,
        pagination,
        setPagination,
        filters
    };
};