import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  DeleteButton,
  List,
  TextField,
  TextInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  ReferenceField,
  required,
  TopToolbar,
  CreateButton,
  Filter,
} from "react-admin";
import { ImportCSVButton } from "../components/ImportCSVButton";


const AttendantFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <TextInput label="Pesquisar" source="search" alwaysOn />
    <ReferenceInput
      label="Posto"
      source="stationId"
      reference="stations"
      filter={permissions === 'manager' ? { chainId: localStorage.getItem('chainId') } : {}}
      sort={{ field: "name", order: "ASC" }}
      perPage={false}
      alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const ListActions = props => {
  const {
    className,
    basePath,
  } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton basePath={basePath} />
      <ImportCSVButton {...props} />
    </TopToolbar>
  );
};

export const AttendantList = ({ permissions, ...props }) => (
  <List {...props}
    title="Frentistas"
    filters={<AttendantFilter permissions={permissions} />}
    actions={<ListActions permissions={permissions} />}
    filter={permissions === 'manager' ? { chainId: localStorage.getItem('chainId') } : {}}>
    <Datagrid rowClick="show">
      <TextField source="name" label="Nome" />
      <TextField source="identfid" label="Identfid" />
      <ReferenceField label="Posto" source="stationId" reference="stations">
        <TextField source="name" />
      </ReferenceField>
      {(permissions === "admin" || permissions === "manager") && <EditButton />}
      {(permissions === "admin" || permissions === "manager") && <DeleteButton />}
    </Datagrid>
  </List>
);

export const AttendantEdit = ({ permissions, ...props }) => (
  <Edit title='Editar frentista' {...props}>
    <SimpleForm>
      <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
      <TextInput source="identfid" label="Identificador" validate={required("Identificador é obrigatório")} />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          <ReferenceInput
            label="Posto"
            source="stationId"
            reference="stations"
            filter={permissions === 'manager' ? { chainId: localStorage.getItem('chainId') } : {}}
            perPage={false}
            sort={{ field: "name", order: "ASC" }}>
            <SelectInput optionText="name" />
          </ReferenceInput>
        }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

export const AttendantCreate = ({ permissions, ...props }) => (
  <Create title='Adicionar frentista' {...props}>
    <SimpleForm>
      <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
      <TextInput source="identfid" label="Identificador" validate={required("Identificador é obrigatório")} />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          <ReferenceInput
            label="Posto"
            source="stationId"
            reference="stations"
            filter={permissions === 'manager' ? { chainId: localStorage.getItem('chainId') } : {}}
            perPage={false}
            sort={{ field: "name", order: "ASC" }}>
            <SelectInput optionText="name" />
          </ReferenceInput>
        }
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);