import React, { useEffect, useState } from 'react';
import { DashboardWrapper, RotatedAxisTick } from '../../components';
import { Area, AreaChart, XAxis, YAxis, Tooltip } from 'recharts';
import { useDataProvider } from 'react-admin';
import { formatNumber } from '../../utils/utils';
import moment from 'moment';

const color = "#03c8ff";

const sortData = (a, b) => {
    return new Date(a.day) - new Date(b.day);
}

const NewCustomersChart = () => {
    const dataProvider = useDataProvider();
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        dataProvider.getList('reports/new-customers-summary', {
            filter: {
                from: moment().startOf('day').subtract(1, 'month'),
                to: moment().endOf('day')
            }
        }).then(({ data }) => {
            data.sort(sortData);
            data.forEach((element) => {
                element.day = moment(element.day).format('DD/MM/YYYY');
                element.id = Number(element.id);
            });
            setChartData(data);
        });
    }, []);

    return (
        <DashboardWrapper
            title="Gráfico de Novos Clientes"
            permissions={['admin']}
            content={
                <AreaChart data={chartData}>
                    <YAxis type="number" domain={['auto', 'auto']} />
                    <XAxis dataKey="day" tick={<RotatedAxisTick />} height={60} />
                    <Tooltip formatter={(value) => `${formatNumber(value)} Clientes`} />
                    <defs>
                        <linearGradient id={color} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={color} stopOpacity={0.75} />
                            <stop offset="95%" stopColor={color} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <Area
                        stroke={color}
                        fillOpacity={1}
                        fill={`url(#${color})`}
                        dataKey={"id"}
                        name='Total'
                        type="monotone"
                    />
                </AreaChart>
            }
        />
    );
}

export default NewCustomersChart;