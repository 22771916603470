import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  showNotification,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import CancelButton from '../CancelButton';

const BlockCustomerButton = ({ customer }) => {
  const [displayModal, setDisplayModal] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  const doUpdate = ({ resource, id, data, previousData, successMessage, errorMessage }) => {
    dataProvider.UPDATE(resource, { id, data, previousData })
      .then(() => {
        setDisplayModal(false);
        notify(successMessage);
        refresh();
      })
      .catch(() => { notify(errorMessage, 'warning'); })
  }

  return customer && (
    <Fragment>
      <div style={{ marginTop: '1em' }}>
        <Button
          label={customer.isBlocked ? 'Desbloquear Cliente' : 'Bloquear Cliente'}
          style={{ color: "red", borderColor: "red", marginLeft: 15 }}
          variant="outlined"
          onClick={() => setDisplayModal(true)}

        />
      </div>
      <Dialog
        open={displayModal}
        onClose={() => setDisplayModal(false)}
      >
        <DialogTitle>{`Confirmar ${customer.isBlocked ? 'desbloqueio' : 'bloqueio'} do cliente?`}</DialogTitle>
        <DialogContent>
          <div>
            <Button
              label="Cancelar"
              variant="outlined"
              onClick={() => setDisplayModal(false)}
              style={{ width: 100, marginLeft: 30 }}
            />
            <Button
              label="Confirmar"
              aria-label='o'
              style={{ width: 100, marginLeft: 10 }}
              variant="contained"
              onClick={() => {
                const successMessage = `Cliente ${customer.isBlocked ? 'desbloqueado' : 'bloqueado'}`;
                const errorMessage = `Erro ao ${customer.isBlocked ? 'desbloquear' : 'bloquear'} cliente`
                doUpdate({
                  resource: 'customers',
                  id: customer.id,
                  data: { isBlocked: !customer.isBlocked },
                  previousData: customer,
                  successMessage,
                  errorMessage
                })
              }}
            />
          </div>
        </DialogContent>
      </Dialog>

    </Fragment>
  );
}

export default connect(
  null,
  {
    showNotification,
  },
)(BlockCustomerButton)
