import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify, FormDataConsumer } from 'react-admin';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const getItemIndex = (indexString) => {
    const matches = indexString.match(/\[([0-9]*?)\]/);
    return matches ? matches[1] : '';
}

const LazyLoadAutoCompleteInput = ({ source, label, style, format, parse, isInsideArray, arraySource, id, ...props }) => {
    const [choices, setChoices] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedIds, setSelectedIds] = useState();
    const [value, setValue] = useState(null);
    const [timeoutId, setTimeoutId] = useState(setTimeout(() => { }, 1));
    const [itemIndex, setItemIndex] = useState('');
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const onInputChange = (event, newValue) => {
        const parsedValue = parse ? parse(newValue) : newValue;
        if (parsedValue !== search) {
            setSearch(newValue);
            clearTimeout(timeoutId);
            const id = setTimeout(() => { getChoices(parsedValue); }, 400);
            setTimeoutId(id);
        }
    };

    const getSelected = () => {
        const filter = { chainId: localStorage.getItem('chainId') };
        const saleProductId = props.record.saleProductId;
        if (saleProductId) {
            dataProvider.getOne(`sale-products`, { id: saleProductId, filter })
                .then(selectedItemData => {
                    const initialValue = selectedItemData.data;
                    if (initialValue) {
                        setValue(initialValue);
                    }
                })
        }
    }

    const getChoices = (search) => {
        const filter = { chainId: localStorage.getItem('chainId') };
        if (search) {
            filter.search = search;
        }
        dataProvider.getList('sale-products', {
            sort: { field: 'name', order: 'ASC' },
            filter
        })
            .then(data => {
                setChoices(data.data);
                if (!value && selectedIds && itemIndex) {
                    const initialValue = data.data.find(d => d.id === selectedIds[itemIndex]);
                    if (initialValue) {
                        setValue(initialValue);
                    }
                }
            })
            .catch(() => notify('Erro ao carregar opções', 'warning'));
    };


    useEffect(() => {
        getSelected();
    }, []);


    return (
        <FormDataConsumer>
            {({ formData }) => {
                if (isInsideArray) {
                    if (id) {
                        const index = getItemIndex(id);
                        setItemIndex(index);
                    }
                }

                if (!selectedIds && formData && arraySource) {
                    const saleProductIds = formData[arraySource].map(p => p && p.saleProductId);
                    if (saleProductIds) {
                        setSelectedIds(saleProductIds);
                    }
                }

                const onValueChange = (event, newValue) => {
                    if (arraySource && itemIndex) {
                        formData[arraySource][itemIndex] = { saleProductId: newValue.id };
                    } else {
                        formData[source] = newValue.id;
                    }
                    setValue(newValue);
                };

                return (
                    <Autocomplete
                        value={value}
                        onChange={onValueChange}
                        options={choices}
                        getOptionLabel={format || (option => option.name)}
                        noOptionsText={"Buscar produto"}
                        inputValue={search}
                        onInputChange={onInputChange}
                        style={style}
                        renderInput={(params) => <TextField {...params} label={label} variant="filled" />}
                    />
                );
            }}
        </FormDataConsumer>
    );
};

export default LazyLoadAutoCompleteInput;