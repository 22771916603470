import React, { useEffect, useState } from 'react';
import {
    List,
    Create,
    Edit,
    TextInput,
    SelectInput,
    required,
    minValue,
    TabbedForm,
    FormTab,
    ArrayInput,
    SimpleFormIterator,
    FormDataConsumer,
    NumberInput,
    SaveButton,
    Toolbar,
    DeleteButton,
    useDataProvider,
    useNotify,
    useRefresh,
    Filter,
    Datagrid,
    TextField,
    ImageField,
    SearchInput,
    BulkDeleteButton,
    BooleanInput,
    ImageInput,
    ReferenceInput,
    ReferenceField,
    maxValue,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CancelButton from '../components/CancelButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { LazyLoadAutoCompleteInput } from '../components';

const editStyles = makeStyles({
    placesInput: {
        '& p': {
            color: 'grey',
            'margin-right': '1em',
            'font-weight': 'bold',
        },
    },
    placeName: {
        padding: '1em 0 0.5em 0',
        width: '100%',
    },
    stockSwitch: {
        margin: '2em 2em 0em 1em'
    },
});

const useIteratorStyle = makeStyles(() => ({
    form: {
        display: 'flex',
        flexDirection: 'row',
    },
}));

const Filters = props => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <BooleanInput label="Desativados" source="isDisabled" alwaysOn />
    </Filter>
);

const listStyles = makeStyles({
    image: {
        '& img': {
            maxWidth: '4rem',
        },
    },
});

const BulkActionButtons = props => (
    <BulkDeleteButton {...props} label="Inativar" />
);

const CustomToolbar = props => {
    const classes = editStyles();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    return (
        <Toolbar {...props} className={classes.Toolbar}>
            <SaveButton />
            <div className={classes.ToolbarButtonWrapper}>
                <Button
                    startIcon={props.record.isDisabled ? <AddCircleIcon /> : <RemoveCircleIcon />}
                    label={props.record.isDisabled ? 'Ativar' : 'Desativar'}
                    onClick={() => {
                        dataProvider.UPDATE(props.resource, { id: props.record.id, data: { isDisabled: !props.record.isDisabled }, previousData: { ...props.record } })
                            .then(() => {
                                notify(`Produto ${props.record.isDisabled ? 'ativado' : 'desativado'} com sucesso`)
                            }).catch(() => {
                                notify(`Erro ao ${props.record.isDisabled ? 'ativar' : 'desativar'} produto`, 'warning')
                            })
                        refresh()
                    }}
                />
                <DeleteButton />
            </div>
        </Toolbar>
    )
}

export const ProductList = ({ permissions, ...props }) => {
    const classes = listStyles();

    return (
        <List
            {...props}
            perPage={25}
            exporter={false}
            title="Produtos"
            filter={localStorage.getItem('chainId') ? { chainId: localStorage.getItem('chainId') } : null}
            filters={<Filters />}
            bulkActionButtons={<BulkActionButtons />}
            filterDefaultValues={{ isDisabled: false }}
        >
            <Datagrid rowClick="edit">
                <ImageField className={classes.image} source="imagePath" label="Imagem" />
                <TextField source="name" label="Nome" />
                <TextField source="description" label="Descrição" />
                <ReferenceField
                    label="Categoria"
                    source="categoryId"
                    reference="product-categories"
                    emptyText="[Sem categoria]"
                    link={false}>
                    <TextField source="name" />
                </ReferenceField>
            </Datagrid>
        </List>
    );
}


export const ProductEdit = ({ permissions, ...props }) => {
    const classes = editStyles();
    const iteratorClasses = useIteratorStyle();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [opts, setOpts] = useState([]);

    const saleProductOptionRenderer = choice => `${choice.code} - ${choice.name}`;

    const transform = data => {
        data = {
            ...data,
            places: data.productPlaces.map(({ placeId, price, partnerPrice, discountValue, limit, rtiCode }) => ({
                placeId, price, rtiCode,
                partnerPrice: partnerPrice ? +partnerPrice : null,
                limit: limit ? +limit : null,
                discountValue: discountValue ? +discountValue : null
            })),
            chainId: permissions === 'manager' && localStorage.getItem('chainId'),
        }
        data.productSaleProducts = data.productSaleProducts.map(p => p && p.saleProductId);
        delete data.productPlaces;
        delete data.saleProducts;

        return data;
    };

    useEffect(() => {
        dataProvider.getList(`places`, {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name', order: 'ASC' },
            filter: permissions === 'manager' ? { chainId: localStorage.getItem('chainId') } : {},
        })
            .then(({ data }) => {
                const formattedData = data.map(p => ({
                    id: p.id,
                    name: p.name,
                }));
                setOpts(formattedData);
            })
            .catch(() => { notify('Erro ao carregar transações da sincronização', 'warning'); });
    }, []);

    const validate = [required()];

    return (
        <Edit {...props} title="Editar produto" transform={transform} >
            <TabbedForm toolbar={<CustomToolbar {...props} />} >
                <FormTab label="Produto">
                    <TextInput source="name" label="Nome" validate={validate} inputProps={{ autoComplete: 'off' }} />
                    <TextInput
                        source="description"
                        label="Descrição"
                        validate={validate}
                        inputProps={{ autoComplete: 'off' }}
                    />
                    <ReferenceInput
                        label="Categoria"
                        source="categoryId"
                        reference="product-categories"
                        sort={{ field: "name", order: "ASC" }}
                        perPage={false}
                        validate={validate}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ArrayInput
                        label="Produtos de Venda"
                        source="productSaleProducts"
                        className={classes.arrayInput}
                    >
                        <SimpleFormIterator disableReordering>
                            <LazyLoadAutoCompleteInput
                                label="Produto de Venda"
                                source="productSaleProducts"
                                format={saleProductOptionRenderer}
                                style={{ width: 260, margin: 15 }}
                                isInsideArray
                                arraySource="productSaleProducts"
                                parse={filter => filter.indexOf('- ') > -1 ? filter.split('- ')[1] : filter}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ImageInput source="imagefile" label="Imagem" accept="image/*">
                        <ImageField source="image" label="Imagem" />
                    </ImageInput>
                </FormTab>
                <FormTab label="Resgate">
                    <ArrayInput source="productPlaces" label="" className={classes.placesInput}>
                        <FormDataConsumer>
                            {({ formData: upperFormData, ...upperRest }) => {
                                const disableRemove = upperFormData.places
                                    ? upperFormData.places.length === 1
                                    : false
                                const disableAdd = upperFormData.places ? upperFormData.places.length === opts.length : false;
                                return (
                                    <SimpleFormIterator
                                        disableRemove={disableRemove}
                                        disableAdd={disableAdd}
                                        classes={iteratorClasses}
                                        {...upperRest}
                                    >
                                        <FormDataConsumer>
                                            {({
                                                formData,
                                                scopedFormData,
                                                getSource,
                                                ...rest
                                            }) => {
                                                if (formData.productPlaces) {
                                                    const selectedPlacesIds = formData.productPlaces.filter((s) => s).map(
                                                        s => s.placeId,
                                                    )
                                                    const availablePlaces = opts.filter(
                                                        ({ id }) => !selectedPlacesIds.includes(id) ||
                                                            (scopedFormData ? scopedFormData.placeId === id : false),
                                                    )

                                                    return (
                                                        <SelectInput
                                                            source={getSource && getSource('placeId')}
                                                            choices={availablePlaces}
                                                            label="Ponto de troca"
                                                        />
                                                    )
                                                } else {
                                                    return null;
                                                }
                                            }}
                                        </FormDataConsumer>
                                        <NumberInput
                                            source="price"
                                            label="Valor em pontos"
                                            validate={[required(), minValue(1)]}
                                            min={1}
                                            style={{ width: 150, marginLeft: 16 }}
                                        />
                                        <TextInput
                                            source="partnerPrice"
                                            label="Valor em reais"
                                            initialValue="0"
                                            format={(value) => {
                                                if (!value || value === 0) {
                                                    return "R$ 0,00";
                                                }
                                                return `R$ ${Number(value).toFixed(2)}`;
                                            }}
                                            parse={(value) => {
                                                if (!value || value === '') {
                                                    return 0;
                                                }
                                                const onlyNumbers = +value.replace(/[^\d]/g, '');
                                                return onlyNumbers / 100;
                                            }}
                                            style={{ width: 150, marginLeft: 16 }}
                                        />
                                        <TextInput
                                            source="discountValue"
                                            label="Valor do Desconto(%)"
                                            format={(value) => value ? Number(value * 100).toFixed(0) : 0}
                                            parse={(value) => value && +value.replace(/[^\d]/g, '') / 100}
                                            defaultValue={1}
                                            validate={[minValue(0.01, 'Deve ser no mínimo 1'), maxValue(1, 'Deve ser no máximo 100')]}
                                            style={{ width: 160, marginLeft: 16 }}
                                        />
                                        <NumberInput
                                            source="limit"
                                            label="Resgates por dia"
                                            min={1}
                                            validate={minValue(1)}
                                            style={{ width: 150, marginLeft: 16 }}
                                        />
                                        <NumberInput
                                            source="rtiCode"
                                            label="Código RTI"
                                            validate={[minValue(0)]}
                                            min={0}
                                            style={{ width: 150, marginLeft: 16 }}
                                        />
                                    </SimpleFormIterator>
                                )
                            }}
                        </FormDataConsumer>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
}

export const ProductCreate = ({ permissions, ...props }) => {
    const classes = editStyles();
    const iteratorClasses = useIteratorStyle();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [opts, setOpts] = useState([]);
    const optionRenderer = choice => `${choice.code} - ${choice.name}`;

    const transform = data => {
        data = {
            ...data,
            places: data.places.map(({ placeId, price, partnerPrice, discountValue, limit, rtiCode }) => ({
                placeId, price, rtiCode,
                partnerPrice: partnerPrice ? +partnerPrice : null,
                limit: limit ? +limit : null,
                discountValue: discountValue ? +discountValue : null
            })),
            isCash: false,
        }

        if (data.saleProductIds) {
            data.saleProductIds = data.saleProductIds.map(p => p && p.saleProductId);
        }
        delete data.defaultValue;
        return data;
    };

    useEffect(() => {
        dataProvider.getList(`places`, {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name', order: 'ASC' },
            filter: permissions === 'manager' ? { chainId: localStorage.getItem('chainId') } : {},
        })
            .then(({ data }) => {
                const formattedData = data.map(p => ({
                    id: p.id,
                    name: p.name,
                }));
                setOpts(formattedData);
            })
            .catch(() => { notify('Erro ao carregar transações da sincronização', 'warning'); })
    }, []);

    const [addAllPlaces, setAddAllPlaces] = React.useState(false);

    const customAction = () => {
        setAddAllPlaces(true);
    }

    const validate = [required()]

    const CreateToolbar = (props) => {
        return (
            <Toolbar {...props}>
                <SaveButton />
                <CancelButton />
            </Toolbar>
        );
    }

    return (
        <Create
            {...props}
            title="Cadastrar novo produto"
            transform={transform}
        >
            <TabbedForm toolbar={<CreateToolbar />} initialValues={permissions === 'manager' && { chainId: localStorage.getItem('chainId') }}>
                <FormTab label="Produto">
                    <TextInput source="name" label="Nome" validate={validate} inputProps={{ autoComplete: 'off' }} />
                    <TextInput
                        source="description"
                        label="Descrição"
                        validate={validate}
                        inputProps={{ autoComplete: 'off' }}
                    />
                    <ReferenceInput
                        label="Categoria"
                        source="categoryId"
                        reference="product-categories"
                        sort={{ field: "name", order: "ASC" }}
                        perPage={false}
                        validate={validate}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ArrayInput
                        label="Produtos de Venda"
                        source="saleProductIds"
                        className={classes.arrayInput}
                    >
                        <SimpleFormIterator disableReordering>
                            <LazyLoadAutoCompleteInput
                                label="Produto de Venda"
                                source="saleProductId"
                                format={optionRenderer}
                                style={{ width: 260, margin: 15 }}
                                isInsideArray
                                arraySource="saleProductIds"
                                parse={filter => filter.indexOf('- ') > -1 ? filter.split('- ')[1] : filter}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ImageInput source="imagefile" label="Imagem" accept="image/*">
                        <ImageField source="image" label="Imagem" />
                    </ImageInput>
                </FormTab>
                <FormTab label="Resgate">
                    <NumberInput
                        source="defaultValue"
                        label="Valor Padrão"
                        id="defaultValueInput"
                        inputProps={{ autoComplete: 'off' }}
                    />
                    <FormDataConsumer>
                        {({
                            formData,
                            ...rest
                        }) => {
                            const disabled = formData.places ? formData.places.length === opts.length : false;
                            return (
                                <Button color="primary" disabled={disabled || !formData.defaultValue} onClick={customAction}>Adicionar Restantes</Button>
                            )
                        }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({
                            formData,
                            scopedFormData,
                            getSource,
                            label,
                            ...rest
                        }) => {
                            if (addAllPlaces) {
                                let places;
                                if (formData.places) {
                                    const formDataPlaces = formData.places.filter((s) => s);
                                    const insertedIds = formDataPlaces.map((s) => s.placeId)
                                    opts.forEach((s) => s.price = formData.defaultValue)
                                    places = formDataPlaces.concat(opts.filter((place) => !insertedIds.includes(place.id)));
                                } else {
                                    places = opts;
                                    places.forEach((s) => s.price = formData.defaultValue)
                                }
                                formData.places = places;
                                const defaultValueInputRef = document['getElementById'](
                                    'defaultValueInput',
                                )
                                setAddAllPlaces(false);
                                defaultValueInputRef.focus()
                            }
                            return (
                                <ArrayInput
                                    validate={validate}
                                    label=""
                                    source="places"
                                    className={classes.placesInput}
                                >
                                    <FormDataConsumer>
                                        {({ formData: upperFormData, ...upperRest }) => {
                                            const disableRemove = upperFormData.places
                                                ? upperFormData.places.length === 1
                                                : false
                                            const disableAdd = upperFormData.places ? upperFormData.places.length === opts.length : false;
                                            return (
                                                <SimpleFormIterator
                                                    disableRemove={disableRemove}
                                                    disableAdd={disableAdd}
                                                    classes={iteratorClasses}
                                                    {...upperRest}
                                                >
                                                    <FormDataConsumer>
                                                        {({
                                                            formData,
                                                            scopedFormData,
                                                            getSource,
                                                            label,
                                                            ...rest
                                                        }) => {
                                                            const selectedPlacesIds = formData.places.filter(s => s).map(
                                                                s => s.placeId,
                                                            )
                                                            const availablePlaces = opts.filter(
                                                                ({ id }) => {
                                                                    return !selectedPlacesIds.includes(id) ||
                                                                        (scopedFormData ? scopedFormData.placeId === id : false);
                                                                }
                                                            )
                                                            availablePlaces.map((s) => s.placeId = s.id)
                                                            return (
                                                                <SelectInput
                                                                    source={getSource('placeId')}
                                                                    choices={availablePlaces}
                                                                    label="Ponto de troca"
                                                                    validate={validate}
                                                                />
                                                            )
                                                        }}
                                                    </FormDataConsumer>
                                                    <NumberInput
                                                        source="price"
                                                        label="Valor em pontos"
                                                        validate={validate}
                                                        style={{ width: 150, marginLeft: 16 }}
                                                    />
                                                    <TextInput
                                                        source="partnerPrice"
                                                        label="Valor em reais"
                                                        style={{ width: 150, marginLeft: 16 }}
                                                        format={(value) => {
                                                            if (!value || value === 0) {
                                                                return "R$ 0,00";
                                                            }
                                                            return `R$ ${Number(value).toFixed(2)}`;
                                                        }}
                                                        parse={(value) => {
                                                            if (!value || value === '') {
                                                                return 0;
                                                            }
                                                            const onlyNumbers = +value.replace(/[^\d]/g, '');
                                                            return onlyNumbers / 100;
                                                        }}
                                                    />
                                                    <TextInput
                                                        source="discountValue"
                                                        label="Valor do Desconto(%)"
                                                        format={(value) => value === null ? 100 : Number(value * 100).toFixed(0)}
                                                        parse={(value) => value && +value.replace(/[^\d]/g, '') / 100}
                                                        defaultValue={1}
                                                        validate={[minValue(0.01, 'Deve ser no mínimo 1'), maxValue(1, 'Deve ser no máximo 100')]}
                                                        style={{ width: 160, marginLeft: 16 }}
                                                    />
                                                    <NumberInput
                                                        source="limit"
                                                        label="Resgates por dia"
                                                        min={1}
                                                        validate={minValue(1)}
                                                        style={{ width: 150, marginLeft: 16 }}
                                                    />
                                                    <NumberInput
                                                        source="rtiCode"
                                                        label="Código RTI"
                                                        validate={[minValue(0)]}
                                                        min={0}
                                                        style={{ width: 150, marginLeft: 16 }}
                                                    />
                                                </SimpleFormIterator>
                                            )
                                        }}
                                    </FormDataConsumer>
                                </ArrayInput>
                            )
                        }}
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Create>
    )
}