import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  SaveButton,
  NumberInput,
  TextInput,
  showNotification,
  number,
  required,
  ReferenceInput,
  SelectInput,
  useDataProvider,
  useNotify
} from 'react-admin'

import { Form } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'

const VALUE_VALIDATION = [required(), number()]

const BalanceButton = ({ clientId }) => {
  const [displayModal, setDisplayModal] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const addBalance = async (data) => {
    dataProvider.create(`customers/${clientId}/balance`, { data })
      .then(() => {
        notify(`Pontos adicionados com sucesso!`);
        setDisplayModal(false);
      }).catch((error) => notify('Erro ao salvar descontos'));
  }

  return (
    <Fragment>
      <div style={{ marginTop: '1em' }}>
        <Button
          variant="outlined"
          label="Adicionar Pontos"
          onClick={() => setDisplayModal(true)}
        />
      </div>
      <Dialog
        open={displayModal}
        onClose={() => setDisplayModal(false)}
        aria-label="Add balance to customer"
      >
        <DialogTitle>{'Adicionar pontos para o cliente'}</DialogTitle>
        <DialogContent>
          <Form onSubmit={addBalance}>
            {({ handleSubmit }) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <NumberInput
                  autoFocus
                  step={1}
                  label="Pontos"
                  source="value"
                  validate={VALUE_VALIDATION}
                />
                <TextInput
                  label="Descrição"
                  source="description"
                />
                <ReferenceInput
                  label="Posto"
                  source="placeId"
                  reference="places"
                  sort={{ field: 'name', order: 'ASC' }}
                  perPage={99999}
                  allowEmpty
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <SaveButton style={{ width: 100 }} handleSubmitWithRedirect={handleSubmit} />
              </div>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default connect(
  null,
  {
    showNotification,
  },
)(BalanceButton)
