import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { ResponsiveContainer } from 'recharts';
import Permissions from '../Permissions';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  filterRow: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '18px',
    marginBottom: '10px',
  }
});

const FilterRow = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.filterRow}>
      {React.Children.map(children, child => (
        <div>{child}</div>
      ))}
    </div>
  );
};

const DashBoardChild: React.FC<{ title: string, filters?: any, content: any }> = ({ filters, title, content }) => {
  const classes = useStyles();

  return (
    <Card variant="outlined">
      <CardContent>
        {title && (
          <Typography className={classes.title} variant="h5" component="h2">
            {title}
          </Typography>
        )}
        <FilterRow>{filters}</FilterRow>
        <ResponsiveContainer aspect={6.0 / 2.5} >
          {content}
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
};

const DashBoardWrapper: React.FC<{ title: string, permissions: string[], filters?: any, content: any, width?: string, link?: string }> = ({ filters, permissions, title, content, link }) => {
  const classes = useStyles();

  return (
    <Permissions permissions={permissions}>
      {link ? (
        <Link to={link}>
          <DashBoardChild title={title} content={content} filters={filters} />
        </Link>
      ) : (
        <DashBoardChild title={title} content={content} filters={filters} />
      )}
    </Permissions>
  );
};

export default DashBoardWrapper;