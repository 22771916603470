import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Filter,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  FunctionField,
  DateField
} from "react-admin";
import { StarRatingField } from '../components';

const RatingFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    {permissions === 'admin' &&
      <ReferenceInput label="Rede" source="chainId" reference="chains" perPage={false} alwaysOn>
        <SelectInput resettable={true} optionText="name" />
      </ReferenceInput>}
  </Filter>
);

export const RatingDatagrid = (props) => (
  <Datagrid {...props}>
      <ReferenceField source="customerId" label="Usuário" reference="customers" link='show'>
        <TextField source="name" />
      </ReferenceField>
      <StarRatingField source="attendantRating" label="Avaliação do frentista" />
      <StarRatingField source="stationRating" label="Avaliação do Posto" />
      <DateField source='createdAt' label='Data' showTime locales="pt-BR"/>
      <ReferenceField label="Abastecimento" source="fillinId" reference="fillins" link='show' sortable={false}>
        <FunctionField render={() => 'Abastecimento'} />
      </ReferenceField>
    </Datagrid>
);

export const RatingList = ({ permissions, ...props }) => (
  <List
    {...props}
    exporter={false}
    bulkActionButtons={false}
    title="Avaliações"
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<RatingFilter permissions={permissions} />}
  >
    <RatingDatagrid />
  </List>
);
