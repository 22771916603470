import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
      light: '#64d8ff',
      dark: '#2e00b6',
    },
    secondary: {
      main: '#2196f3',
      light: '#64d8ff',
      dark: '#2e00b6',
    },
  },
  overrides: {
    MuiTableHead: {
      root: {
        backgroundColor: '#efefef',
        width: '100%',
      },
    },
    MuiPaper: {
        elevation1: {
            boxShadow: 'none',
        },
        root: {
            border: '1px solid #e0e0e3',
            backgroundClip: 'padding-box',
        },
    },
  },
  sidebar: {
    width: 270
  }
})
