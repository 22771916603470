import React, { Fragment, useState } from 'react';
import { Button, FormDataConsumer, ReferenceInput, SimpleForm, SelectInput, useNotify } from 'react-admin'
import IconContentAdd from '@material-ui/icons/GetApp';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { ImportButton } from 'react-admin-import-csv';

export const ImportCSVButton = ({ permissions, ...props }) => {
    const notify = useNotify();
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = () => {
        setShowDialog(true);
    };

    const closeDialog = () => {
        setShowDialog(false)
    };

    return (
        <Fragment>
            <Button onClick={handleClick} label="Importar CSV">
                <IconContentAdd style={{ transform: "rotate(180deg)", fontSize: "20" }} />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={closeDialog}
                aria-label="Importar CSV"
            >
                <DialogTitle>Importar CSV</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        // We want no toolbar at all as we have our modal actions
                        toolbar={null}
                        resource="station-attendants"
                    >
                        <ReferenceInput
                            label="Posto"
                            source="stationId"
                            reference="stations"
                            filter={permissions === 'manager' ? { chainId: localStorage.getItem('chainId') } : {}}
                            perPage={false}
                            sort={{ field: "name", order: "ASC" }}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => {
                                const config = {
                                    logging: true,
                                    transformRows: (rows) => {
                                        rows.forEach(row => row.stationId = formData.stationId);
                                        return rows.filter(row => row.identfid);
                                    },
                                    postCommitCallback: (result) => {
                                        closeDialog();
                                        if (result.length && !result[0].success) {
                                            notify(result[0].err.message, 'warning')
                                        }
                                    }
                                };
                                return (<ImportButton {...props} {...rest} {...config} />);
                            }}
                        </FormDataConsumer>
                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <Button label="ra.action.cancel" onClick={closeDialog}>
                        <IconCancel />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );

}
