import React from 'react';
import {
	SimpleForm,
	TextInput,
	usePermissions,
	SelectInput,
	ReferenceInput,
	Create,
	NumberInput,
	maxLength,
	required,
	TextField,
	Datagrid,
	List,
	Filter,
	SearchInput,
	Edit,
	BooleanInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { ListPagination } from '../components';

const useStyles = makeStyles((theme) => ({
	chain: {
		width: 255,
	}
}));

const Filters = props => (
	<Filter {...props}>
		<SearchInput source="search" alwaysOn />
	</Filter>
);

export const SaleProductsList = props =>
	<List
		{...props}
		perPage={25}
		pagination={<ListPagination />}
		exporter={false}
		bulkActionButtons={false}
		title="Produtos de Venda"
		filterDefaultValues={{ chainId: localStorage.getItem('chainId') }}
		filters={<Filters />}
	>
		<Datagrid rowClick="edit">
			<TextField source="code" label="Código" />
			<TextField source="name" label="Nome" />
		</Datagrid>
	</List>;

export const SaleProductsCreate = props => {
	const classes = useStyles();
	const { permissions } = usePermissions();

	return (
		<Create {...props} title="Criar Produto">
			<SimpleForm initialValues={{ chainId: localStorage.getItem('chainId') }}>
				<NumberInput
					source='code'
					label="Código do Produto"
					min={1}
					validate={[required(), maxLength(200)]}
				/>
				<TextInput
					source="name"
					label="Nome"
					InputProps={{ autoComplete: 'off' }}
					validate={[required(), maxLength(255)]}
				/>
				{/* <ReferenceInput
					label="Categoria do Produto"
					source="groupId"
					reference='chains/chainId/product-categories'
					sort={{ field: 'name', order: 'ASC' }} >
					<SelectInput optionText="name" />
				</ReferenceInput> */}
				{
					permissions && permissions.includes('admin')
						? <ReferenceInput
							className={classes.chain}
							label="Rede"
							source="chainId"
							reference="chains"
							perPage={false}
							sort={{ field: 'name', order: 'ASC' }} >
							<SelectInput optionText="name" />
						</ReferenceInput>
						: null
				}
			</SimpleForm>
		</Create>
	);
};

export const SaleProductsEdit = props => {
	const classes = useStyles();
	const { permissions } = usePermissions();

	return (
		<Edit {...props} title="Editar Produto">
			<SimpleForm initialValues={{ chainId: localStorage.getItem('chainId') }}>
				<NumberInput
					source='code'
					label="Código do Produto"
					min={1}
					validate={maxLength(200)}
				/>
				<TextInput
					source="name"
					label="Nome"
					InputProps={{ autoComplete: 'off' }}
					validate={maxLength(255)}
				/>
				{/* <ReferenceInput
					label="Categoria do Produto"
					source="groupId"
					reference='chains/chainId/product-categories'
					sort={{ field: 'name', order: 'ASC' }} >
					<SelectInput optionText="name" />
				</ReferenceInput> */}
				{
					permissions && permissions.includes('admin')
						? <ReferenceInput
							className={classes.chain}
							label="Rede"
							source="chainId"
							reference="chains"
							perPage={false}
							sort={{ field: 'name', order: 'ASC' }} >
							<SelectInput optionText="name" />
						</ReferenceInput>
						: null
				}
				<BooleanInput label="Desativar Produto" source="isDisabled" InputProps={{ autoComplete: 'off' }} />
			</SimpleForm>
		</Edit>
	);
};
