import React, { useState, useEffect } from "react";
import { ReportLayout, useReportContext, GenericDatagridField, GenericDatagrid, GenericSelect, RotatedAxisTick } from '../../components';
import { formatNumber } from '../../utils/utils';
import { useNotify } from 'react-admin';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { RadioGroup, Radio, FormControlLabel, FormControl } from '@material-ui/core';
import momement from 'moment';

const colors = ['#608DE0', '#E0348A', '#E0AD3F', '#34E034', '#17E0DC', '#9934E0', '#E0793F', '#E05C3F', '#4612E0', '#16E097', '#E0D134', '#E016CE'];

const FuelBySupply = () => {
  const [chartType, setChartType] = useState('totalValue');
  const [fuelData, setFuelData] = useState([]);
  const { data, error, setData, filters } = useReportContext();
  const notify = useNotify();

  if (error) { notify('Erro ao carregar Relatório de Abastecimentos por Combustível', 'warning'); };
  
  const sortData = (a, b) => {
    return b.totalValue - a.totalValue;
  }
  useEffect(() => {
    if (data && data.data) {
      
      if (filters.fuelId) {
        setFuelData(data.data);
      } else {
        const filteredData = data.data.filter(fuel => !!fuel.totalValue).sort(sortData);
        filteredData.unshift({
          name: 'Todos',
          totalAmount: data.totalAmount,
          totalFillins: data.total,
          totalValue: data.totalValue,
        });        
        setFuelData(filteredData);  
      }
    }
  }, [data]);

  return (
    <>
      <GenericDatagrid
        style={{ margin: '0 0 40px 0' }}
        textAlign='center'
        data={data && fuelData}
      >
        <GenericDatagridField
          label='Combustível'
          source='name'
        />
        <GenericDatagridField
          label='Total em Dinheiro'
          render={({ totalValue }) => formatNumber(totalValue, { style: 'currency', currency: 'BRL' })}
        />
        <GenericDatagridField
          label='Total em Litros'
          render={({ totalAmount }) => formatNumber(totalAmount, { style: 'decimal', unit: 'liter' })}
        />
        <GenericDatagridField
          label='Total de abastecimentos'
          render={({ totalFillins }) => formatNumber(totalFillins)}
        />
        <GenericDatagridField
          label='Ticket Médio'
          render={({ totalValue, totalFillins }) => formatNumber(totalValue / totalFillins || 0, { style: 'currency', currency: 'BRL' })}
        />
        <GenericDatagridField
          label='Volume Médio'
          render={({ totalAmount, totalFillins }) => formatNumber(totalAmount / totalFillins || 0, { style: 'decimal', unit: 'liter' })}
        />
      </GenericDatagrid>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <FormControl>
          <RadioGroup row defaultValue='totalValue' onChange={({ target: { value } }) => {
            setChartType(value);
            // work around for line animation
            setData({ ...data, chartData: [...data.chartData] });
          }}>
            <FormControlLabel value='totalValue' label='Dinheiro' control={<Radio />} />
            <FormControlLabel value='totalFillins' label='Número de Abastecimentos' control={<Radio />} />
            <FormControlLabel value='totalAmount' label='Volume' control={<Radio />} />
          </RadioGroup>
        </FormControl>
        <LineChart width={790} height={600} data={data ? data.chartData : []}>
          <XAxis dataKey="date" tick={<RotatedAxisTick fontSize={16} />} height={100} />
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis domain={['auto', 'auto']} type='number' />
          <Tooltip formatter={(value) => {
            switch (chartType) {
              case 'totalValue':
                return formatNumber(value, { style: 'currency', currency: 'BRL' });
              case 'totalAmount':
                return formatNumber(value, { style: 'decimal', unit: 'liter' });
              case 'totalFillins':
                return `${formatNumber(value)} Abastecimentos`;
              default:
                return ''
            }
          }} />
          {data && data.data.map((fuel, i) =>
            data.chartData.some(point => point[`${fuel.id}-totalFillins`]) &&
            <Line
              type='monotone'
              dataKey={`${fuel.id}-${chartType}`}
              stroke={i <= 11 ? colors[i] : colors[((i + 1) % 12) - 1]}
              name={fuel.name}
              activeDot={{ r: 5 }}
              key={i}
            />
          )}
        </LineChart>
      </div>
    </>
  );
};

const FuelBySupplyReport = () => {
  const [fuelId, setFuelId] = useState();

  return (
    <ReportLayout
      chainSelect
      stationSelect
      permissions={['admin', 'manager']}
      resource='fuel-by-supply'
      initialFrom={momement().startOf('day').subtract(1, 'month')}
      filtersValue={{ fuelId }}
      filtersComponent={
        <GenericSelect
          reference='fuels'
          allowEmpty
          value={fuelId}
          setValue={setFuelId}
          label='Produto'
        />
      }
    >
      <FuelBySupply />
    </ReportLayout>
  );
};

export default FuelBySupplyReport;