import { Card, makeStyles, Typography } from '@material-ui/core';
import React, { useRef, cloneElement } from 'react';
import { ExportButton, TopToolbar, ListBase, Title, ListToolbar, BulkActionsToolbar, Pagination } from 'react-admin';
import { PrintButton, ReportPrint } from '..';

const useStyles = makeStyles(theme => ({
  printButton: {},
  filterTablePrint: {
    display: 'none',
    '@media print': {
      display: 'block'
    },
    marginBottom: 10,
  },
  contentPagination: {
    '@media print': {
      display: 'none'
    },
  },
  titleCard: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
}));

const ReportList = ({ children, actions, bulkActionButtons, filters, title, pagination, exporter, disablePrint, landscape, titleOnPrint, filterTablePrint, ...props }) => {
  const classes = useStyles();
  const reportRef = useRef();

  const PostShowActions = () => (
    <TopToolbar>
      {exporter &&
        <ExportButton resource={props.resource} exporter={exporter || undefined} />
      }
      {!disablePrint &&
        <PrintButton printRef={reportRef} className={classes.printButton} />
      }
    </TopToolbar>
  );

  return (
    <ListBase {...props}>
      <Title title={title} />
      <ListToolbar
        filters={filters}
        actions={<PostShowActions />}
      />
      <ReportPrint ref={reportRef} title={titleOnPrint || title} landscape={landscape || false}>
        {filterTablePrint &&
          <div className={classes.filterTablePrint}>
            <Typography className={classes.titleCard}>Filtros</Typography>
            <Card>
              {filterTablePrint}
            </Card>
          </div>
        }
        <Card>
          <BulkActionsToolbar>
            {bulkActionButtons}
          </BulkActionsToolbar>
          {
            cloneElement(children, {
              hasBulkActions: bulkActionButtons !== false,
            })
          }
          <div className={classes.contentPagination}>
            {pagination ? pagination : <Pagination />}
          </div>
        </Card>
      </ReportPrint>
    </ListBase>
  )
};

export default ReportList;