import React, { useState } from "react";
import {
  Datagrid,
  List,
  TextField,
  ReferenceField,
  Filter,
  FunctionField,
  DateField,
  NumberField,
  Button,
  BooleanInput,
  useRefresh,
  useNotify,
  useDataProvider,
  SelectArrayInput,
} from "react-admin";

export const PaymentsList = ({ permissions, ...props }) => {

  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);

  const onClickPaymentVerified = (record) => {
    setLoading(true);
    dataProvider.update(props.resource, { id: record.id, data: { verifiedByEmployee: true }, previousData: record })
      .then(() => {
        notify('Atualizado com sucesso.');
        refresh();
      })
      .catch(() => { notify('Erro ao atualizar.'); })
      .finally(() => setLoading(false))
  };

  const PaymentsFilter = (props) => (
    <Filter {...props}>
      <SelectArrayInput source="status" label="Estado" choices={[
        { id: 'ATIVA', name: 'Pendente' },
        { id: 'EXPIRADA', name: 'Expirado' },
        { id: 'CONCLUIDA', name: 'Aprovado' },
      ]} alwaysOn />
      <BooleanInput source={'verifiedByEmployee'} label="Mostrar concluídos" alwaysOn />
    </Filter>
  );

  return (
    <List {...props}
      title="Pagamentos"
      filters={<PaymentsFilter />}
      bulkActionButtons={false}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filter={{ stationId: localStorage.getItem('stationId') }}
      filterDefaultValues={{ verifiedByEmployee: false, status: ['ATIVA', 'CONCLUIDA'] }}
    >
      <Datagrid>
        <DateField source="createdAt" label="Data" locales={"pt-BR"} showTime />
        <TextField source="fillin.hoseNumber" label="Bico" textAlign="center" />
        <NumberField
          sortable={false}
          source="fillin.value"
          label="Valor"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          sortable={false}
          source="fillin.amount"
          label="Volume"
          textAlign="center"
          emptyText="0.0"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <ReferenceField sortable={false} label="Combustível" source="fillin.fuelId" reference="fuels" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <FunctionField label="Status" render={record => {
          switch (record.status) {
            case "ATIVA": return "Pendente";
            case "CONCLUIDA": return "Aprovado";
            case "EXPIRADA": return "Expirado";
            default: return "";
          }
        }} />
        <FunctionField sortable={false} render={record => {
          if (record.verifiedByEmployee === false) {
            return <Button variant="contained" disabled={loading} label='Concluído' onClick={() => onClickPaymentVerified(record)} />
          }
        }} />
      </Datagrid>
    </List>
  );
};