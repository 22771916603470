import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Paper } from '@material-ui/core';
import {
    useNotify,
    usePermissions,
    NumberInput,
    useDataProvider,
    SimpleForm,
} from 'react-admin';
import Permission from '../components/Permission';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: 300,
        marginTop: 40,
    },
}));

const TabPanel = ({ children, value, index }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`settings-tabpanel-${index}`}
        aria-labelledby={`settings-tab-${index}`}
    >
        {value === index && children}
    </div>
);

const Config = () => {
    const { loaded: loadedPermissions, permissions } = usePermissions();
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(null);
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const classes = useStyles();

    const patchConfig = data => {
        setLoading(true);
        dataProvider
          .UPDATE(`config`, { data, previousData: { id: initialValues.id } })
          .then(() => notify('Configurações salvas com sucesso'))
          .catch(() => notify('Erro ao salvar configurações', 'warning'))
          .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (loadedPermissions && permissions.includes('admin')) {
            setLoading(true);
            dataProvider
                .getOne(`config`, {})
                .then(({ data }) => setInitialValues(data))
                .catch(() => notify('Erro ao buscar configurações atuais', 'warning'))
                .finally(() => setLoading(false));
        }
    }, [loadedPermissions]);

    return (
        <Permission permission='admin'>
            <Paper>
                <SimpleForm save={patchConfig} saving={loading} initialValues={initialValues}>
                    <Tabs
                        value={value}
                        onChange={(event, newValue) => setValue(newValue)}
                        style={{ width: '100%' }}
                    >
                        <Tab label="Pontuação" id="settings-tab-0" aria-controls="settings-tabpanel-0" />
                        <Tab label="Indicação" id="settings-tab-1" aria-controls="settings-tabpanel-1" />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <div className={classes.container}>
                            <NumberInput
                                source="discountPrice"
                                label="Valor do Resgate"
                                min={0}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div className={classes.container}>
                            <NumberInput
                                source="referralValue"
                                label="Valor para quem indica"
                                min={0}
                            />
                            <NumberInput
                                source="refereeValue"
                                label="Valor para indicado"
                                min={0}
                            />
                        </div>
                    </TabPanel>
                </SimpleForm>
            </Paper>
        </Permission>
    );
};

export default Config;