export { strip } from '@fnando/cpf';

export const formatCellphone = (num) => {
  if (!num) return num;
  if (num.length < 3)
    return `${num.substring(0, 2)}`;
  if (num.length < 7)
    return `(${num.substring(0, 2)}) ${num.substring(2, 6)}`;
  if (num.length < 11)
    return `(${num.substring(0, 2)}) ${num.substring(2, 6)}-${num.substring(6, 10)}`;
  return `(${num.substring(0, 2)}) ${num.substring(2, 7)}-${num.substring(7, 11)}`;
};

export const formatPrice = (number, options) => {
  return new Intl.NumberFormat('pt-BR', { style:'currency', currency:'BRL' }).format(number);
};

export const formatCep = (num) => {
  if (!num) return num;
  if (num.length < 6)
    return `${num.substring(0, 5)}`;
  return `${num.substring(0, 5)}-${num.substring(5, 8)}`;
};

export const formatCpf = (num) => {
  if (!num) return num;
  if (num.length < 4)
    return `${num.substring(0, 3)}`;
  if (num.length < 7)
    return `${num.substring(0, 3)}.${num.substring(3, 6)}`;
  if (num.length < 10)
    return `${num.substring(0, 3)}.${num.substring(3, 6)}.${num.substring(6, 9)}`;
  return `${num.substring(0, 3)}.${num.substring(3, 6)}.${num.substring(6, 9)}-${num.substring(9, 11)}`
};

export const formatCnpj = (num) => {
  if (!num) return num;
  if (num.length < 3)
    return `${num.substring(0, 2)}`;
  if (num.length < 6)
    return `${num.substring(0, 2)}.${num.substring(2, 5)}`;
  if (num.length < 9)
    return `${num.substring(0, 2)}.${num.substring(2, 5)}.${num.substring(5, 8)}`;
  if (num.length < 13)
    return `${num.substring(0, 2)}.${num.substring(2, 5)}.${num.substring(5, 8)}/${num.substring(8, 12)}`;
  return `${num.substring(0, 2)}.${num.substring(2, 5)}.${num.substring(5, 8)}/${num.substring(8, 12)}-${num.substring(12, 14)}`
};

export const formatId = (num) => {
  var id = '0000' + num;
  return `${id.substring(id.length - 4)} `;
};

export const formatNumber = (number, options) => {
  const { unit, style, currency, minimumFractionDigits } = options || {};
  return new Intl.NumberFormat('pt-BR', { unit, style: style || "decimal", currency, minimumFractionDigits }).format(number);
};

// needed to overwrite DateInput formatter
const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
const convertDateToString = (value) => {
  if (!(value instanceof Date) || isNaN(value.getDate())) return null;
  const pad = '00';
  const yyyy = value.getUTCFullYear().toString();
  const MM = (value.getUTCMonth() + 1).toString();
  const dd = value.getUTCDate().toString();
  return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};

export const dateInputFormatter = (value) => {
  if (value == null || value === '') {
    return null;
  }
  if (value instanceof Date) {
    return convertDateToString(value);
  }
  // valid dates should not be converted
  if (dateRegex.test(value)) {
    return value;
  }
  return convertDateToString(new Date(value));
};
