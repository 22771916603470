import React from "react";
import { BooleanInput, Datagrid, DateField, downloadCSV, NumberField, ReferenceField, ReferenceInput, SelectInput, TextField, useListContext, usePermissions } from 'react-admin';
import { ReportList, ListPagination } from '../../components';
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { makeStyles, Typography } from '@material-ui/core';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';
import { DateInput } from "../../components/DateInput";

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  }
}));

const FillinsList = () => {
  const classes = useStyles();
  const props = useListContext();


  const fillinInfo = props.data['fillins'];
  let data = {};
  let ids = [];
  let summaryData = {};

  if (fillinInfo) {
    // convert array to object passing 'id' as key
    fillinInfo.data.forEach(element => {
      data[element.id] = element;
    });

    ids = fillinInfo.data.map(item => item.id);
    summaryData = { summary: fillinInfo.summaryData };
  }

  return (
    <>
      <Typography className={classes.title}>Total</Typography>
      <Datagrid classes={{ headerCell: classes.headerCell }} style={{ marginBottom: 60 }} data={summaryData} ids={['summary']} total={1}>
        <NumberField
          source="total_value"
          label="Total em Dinheiro"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="total_amount"
          label="Total em Litros"
          textAlign="center"
          emptyText="0"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <TextField source="total_fillins" textAlign="center" label="Total de abastecimentos" sortable={false} />
        <NumberField
          source="average_ticket"
          label="Ticket Médio"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
        <NumberField
          source="average_amount"
          label="Volume Médio"
          textAlign="center"
          emptyText="0"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          sortable={false}
        />
        <NumberField
          source="discount"
          label="Total de descontos"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,00"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
          sortable={false}
        />
      </Datagrid>

      <Typography className={classes.title}>Abastecimento</Typography>
      <CustomizableDatagrid classes={{ headerCell: classes.headerCell }} resource={props.resource} data={data} ids={ids} rowClick="show" defaultColumns={['station_id', 'chain_id', 'value', 'amount', 'price_per_liter', 'old_price_per_liter', 'discount', 'fuel_id', 'created_at']}>
        <ReferenceField label="Posto" emptyText="Posto não indentificado" source="station_id" basePath="stations" reference="stations" link="show">
          <TextField source="name" />
        </ReferenceField>
        <NumberField
          source="hose_number"
          label="Bico"
          textAlign="center"
          emptyText="Não definido"
        />
        <ReferenceField label="Frentista" emptyText="Não indentificado" source="attendant_id" basePath="station-attendants" reference="station-attendants" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Cliente" emptyText="Não indentificado" source="customer_id" basePath="customers" reference="customers" link="show">
          <TextField source="name" />
        </ReferenceField>
        <NumberField
          source="value"
          label="Valor"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="amount"
          label="Litros"
          textAlign="center"
          emptyText="0"
          options={{
            maximumFractionDigits: 2
          }}
        />
        <NumberField
          source="price_per_liter"
          label="Preço com desconto"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }}
        />
        <NumberField
          source="old_price_per_liter"
          label="Preço normal"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
          }}
        />
        <NumberField
          source="discount"
          label="Desconto"
          locales="pt-BR"
          textAlign="center"
          emptyText="R$ 0,000"
          options={{
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }}
        />
        <ReferenceField label="Combustível" emptyText="Não indentificado" source="fuel_id" basePath="fuels" reference="fuels" link="show">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="created_at" label="Data" showTime locales="pt-BR" />
      </CustomizableDatagrid>
    </>
  );
};


const fillinsExporter = async (data, fetchRelatedRecords) => {
  let fillinInfo = data[0].data;

  const fetchAttendants = fetchRelatedRecords(fillinInfo, 'attendant_id', 'station-attendants');
  const fetchFuels = fetchRelatedRecords(fillinInfo, 'fuel_id', 'fuels');
  const fetchStations = fetchRelatedRecords(fillinInfo, 'station_id', 'stations');
  const fetchCustomer = fetchRelatedRecords(fillinInfo, 'customer_id', 'customers');

  const fetchData = await Promise.all([fetchAttendants, fetchFuels, fetchStations, fetchCustomer]);

  fillinInfo = fillinInfo.map(fillin => {
    const { attendant_id, fuel_id, station_id, customer_id, ...fillinRest } = fillin;
    return {
      ...fillinRest,
      attendant_name: attendant_id ? fetchData[0][attendant_id].name : 'Não indentificado',
      fuel_name: fuel_id ? fetchData[1][fuel_id].name : 'Não indentificado',
      station_name: station_id ? fetchData[2][station_id].name : 'Não indentificado',
      customer_name: customer_id ? fetchData[3][customer_id].name : 'Não indentificado',
    }
  });

  jsonExport(fillinInfo, {
    headers: ['id', 'station_name', 'fuel_name', 'attendant_name', 'customer_name']
  }, (err, csv) => {
    downloadCSV(csv, 'fillins');
  });
}

const FilterTablePrint = () => {
  const classes = useStyles();
  const { filterValues } = useListContext();
  return (
    <Datagrid classes={{ headerCell: classes.headerCell }} data={{ filters: { ...filterValues, generatedReportAt: moment() } }} ids={['filters']} total={1}>
      <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
      <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
      <ReferenceField label="Posto" emptyText="Todos" source="stationId" textAlign="center" basePath="stations" reference="stations" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Produto" emptyText="Todos" source="fuelId" textAlign="center" basePath="fuels" reference="fuels" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
    </Datagrid>
  );
}

const FillinsReport = (props) => {
  const { permissions } = usePermissions();

  const listFilters = [
    <DateInput source="from" label="De" options={{ format: 'DD/MM/YYYY' }} alwaysOn />,
    <DateInput source="to" label="Até" options={{ format: 'DD/MM/YYYY' }} alwaysOn />,
    <ReferenceInput
      label="Posto"
      source="stationId"
      reference="stations"
      sort={{ field: "name", order: "ASC" }}
      emptyText="Todos"
      filter={permissions === 'manager' ? { chainId: localStorage.getItem('chainId') } : {}}
      perPage={false}
      alwaysOn
    >
      <SelectInput
        optionText={'name'}
      />
    </ReferenceInput>,
    <ReferenceInput label="Produto" source="fuelId" reference="fuels" sort={{ field: "name", order: "ASC" }} emptyText="Todos" perPage={false} alwaysOn>
      <SelectInput
        optionText={'name'}
      />
    </ReferenceInput>,
    <BooleanInput label="Desconto" source="withDiscount" alwaysOn />
  ];

  if (permissions === 'admin') {
    listFilters.splice(2, 0,
      <ReferenceInput label="Rede" source="chainId" reference="chains" perPage={false} sort={{ field: 'name', order: 'ASC' }} emptyText="Todos" alwaysOn>
        <SelectInput
          optionText={'name'} />
      </ReferenceInput>,
    )
  }

  return (
    <ReportList
      {...props}
      basePath="fillins"
      title="Abastecimento"
      resource="reports/fillins"
      filters={listFilters}
      filter={localStorage.getItem('chainId') ? { chainId: localStorage.getItem('chainId') } : null}
      sort={{ field: 'created_at', order: 'DESC' }}
      perPage={25}
      filterDefaultValues={{ from: moment().startOf('day').toISOString(), to: moment().endOf('day').toISOString() }}
      pagination={<ListPagination />}
      bulkActionButtons={false}
      exporter={fillinsExporter}
      titleOnPrint="Relatório de abastecimento"
      filterTablePrint={<FilterTablePrint />}
      landscape={true}
    >
      <FillinsList />
    </ReportList>)
};

export default FillinsReport;