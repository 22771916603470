import 'moment/locale/pt-br';
import React, { useState } from 'react';
import moment from 'moment';
import { ReportLayout, useReportContext, GenericDatagrid, GenericDatagridField, GenericSelect } from '../../components';
import { useNotify } from 'react-admin';
import { formatNumber } from '../../utils/utils';
moment.locale('pt-br');

const EvolutionCustomers = ({ dataType }) => {
  const { data, error, from, to } = useReportContext();
  const notify = useNotify();

  if (error) { notify('Erro ao carregar Relatório de Evolução de Clientes', 'warning'); };

  let items = [];

  const sumMonth = (f, t) => {
    if (moment(f).get('month') > moment(t).get('month') && moment(f).get('year') >= moment(t).get('year')) {
      return false;
    } else {
      items.push({
        day: moment(f).get('date').toString().length === 1 ? `0${moment(f).get('date')}` : moment(f).get('date'),
        month: (moment(f).get('month') + 1).toString().length === 1 ? `0${moment(f).get('month') + 1}` : moment(f).get('month') + 1,
        year: moment(f).get('year'),
        quantity: 0
      });
    }
    f = moment(f).add(1, 'month');
    return sumMonth(f, t);
  }

  const sumBiweekly = (f, t) => {
    if (moment(f).get('month') > moment(t).get('month') && moment(f).get('year') >= moment(t).get('year')) {
      return false;
    } else {
      items.push({
        day: moment(f).get('date').toString().length === 1 ? `0${moment(f).get('date')}` : moment(f).get('date'),
        month: (moment(f).get('month') + 1).toString().length === 1 ? `0${moment(f).get('month') + 1}` : moment(f).get('month') + 1,
        year: moment(f).get('year'),
        to: moment(f).add(14, 'days'),
        quantity: 0
      });
    }
    f = moment(f).add(14, 'days');
    return sumBiweekly(f, t);
  }

  const sumDaily = (f, t) => {
    if (moment(f).get('month') > moment(t).get('month') && moment(f).get('year') >= moment(t).get('year')) {
      return false;
    } else {
      items.push({
        day: moment(f).get('date').toString().length === 1 ? `0${moment(f).get('date')}` : moment(f).get('date'),
        month: (moment(f).get('month') + 1).toString().length === 1 ? `0${moment(f).get('month') + 1}` : moment(f).get('month') + 1,
        year: moment(f).get('year'),
        quantity: 0
      });
    }
    f = moment(f).add(1, 'days');
    return sumDaily(f, t);
  }

  if (dataType === "monthly") {
    sumMonth(from, to);
  }

  if (dataType === "biweekly") {
    sumBiweekly(from, to);
  }

  if (dataType === "daily") {
    sumDaily(from, to);
  }

  dataType === "monthly" && data && data.data.forEach(data => {
    items.forEach((e, i) => {
      if (parseInt(e.month) === (moment(data.createdAt).get('month') + 1) && parseInt(e.year) === moment(data.createdAt).get('year')) {
        items[i].quantity++;
      }
    });
  });

  dataType === "biweekly" && data && data.data.forEach(data => {
    items.forEach((e, i) => {
      if (parseInt(e.day) < moment(data.createdAt).get('date') && moment(e.to).get('date') > moment(data.createdAt).get('date') && parseInt(e.month) === (moment(data.createdAt).get('month') + 1) && parseInt(e.year) === moment(data.createdAt).get('year')) {
        items[i].quantity++;
      }
    });
  });

  dataType === "daily" && data && data.data.forEach(data => {
    items.forEach((e, i) => {
      if (parseInt(e.day) === moment(data.createdAt).get('date') && parseInt(e.month) === (moment(data.createdAt).get('month') + 1) && parseInt(e.year) === moment(data.createdAt).get('year')) {
        items[i].quantity++;
      }
    });
  });

  return (
    <>
      <GenericDatagrid
        data={data && [{ total: data.total }]}
        style={{ margin: '0 0 50px 0' }}
        textAlign='center'
      >
        <GenericDatagridField label='Total de Clientes' render={({ total }) => formatNumber(total)} />
      </GenericDatagrid>
      <GenericDatagrid
        data={items}
        title='Relatório de Evolução de Clientes'
        total={items.length}
        textAlign='center'
      >
        <GenericDatagridField
          label={dataType === "monthly" ? "Mês" : "Dia"}
          source='dataType'
          render={({ day, month, year, to }) => {
            if (dataType === "daily") {
              return `${day}/${month}/${year}`;
            } else if (dataType === "biweekly") {
              return `${day}/${month}/${year} até ${moment(to).format('DD/MM/YYYY')}`;
            } else {
              return `${month}/${year}`;
            }
          }}
        />
        <GenericDatagridField label='Quantidade' source='quantity' />
      </GenericDatagrid>
    </>
  );
};

const EvolutionCustomersReport = () => {
  const [dataType, setDataType] = useState('monthly');

  return (
    <ReportLayout
      permissions={['admin']}
      resource='new-customers'
      chainSelect
      filtersComponent={[
        <GenericSelect
          label='Período'
          options={[{ id: 'daily', name: 'Diário' }, { id: 'biweekly', name: 'Quinzenal' }, { id: 'monthly', name: 'Mensal' }]}
          value={dataType}
          setValue={setDataType}
        />
      ]}
    >
      <EvolutionCustomers dataType={dataType} />
    </ReportLayout>
  );
}

export default EvolutionCustomersReport;