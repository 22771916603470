import React from 'react'
import CardContent from '@material-ui/core/CardContent'
import { Paper } from '@material-ui/core'


const divStyle = {
  padding: '20px 20px 20px 20px',
}

export const Wrapper = props => (
  <CardContent>
    <Paper>
      <div style={divStyle}>{props.children}</div>
    </Paper>
  </CardContent>
)