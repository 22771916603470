import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { DashboardWrapper } from '../../components';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles({
  title: {
    fontSize: '2.5625rem'
  }
});

const FillinDuration = () => {
  const dataProvider = useDataProvider();
  const [duration, setTime] = useState(0);
  const chainId = useState(localStorage.getItem('chainId'));
  const stationId = useState(localStorage.getItem('stationId'));
  const classes = useStyles();

  useEffect(() => {
    const filter = {};
    if (stationId) { filter.stationId = stationId; }
    else if (chainId) { filter.chainId = chainId; }

    dataProvider.getList('dashboard/fillin-duration', {
      filter: {
        from: moment().startOf('day').subtract(1, 'month'),
        to: moment().endOf('day')
      }
    })
      .then(({ data }) => {
        const { 0: res } = data;
        setTime(moment('0:0:0', 'H:m:s').add(res.time.toFixed(2) * 60000, 'milliseconds').format('HH:mm:ss'));
      }).catch(err => console.error(err));
  }, []);

  return (
    <DashboardWrapper
      permissions={['manager', 'admin']}
      title="Duração de um abastecimento"
      content={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography className={classes.title} variant="h3">{duration}</Typography>
        </div>
      }
    />
  );
};

export default FillinDuration;