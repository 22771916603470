import React from 'react';
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  DeleteButton,
  List,
  SimpleForm,
  TextField,
  SelectField,
  SelectInput,
  TextInput,
  ReferenceField,
  ReferenceInput,
  FormDataConsumer
} from 'react-admin';
import { SearchFilter } from "../components/SearchFilter";
import Permission from '../components/Permission';

const required = (message = 'Required') =>
  value => value ? undefined : message;

export const EmployeeList = (props) => (
  <List {...props} title="Funcionários" filters={<SearchFilter />} bulkActionButtons={props.permissions === 'admin'}>
    <Datagrid>
      <TextField source="login" />
      <SelectField source="role" label="Permissão"
        choices={[
          { id: 'admin', name: 'Administrador' },
          { id: 'manager', name: 'Gerente de rede' },
          { id: 'cashier', name: 'Caixa' },
          { id: 'partner', name: 'Parceiro' }
        ]}
      />
      <Permission permission='admin'>
        <ReferenceField label="Rede" source="chainId" reference="chains" allowEmpty>
          <TextField source="name" />
        </ReferenceField>
      </Permission>
      <DateField source="lastLogin" label="Último login" locales="pt-BR" showTime />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export const EmployeeEdit = (props) => {
  const choices = [
    { id: 'manager', name: 'Gerente de rede' },
    { id: 'cashier', name: 'Caixa' },
    { id: 'partner', name: 'Parceiro' }
  ];

  if (props.permissions === 'admin') {
    choices.push({ id: 'admin', name: 'Administrador' });
  }

  return (
    <Edit title='Editar funcionário' {...props}>
      <SimpleForm>
        <TextInput disabled label="ID" source="id" />
        <TextInput source="name" label="Nome" />
        <TextInput source="login" />
        <TextInput source="password" label="Senha" type="password" />
        <SelectInput source="role" label="Permissão"
          choices={choices}
        />
        <Permission permission='admin'>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.role !== 'admin' &&
              <ReferenceInput label="Rede" source="chainId" reference="chains" sort={{ field: 'name', order: 'ASC' }} pagination={null} perPage={null}>
                <SelectInput optionText="name" />
              </ReferenceInput>
            }
          </FormDataConsumer>
        </Permission>
        <FormDataConsumer>
          {({ formData }) =>
            (formData.role === 'cashier' || formData.role === 'partner') &&
            <ReferenceInput
              label="Local"
              source="stationId"
              reference="places"
              sort={{ field: 'name', order: 'ASC' }}
              perPage={false}
              filter={{ chainId: formData.chainId }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export const EmployeeCreate = (props) => {
  const choices = [
    { id: 'manager', name: 'Gerente de rede' },
    { id: 'cashier', name: 'Caixa' },
    { id: 'partner', name: 'Parceiro' }
  ];

  if (props.permissions === 'admin') {
    choices.push({ id: 'admin', name: 'Administrador' });
  }

  return (
    <Create title='Adicionar funcionário' {...props}>
      <SimpleForm redirect={props.permissions === 'admin' ? 'edit' : 'list'}>
        <TextInput source="name" label="Nome" validate={required()} />
        <TextInput source="login" validate={required()} />
        <TextInput source="password" label="Senha" type="password" defaultValue="" validate={required()} />
        <SelectInput source="role" label="Permissão"
          choices={choices}
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData }) => {
            if (props.permissions === 'manager') {
              formData.chainId = localStorage.getItem('chainId');
            }
            return (
              formData.role !== 'admin' &&
              <Permission permission='admin'>
                <ReferenceInput label="Rede" source="chainId" reference="chains" perPage={false} sort={{ field: 'name', order: 'ASC' }}>
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </Permission>
            );
          }}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) =>
            (formData.role === 'cashier' || formData.role === 'partner') &&
            <ReferenceInput
              label="Local"
              source="stationId"
              reference="places"
              sort={{ field: 'name', order: 'ASC' }}
              filter={{ chainId: formData.chainId }}
              pagination={null} 
              perPage={null}
            >
              <SelectInput optionText="name" validate={required()} />
            </ReferenceInput>
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}