import React from "react";
import {
    TextField,
    useListContext,
    ReferenceField,
    NumberField,
    DateField,
    Datagrid,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
    usePermissions
} from 'react-admin';
import { ReportList } from '../../components';
import { DateInput } from '../../components/DateInput';
import ListPagination from '../../components/ListPagination';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    title: {
        margin: '10px 0 10px 0',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    headerCell: {
        backgroundColor: '#E5E5E5',
    }
}));

const sortData = (a, b) => {
    return b.totalValue - a.totalValue;
}

const FuelByAttendant = () => {
    const props = useListContext();
    const classes = useStyles();

    let ids = [];
    let attendantData = props.data;

    if (attendantData) {
        Object.keys(attendantData).forEach((attendant) => {
            if (attendantData[attendant].totalValue === 0) {
                if (Object.keys(attendantData).length > 1 && attendantData[attendant].id !== '0')
                    delete attendantData[attendant]
            }
        });

        ids = Object.values(attendantData).sort(sortData).map(item => item.id);

        // removing row "Todos" when there's only one attendant
        if (ids.length === 2 && ids[0] === '0') {
            ids.shift();
        }
    }

    return (
        <Datagrid classes={{ headerCell: classes.headerCell }} style={{ marginTop: 40 }} data={attendantData} ids={ids} total={1}>
            <TextField
                source="attendant_name"
                textAlign="center"
                label="Frentista"
            />
            <TextField
                source="identfid"
                textAlign="center"
                label="Identfid"
            />
            <NumberField
                source="total_value"
                label="Total em Dinheiro"
                locales="pt-BR"
                textAlign="center"
                options={{
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }}
            />
            <TextField
                source="total_fillins"
                textAlign="center"
                label="Total de abastecimentos"
            />
            <NumberField
                source="total_amount"
                label="Total em Litros"
                textAlign="center"
                options={{
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }}
            />
            <NumberField
                source="average_ticket"
                label="Ticket Médio"
                locales="pt-BR"
                textAlign="center"
                options={{
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }}
            />
            <NumberField
                source="average_amount"
                label="Volume Médio"
                textAlign="center"
                options={{
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }}
            />
        </Datagrid>
    );
};

const listFilters = [
    <DateInput source="from" label="De" options={{ format: 'DD/MM/YYYY' }} alwaysOn />,
    <DateInput source="to" label="Até" options={{ format: 'DD/MM/YYYY' }} alwaysOn />,
    <ReferenceInput
        label="Posto"
        source="stationId"
        reference="stations"
        perPage={false}
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
        alwaysOn
    >
        <SelectInput
            style={{ minWidth: 180 }}
            optionText={'name'}
        />
    </ReferenceInput>,
    <ReferenceArrayInput
        label="Produto"
        source="fuelIds"
        reference="fuels"
        sort={{ field: "name", order: "ASC" }}
        emptyText="Todos"
        alwaysOn
    >
        <SelectArrayInput
            style={{ minWidth: 180 }}
            optionText={'name'}
        />
    </ReferenceArrayInput>
];

const FilterTablePrint = () => {
    const classes = useStyles();
    const { filterValues } = useListContext();
    return (
        <Datagrid classes={{ headerCell: classes.headerCell }} data={{ filters: { ...filterValues, generatedReportAt: moment() } }} ids={['filters']} total={1}>
            <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
            <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
            <ReferenceField label="Posto" emptyText="Todos" source="stationId" textAlign="center" basePath="stations" reference="stations" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
        </Datagrid>
    );
}

const FuelByAttendantReport = (props) => {
    const { permissions } = usePermissions();

    const defaultFilters = {
        from: moment().startOf('day').subtract(1, "month").toISOString(),
        to: moment().endOf('day').toISOString(),
    }

    if (permissions === 'manager') {
        defaultFilters.chainId = localStorage.getItem('chainId');
    }

    return (
        <ReportList
            {...props}
            basePath="fuel-by-attendant"
            title="Abastecimentos por Frentista"
            resource="reports/fuel-by-attendant"
            filters={listFilters}
            sort={{ field: 'total_fillins', order: 'DESC' }}
            perPage={25}
            pagination={<ListPagination />}
            filterDefaultValues={defaultFilters}
            bulkActionButtons={false}
            exporter={false}
            titleOnPrint="Relatório de Abast. por Frentista"
            filterTablePrint={<FilterTablePrint />}
            landscape={true}
        >
            <FuelByAttendant />
        </ReportList>
    );
};

export default FuelByAttendantReport;