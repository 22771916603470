import React, { useCallback } from 'react';
import { addField } from 'react-admin';
import { TimePicker as TimeInput } from 'material-ui-pickers';
import moment from 'moment';

const TimePicker = ({
  input,
  label,
  ref,
  variant,
  style,
  fullDate
}) => {
  const onChange = useCallback(date => {
    input.onChange(date);
    input.onBlur();
  }, [])

  const calcValue = () => {
    if (input.value) {
      if (moment.isMoment(input.value)) {
        return input.value;
      } else {
        let date: moment.Moment = moment();
        if (fullDate) {
          date = moment(input.value);
        } else {
          let hour = parseInt(input.value.slice(0, 2));
          const minute = parseInt(input.value.slice(3, 5));
          const timeZone = parseInt(input.value.slice(input.value.length - 2));
          if (input.value[input.value.length - 3] === '+') {
            hour -= (timeZone + 3);
          } else {
            hour += (timeZone - 3);
          }
          date.hour(hour);
          date.minute(minute);
        }

        return date;
      }
    }
    return null;
  }

  return (
    <div ref={ref} style={{ display: 'flex', flexDirection: 'column', ...(style || {}) }}>
      <TimeInput
        label={label}
        variant={variant}
        value={calcValue()}
        onChange={date => onChange(date)}
        ampm={false}
        required
        format='HH:mm'
      />
    </div>
  );
};

export default addField(TimePicker);
