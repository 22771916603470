import React, { useState, useEffect } from "react";
import { useNotify } from 'react-admin';
import { GenericDatagridField, GenericDatagrid, ReportLayout, useReportContext, RotatedAxisTick } from '../../components';
import { formatNumber } from '../../utils/utils';
import moment from 'moment';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const colors = ['#608DE0', '#E0348A', '#E0AD3F', '#34E034', '#17E0DC', '#9934E0', '#E0793F', '#E05C3F', '#4612E0', '#16E097', '#E0D134', '#E016CE'];

const StationRating = () => {
  const [stationData, setStationData] = useState([]);
  const { data, error, filters } = useReportContext();
  const notify = useNotify();

  if (error) { notify('Erro ao carregar Relatório de Avaliações do Posto', 'warning'); };

  const sortData = (a, b) => {
    return b.avg - a.avg;
  }
  useEffect(() => {
    if (data && data.data) {  
      
      if (filters.stationId) {
        setStationData(data.data);
      } else {
        const filteredData = data.data.filter(station => !!station.avg).sort(sortData);
        setStationData(filteredData);  
      }
    }
  }, [data]);

  const commomContent = [
    <GenericDatagridField
      label='Nota Média'
      source='avg'
      render={({ avg }) => formatNumber(avg, { minimumFractionDigits: 2 })}
    />,
    <GenericDatagridField
      label='Total de Avaliações'
      source='count'
      render={({ count }) => formatNumber(count)}
    />
  ];

  const content = [<GenericDatagridField label='Posto' source='name' />];

  return (
    <>
      <GenericDatagrid
        style={{ margin: '0 0 40px 0' }}
        textAlign='center'
        title='Relatório de Avaliações do Posto'
        data={data && stationData}
      >
        {content.concat(commomContent)}
      </GenericDatagrid>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <LineChart width={790} height={600} data={data ? data.chartData : []}>
          <XAxis dataKey="date" tick={<RotatedAxisTick fontSize={16} />} height={100} />
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis domain={[0, 5]} type='number' />
          <Tooltip itemStyle={{ fontSize: 12 }} formatter={(avg) => formatNumber(avg, { minimumFractionDigits: 2 })} />
    
          {data && data.data.map((station, i) =>
            data.chartData.some(point => point[`${station.id}-avg`]) &&
            <Line
              type='monotone'
              dataKey={`${station.id}-avg`}
              stroke={i <= 11 ? colors[i] : colors[((i + 1) % 12) - 1]}
              name={station.name}
              activeDot={{ r: 5 }}
              key={i}
            />
          )}
        </LineChart>
      </div>
    </>);
};

const StationRatingReport = () => {
  return (
    <ReportLayout
      stationSelect
      chainSelect
      permissions={['admin', 'manager']}
      resource='station-rating'
      initialFrom={moment().startOf('day').subtract(1, 'month')}
    >
      <StationRating/>
    </ReportLayout>
  );
};

export default StationRatingReport;