import moment from "moment";
import React from "react";
import { Datagrid, DateField, FunctionField, List, NumberField, ReferenceField, ReferenceInput, SelectInput, TextField, useListContext, usePermissions } from "react-admin";
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { DateInput } from "../../components/DateInput";
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '10px 0 10px 0',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  }
}));

const FirstFillinList = () => {
  const classes = useStyles();
  const props = useListContext();

  const fillinInfo = props.data['fillins'];
  let data = {};
  let ids = [];
  let summaryIds = [];
  let summaryData = {};

  if (fillinInfo) {
    // convert array to object passing 'id' as key
    fillinInfo.data.forEach(element => {
      data[element.id] = element;
    });
    ids = fillinInfo.data.map(item => item.id);

    fillinInfo.summaryData.forEach(element => {
      summaryData[element.attendant_id] = element;
    });
    summaryIds = fillinInfo.summaryData.map(item => item.attendant_id);
  }

  return (
    <>
      <Typography className={classes.title}>Top 40</Typography>
      <Datagrid classes={{ headerCell: classes.headerCell }} data={summaryData} ids={summaryIds} total={summaryData.length} style={{ marginBottom: 60 }}>
        <ReferenceField source="attendant_id" reference="station-attendants" label="Frentista" basePath="station-attendants">
          <FunctionField render={record => record.name || record.identfid} label='Nome/Identfid' />
        </ReferenceField>
        <NumberField textAlign="center" source="count" label="Total de Clientes" />
      </Datagrid>

      <Typography className={classes.title}>Primeiro Abastecimento de Clientes</Typography>
      <CustomizableDatagrid classes={{ headerCell: classes.headerCell }} resource={props.resource} rowClick={null} data={data} ids={ids}>
        <ReferenceField source="id" reference="customers" label="Cliente" basePath="customers">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="attendantId" reference="station-attendants" label="Frentista" basePath="station-attendants">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="stationId" reference="stations" label="Posto" basePath="stations">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" label="Data do Abastecimento" showTime locales="pt-BR" />
      </CustomizableDatagrid>
    </>
  );
}

const FirstFillinsReport = (props) => {
  const { permissions, loaded: loadedPermissions } = usePermissions();

  const listFilters = [
    <DateInput source="from" label="De" options={{ disableFuture: true, format: 'DD/MM/YYYY' }} alwaysOn />,
    <DateInput source="to" label="Até" options={{ disableFuture: true, format: 'DD/MM/YYYY' }} alwaysOn />,
    <ReferenceInput
      label="Posto"
      source="stationId"
      reference="stations"
      sort={{ field: "name", order: "ASC" }}
      perPage={false}
      emptyText="Todos"
      filter={permissions === 'manager' ? { chainId: localStorage.getItem('chainId') } : {}}
      alwaysOn
    >
      <SelectInput
        optionText="name"
      />
    </ReferenceInput>
  ];

  return (
    <>
      {loadedPermissions &&
        <List
          {...props}
          title="Primeiro Abastecimento de Clientes"
          basePath="reports"
          resource="reports/first-fillin"
          sort={{ field: "createdAt", order: "DESC" }}
          filters={listFilters}
          filter={permissions === 'manager' ? { chainId: localStorage.getItem('chainId') } : {}}
          filterDefaultValues={{ from: moment().startOf('day').subtract(1, 'month').toISOString(), to: moment().endOf('day').toISOString() }}
        >
          <FirstFillinList />
        </List>
      }
    </>
  );
}

export default FirstFillinsReport;