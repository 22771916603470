import React from 'react';
import { Create, Datagrid, DeleteButton, Edit, EditButton, List, SimpleForm, TextField, TextInput } from 'react-admin';

const required = (message = 'Required') =>
  value => value ? undefined : message;

export const SupportSubjectList = (props) => (
  <List {...props} title="Assunto de suportes" >
    <Datagrid>
      <TextField source="name" label="Assunto"/>
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export const SupportSubjectEdit = (props) => (
  <Edit title="Editar assunto de suporte" {...props}>
    <SimpleForm>
      <TextInput disabled label="ID" source="id" />
      <TextInput source="name" label="Assunto" />
    </SimpleForm>
  </Edit>
);

export const SupportSubjectCreate = (props) => (
  <Create title="Adicionar assunto de suporte" {...props}>
    <SimpleForm>
      <TextInput source="name" label="Assunto" validate={required()} />
    </SimpleForm>
  </Create>
);