import { Route } from 'react-router-dom';
import React from 'react';
import {
    AverageTicketReport,
    EvolutionCustomersReport,
    RecurringCustomersReport,
    NewCustomersReport,
    NewCustomersSummaryReport,
    StationRatingReport,
    StationCommentsReport,
    AttendantRatingReport,
    FillinsReport,
    FuelByAttendantReport,
    FuelBySupplyReport,
    CouponsReport,
    SocketErrorReport,
    PaymentByPixReport

} from "./containers/reports";
import Config from './containers/Config'
import FirstFillinsReport from './containers/reports/FirstFillin';

export default [
    <Route exact path='/fillin-report' component={FillinsReport} />,
    <Route exact path='/fuel-by-attendant-report' component={FuelByAttendantReport} />,
    <Route exact path="/average-ticket-report" component={AverageTicketReport} />,
    <Route exact path="/evolution-customer-report" component={EvolutionCustomersReport} />,
    <Route exact path="/fuel-by-suply-report" component={FuelBySupplyReport} />,
    <Route exact path='/first-fillin-report' component={FirstFillinsReport} />,
    <Route exact path='/payment-by-pix-report' component={PaymentByPixReport} />,
    <Route exact path="/new-customer-report" component={NewCustomersReport} />,
    <Route exact path="/new-customer-summary-report" component={NewCustomersSummaryReport} />,
    <Route exact path="/recurring-customer-report" component={RecurringCustomersReport} />,
    <Route exact path="/station-rating-report" component={StationRatingReport} />,
    <Route exact path="/station-comments-report" component={StationCommentsReport} />,
    <Route exact path="/attendant-rating-report" component={AttendantRatingReport} />,
    <Route exact path="/coupons-report" component={CouponsReport} />,
    <Route exact path="/config" component={Config} />,
    <Route exact path="/socket-error-report" component={SocketErrorReport} />
];