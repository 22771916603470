import React, { PureComponent } from 'react';

export default class RotatedAxisTick extends PureComponent {
    render() {
        const { x, y, payload, fontSize } = this.props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text style={{ fontSize: fontSize ? fontSize : 12 }} x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">{payload.value}</text>
            </g>
        );
    }
};