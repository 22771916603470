import React from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  DeleteButton,
  List,
  TextField,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  Show
} from "react-admin";
import { SearchFilter } from "../components/SearchFilter";

export const AvaliationList = ({ permissions, ...props }) => (
  <List {...props} title="Clientes" filters={<SearchFilter />}>
    <Datagrid rowClick="show">
      <ReferenceField source={"stationId"} label="Posto" reference="stations">
        <TextField source={"name"} />
      </ReferenceField>
      <ReferenceField source={"fillinId"} label="Cliente" reference="fillins">
        <ReferenceField
          source={"customerId"}
          label="Cliente"
          reference="customers"
        >
          <TextField source={"name"} />
        </ReferenceField>
      </ReferenceField>
      <TextField source={"stationRating"} label="Nota do Posto" />
      <TextField source={"operatorName"} label="Frentista" />
      <TextField source={"attendantRating"} label="Nota do Frentista" />
      <TextField source={"stationText"} label="Comentário" />
      <ReferenceField source={"fillinId"} label="Data" reference="fillins">
        <DateField source="createdAt" locales="pt-BR" showTime />
      </ReferenceField>
      {permissions === "admin" && <EditButton />}
      {permissions === "admin" && <DeleteButton />}
    </Datagrid>
  </List>
);

export const AvaliationShow = ({ ...props }) => (
  <Show title={""} {...props}>
    <TabbedShowLayout>
      <Tab label="Resumo">
        <ReferenceField source={"stationId"} label="Posto" reference="stations">
          <TextField source={"name"} />
        </ReferenceField>
        <ReferenceField source={"fillinId"} label="Cliente" reference="fillins">
          <ReferenceField
            source={"customerId"}
            label="Cliente"
            reference="customers"
          >
            <TextField source={"name"} />
          </ReferenceField>
        </ReferenceField>
        <TextField source={"stationRating"} label="Nota do Posto" />
        <TextField source={"operatorName"} label="Frentista" />
        <TextField source={"attendantRating"} label="Nota do Frentista" />
        <TextField source={"stationText"} label="Comentário" />
        <ReferenceField source={"fillinId"} label="Data" reference="fillins">
          <DateField source="createdAt" locales="pt-BR" showTime />
        </ReferenceField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
