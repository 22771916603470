import React from "react";
import { useNotify } from 'react-admin';
import { GenericDatagridField, GenericDatagrid, ReportLayout, useReportContext } from '../../components';
import { formatNumber } from '../../utils/utils';
import moment from 'moment';

const AverageTicket = () => {
  const { data, error, setSort, sort, pagination, setPagination } = useReportContext();
  const notify = useNotify();

  if (error) { notify('Erro ao carregar Relatório de Ticket Médio', 'warning'); };

  return (
    <>
      <GenericDatagrid
        style={{ margin: '0 0 50px 0' }}
        textAlign='center'
        data={data && [{ total: data.totalTickets }]}
      >
        <GenericDatagridField label='Total de Tickets' render={({ total }) => formatNumber(total)} />
      </GenericDatagrid>
      <GenericDatagrid
        data={data && data.data}
        title='Relatório de Comentários'
        sort={sort}
        setSort={setSort}
        pagination={pagination}
        setPagination={setPagination}
        total={data && data.total}
      >
        <GenericDatagridField label='Posto' source='name' />
        <GenericDatagridField
          label='Ticket Médio'
          source='avg'
          render={({ avg }) => formatNumber(avg, { style: 'currency', currency: 'BRL' })}
        />
        <GenericDatagridField
          label='Total de Tickets'
          source='count'
          render={({ count }) => formatNumber(count)}
        />
      </GenericDatagrid>
    </>
  );
};

const AverageTicketReport = () =>
  <ReportLayout
    stationSelect
    permissions={['manager']}
    resource='average-ticket'
    initialSort={{ field: 'avg', order: 'DESC' }}
    initialPagination={{ page: 1, perPage: 10 }}
    initialFrom={moment().startOf('day').subtract(1, "month")}
  >
    <AverageTicket />
  </ReportLayout>;

export default AverageTicketReport;