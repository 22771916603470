import { strip } from '@fnando/cpf';
import React from 'react';
import { SelectInput, RadioButtonGroupInput, TextInput } from 'react-admin';
import { formatCellphone, formatCep, formatCnpj, formatCpf, formatPrice } from './utils';

export const StateInput = (props) => {
    return (
        <SelectInput
            {...props}
            choices={[
                { id: 'AC', name: "Acre" },
                { id: 'AL', name: "Alagoas" },
                { id: 'AP', name: "Amapá" },
                { id: 'AM', name: "Amazonas" },
                { id: 'BA', name: "Bahia" },
                { id: 'CE', name: "Ceará" },
                { id: 'DF', name: "Distrito Federal" },
                { id: 'ES', name: "Espírito Santo" },
                { id: 'GO', name: "Goiás" },
                { id: 'MA', name: "Maranhão" },
                { id: 'MT', name: "Mato Grosso" },
                { id: 'MS', name: "Mato Grosso do Sul" },
                { id: 'MG', name: "Minas Gerais" },
                { id: 'PA', name: "Pará" },
                { id: 'PB', name: "Paraíba" },
                { id: 'PR', name: "Paraná" },
                { id: 'PE', name: "Pernambuco" },
                { id: 'PI', name: "Piauí" },
                { id: 'RJ', name: "Rio de Janeiro" },
                { id: 'RN', name: "Rio Grande do Norte" },
                { id: 'RS', name: "Rio Grande do Sul" },
                { id: 'RO', name: "Rondônia" },
                { id: 'RR', name: "Roraima" },
                { id: 'SC', name: "Santa Catarina" },
                { id: 'SP', name: "São Paulo" },
                { id: 'SE', name: "Sergipe" },
                { id: 'TO', name: "Tocantins" }
            ]} />
    );
};

export const GenderInput = (props) => {
    return (
        <RadioButtonGroupInput
            {...props}
            choices={[
                { id: 'M', name: 'Masculino' },
                { id: 'F', name: 'Feminino' },
                { id: 'X', name: 'Não binário' },
            ]}
        />
    );
};

export const CEPInput = (props) => {
    return (
        <TextInput
            {...props}
            format={v => formatCep(v)}
            parse={v => strip(v).substring(0, 8)}
        />
    );
};

export const CPFInput = (props) => {
    return (
        <TextInput
            {...props}
            format={v => formatCpf(v)}
            parse={v => strip(v)}
        />
    );
};

export const CNPJInput = (props) => {
    return (
        <TextInput
            {...props}
            format={v => formatCnpj(v)}
            parse={v => strip(v).substring(0, 14)}
        />
    );
};

export const PhoneInput = (props) => {
    return (
        <TextInput
            {...props}
            format={v => formatCellphone(v)}
            parse={v => strip(v).substring(0, 11)}
        />
    );
};

export const PriceInput = (props) => {
    return (
        <TextInput
            {...props}
            format={v => formatPrice(v)}
            parse={v => (+strip(v))/100}
        />
    );
};