import React, { useEffect, useState } from 'react';
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import { useDataProvider, useNotify } from 'react-admin';
interface GenericSelectProps {
    variant: "filled" | "outlined" | "standard" | undefined;
    label: string | undefined;
    style: object;
    value: string | number | undefined;
    defaultValue: string | number | undefined;
    setValue: Function;
    allowEmpty: Boolean | undefined;
    options: object[];
    optionValue: string;
    optionName: string;
    reference: string | undefined;
    sort: { field: string, order: 'DESC' | 'ASC' };
    filter: object;
    afterQueryFilter: object | undefined;
    errorMessage: string | undefined;
    pagination: { page: number, perPage: number }
};
const GenericSelect = ({
    variant,
    label,
    style,
    value,
    defaultValue,
    setValue,
    allowEmpty,
    options,
    optionValue,
    optionName,
    reference,
    sort,
    filter,
    afterQueryFilter,
    errorMessage,
    pagination
}: GenericSelectProps) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [data, setData] = useState<any>();
    const [allData, setAllData] = useState<any>();

    useEffect(() => {
        let filteredData = allData || [];
        if (afterQueryFilter) {
            Object.keys(afterQueryFilter).forEach(key => {
                filteredData = filteredData.filter(record => record[key] === afterQueryFilter[key]);
            });
        }
        setData(filteredData);
    }, [allData, afterQueryFilter]);

    useEffect(() => {
        if (reference) {
            dataProvider.getList(reference, { sort, filter, pagination })
                .then(({ data }) => { setAllData(data); })
                .catch(() => errorMessage && notify(errorMessage, 'warning'));
        } else { setAllData(options); };
    }, [filter]);

    return (
        <FormControl style={style}>
            {label && <InputLabel variant='standard' id="drop-down-label">{label}</InputLabel>}
            <Select
                variant={variant}
                defaultValue={defaultValue}
                value={value}
                onChange={event => setValue(event.target.value)}
                labelId="drop-down-label"
            >
                {allowEmpty && <MenuItem value={undefined}><em>Todos</em></MenuItem>}
                {data && data.map(option => <MenuItem value={option[optionValue]} key={option.id}>{option[optionName]}</MenuItem>)}
            </Select>
        </FormControl>
    );
}

GenericSelect.defaultProps = {
    filter: {},
    sort: { field: 'name', order: 'ASC' },
    pagination: { page: 1, perPage: 1000 },
    optionValue: 'id',
    optionName: 'name',
    variant: 'standard',
    style: { width: '200px' },
    options: [],
}

export default GenericSelect;