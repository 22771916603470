import React from 'react';
import {
  DateField,
  TextField,
  NumberField,
  Show,
  ReferenceField,
  SimpleShowLayout,
  FunctionField,
  Datagrid,
} from 'react-admin';

const FillinTitle = ({ record }) => {
  return <span>{`Abastecimento`}</span>;
};

export const FillinDatagrid = ({ permissions, ...props }) => (
  <Datagrid rowClick='show' {...props}>
    <NumberField source="amount" label="Quantidade em litros" locales="pt-BR" />
    <NumberField source="odometer" label="Odômetro" locales="pt-BR" />
    <NumberField source="value" label="Valor pago" locales="pt-BR" options={{ style: 'currency', currency: 'BRL' }} />
    <ReferenceField
      label="Combustível"
      reference="fuels"
      source="fuelId"
    >
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField
      label="Posto"
      reference="stations"
      source="stationId"
    >
      <TextField source="name" />
    </ReferenceField>
    <DateField source="createdAt" label="Data inclusão" showTime locales="pt-BR" />
    <FunctionField label="Desconto" render={record => `${(record.oldPricePerLiter && record.pricePerLiter ? (record.oldPricePerLiter - record.pricePerLiter) * (record.amount || 0) : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`} />
  </Datagrid>
);

export const FillinShow = ({ permissions, ...props }) => (
  <Show title={<FillinTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <NumberField source="amount" label="Quantidade em litros" locales="pt-BR" />
      <NumberField source="odometer" label="Odômetro" locales="pt-BR" />
      <NumberField source="value" label="Valor pago" locales="pt-BR" options={{ style: 'currency', currency: 'BRL' }} />
      <ReferenceField
        label="Combustível"
        reference="fuels"
        source="fuelId"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Veículo"
        reference="vehicles"
        source="vehicleId"
      >
        <FunctionField render={record => `${record.nickname}/${record.licensePlate}`} />
      </ReferenceField>
      <ReferenceField
        label="Posto"
        reference="stations"
        source="stationId"
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="hoseNumber" label="Bico" emptyText="Não definido" />
      <ReferenceField
        label="Cliente"
        reference="customers"
        source="customerId"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" label="Data inclusão" showTime locales="pt-BR" />
      <FunctionField label="Valor do desconto concedido" render={record => `${(record.oldPricePerLiter && record.pricePerLiter ? (record.oldPricePerLiter - record.pricePerLiter) * (record.amount || 0) : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`} />;
      <NumberField source="oldPricePerLiter" label="Preço por litro original" locales="pt-BR" options={{ style: 'currency', currency: 'BRL', minimumFractionDigits: 3 }} />
      <NumberField source="pricePerLiter" label="Preço por litro real" locales="pt-BR" options={{ style: 'currency', currency: 'BRL', minimumFractionDigits: 3 }} />
    </SimpleShowLayout>
  </Show>
);