import React, { Fragment } from 'react';
import {
  ArrayInput,
  TabbedForm,
  FormTab,
  email,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FormDataConsumer,
  DeleteButton,
  List,
  TextField,
  TextInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  Filter,
  NumberInput,
  NumberField,
  Labeled,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  ReferenceManyField,
  Pagination,
  SelectArrayInput,
  ReferenceArrayInput,
  ImageInput,
  ImageField,
  BooleanInput,
  ArrayField,
  ChipField
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { PhoneField, CNPJField } from "../components/Fields";
import { CEPInput, CNPJInput, PhoneInput, StateInput, PriceInput } from '../utils/Inputs';
import { FillinDatagrid } from "./Fillin";
import { RatingDatagrid } from "./Rating";
import { Permission, TimePicker } from '../components';

const useStyles = makeStyles(theme => ({
  title: {
    margin: '20px 10px 0px 0px',
    fontSize: '28'
  },
  input: {
    width: '260px'
  },
  bigDiscountInput: {
    color: '#FF0000',
    width: '260px'
  },
  headerCell: {
    backgroundColor: '#E5E5E5',
  },
  listQrcodes: {
    padding: 20,
  },
  fuels: {
    display: 'flex',
    flexDirection: 'column'
  },
  image: {
    '& img': {
      maxWidth: '4rem',
    },
  },
  inputWrapperStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 20px 0',
  },
  openingHourInput: {
    margin: '0 20px 0 0',
  },
  day: {
    fontSize: 16,
    margin: '20px 0'
  }
}));

const weekDays = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'].map((d, i) => ({ id: i, name: d }));

const required = (message = 'Required') =>
  value => value ? undefined : message;

const StationFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <TextInput label="Pesquisar" source="search" alwaysOn />
    {permissions === 'admin' &&
      <ReferenceInput label="Rede" source="chainId" reference="chains" perPage={false} alwaysOn>
        <SelectInput resettable={true} optionText="name" />
      </ReferenceInput>}
  </Filter>
);

const NumericIdField = ({ ids }) => {
  return ids.map((_, ind) => (
    <ChipField source={`numericIds[${ind}]`} />
  ));
};

export const StationList = ({ permissions, ...props }) => {
  const classes = useStyles();

  return (
    <List {...props} title="Postos"
      filter={localStorage.getItem('chainId') ? { chainId: localStorage.getItem('chainId') } : null}
      filters={<StationFilter permissions={permissions} />} >
      <Datagrid rowClick="show">
        <ImageField className={classes.image} source="imagePath" label="Imagem quadrada" />
        <TextField source="name" label="Rede" />
        <ArrayField source="numericIds" label="IDs Numéricos">
          <NumericIdField />
        </ArrayField>
        <CNPJField source="cnpj" label="CNPJ" />
        <PhoneField source="phone" label="Telefone" sortable={false} />
        <DateField source="createdAt" label="Adicionado em" locales="pt-BR" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List >
  );
};

export const StationEdit = ({ permissions, ...props }) => {
  const classes = useStyles();
  return (
    <Edit title='Editar posto' {...props}>
      <TabbedForm>
        <FormTab label="Identificação">
          <TextInput disabled label="ID" source="id" />
          <Permission permission={'admin'}>
            <ArrayInput source="numericIds" label="IDs Numéricos">
              <SimpleFormIterator
                disableReordering
                getItemLabel={() => null}
                TransitionProps={{ enter: false, exit: false, addEndListener: () => null }}
              >
                <NumberInput label={null} />
              </SimpleFormIterator>
            </ArrayInput>
          </Permission>
          <TextInput source="name" label="Nome" />
          <TextInput label="Email" source="email" type="email" validate={email()} />
          <CNPJInput source="cnpj" label="CNPJ" />
          <PhoneInput source="phone" label="Telefone" />
          {
            permissions === 'admin' &&
            <ReferenceInput label="Rede" source='chainId' reference="chains" perPage={false} validate={required()}>
              <SelectInput optionText="name" />
            </ReferenceInput>
          }
          <TextInput
            label="Token RTI"
            source="rtiToken"
          />
          {
            permissions === 'admin' &&
            <TextInput
              label="Token Fixpay"
              source="fixpayToken"
            />
          }
          <ImageInput source="imagefile" label="Imagem quadrada" accept="image/*">
            <ImageField source="image" label="Imagem" />
          </ImageInput>
          <ImageInput source="coverimagefile" label="Imagem retangular" accept="image/*">
            <ImageField source="image" label="Imagem" />
          </ImageInput>
        </FormTab>
        <FormTab label="Endereço">
          <TextInput source="street" label="Rua" />
          <TextInput source="streetNumber" label="Número" />
          <TextInput source="neighbourhood" label="Bairro" />
          <TextInput source="city" label="Cidade" />
          <StateInput source="state" label="Estado" />
          <CEPInput source="cep" label="CEP" />
          <NumberInput source="latitude" label="Latitude" />
          <NumberInput source="longitude" label="Longitude" />
        </FormTab>
        <FormTab label='Descontos'>
          <FormDataConsumer>
            {({ formData }) => {
              const availableStationFuels = (formData.stationFuels && formData.fuelIds) && formData.stationFuels.filter(sf => formData.fuelIds.includes(sf.fuelId))
              formData.stationFuels = availableStationFuels
              return (
                <ArrayInput source='stationFuels' label=''>
                  <SimpleFormIterator
                    disableRemove
                    disableAdd>
                    <FormDataConsumer>
                      {({ scopedFormData, getSource }) =>
                      (
                        <div className={classes.fuels}>
                          <TextField className={classes.input} source={getSource && getSource("fuel.name")} label="Combustível" />
                          <PriceInput
                            InputProps={{ className: scopedFormData && scopedFormData.discount >= 0.5 ? classes.bigDiscountInput : classes.input }}
                            source={getSource && getSource("discount")} label="Desconto"
                          />
                        </div>
                      )
                      }
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              )
            }}
          </FormDataConsumer>

        </FormTab>
        <FormTab label='GPS'>
          <Labeled fullWidth label="Informe as coordenadas em 4 pontos que formam um retângulo" className={classes.title}></Labeled>
          <FormDataConsumer>
            {({ formData: { coordinates } }) =>
              <ArrayInput source='coordinates' label=''>
                <SimpleFormIterator disableAdd={coordinates && coordinates.length === 4}>
                  <NumberInput source="latitude" label="Latitude" />
                  <NumberInput source="longitude" label="Longitude" />
                </SimpleFormIterator>
              </ArrayInput>
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Horários">
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.openingHours && formData.openingHours.length !== 7) {
                const registeredDays = formData.openingHours.map(record => record.dayOfTheWeek);
                const notRegisteredDays = [...weekDays].filter(wd => !registeredDays.includes(wd.id));
                const openingHours = [...formData.openingHours];
                notRegisteredDays.forEach(nrd => {
                  openingHours.push({ dayOfTheWeek: nrd.id, closed: true });
                });
                formData.openingHours = openingHours.sort((a, b) => {
                  if (a.dayOfTheWeek > b.dayOfTheWeek) {
                    return 1;
                  } else if (a.dayOfTheWeek < b.dayOfTheWeek) {
                    return -1;
                  }
                  return 0;
                })
              }
              if (formData.openingHours) {
                return (
                  <ArrayInput source="openingHours" label="">
                    <SimpleFormIterator disableAdd disableRemove>
                      <FormDataConsumer>
                        {({ scopedFormData }) => {
                          const day = weekDays.find(wd => wd.id === scopedFormData.dayOfTheWeek).name;
                          return (
                            <p className={classes.day}>{day}</p>
                          );
                        }}
                      </FormDataConsumer>
                      <FormDataConsumer>
                        {({ scopedFormData, getSource }) => {
                          return (
                            <div className={classes.inputWrapperStyle}>
                              <BooleanInput
                                source={getSource && getSource("closed")}
                                label='Fechado'
                                className={classes.openingHourInput}
                              />
                              {scopedFormData && !scopedFormData.closed &&
                                <BooleanInput
                                  source={getSource && getSource("open24hrs")}
                                  label='Aberto 24 horas'
                                  className={classes.openingHourInput}
                                />
                              }
                              {scopedFormData && !scopedFormData.closed && !scopedFormData.open24hrs &&
                                <Fragment>
                                  <TimePicker
                                    record={scopedFormData}
                                    variant='filled'
                                    label='Hora de abertura'
                                    source={getSource && getSource("startTime")}
                                    style={{ margin: '0 20px 0 0' }}
                                    fullDate
                                  />
                                  <TimePicker
                                    record={scopedFormData}
                                    variant='filled'
                                    label='Hora de fechamento'
                                    source={getSource && getSource("endTime")}
                                    fullDate
                                  />
                                </Fragment>
                              }
                            </div>
                          );
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                );
              }
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab label='Configurações'>
          <ReferenceArrayInput
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
            label="Combustíveis"
            source="fuelIds"
            reference="fuels"
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.serviceIds) {
                return (
                  <ReferenceArrayInput
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={1000}
                    label="Serviços"
                    source="serviceIds"
                    reference="services"
                  >
                    <SelectArrayInput optionText="name" />
                  </ReferenceArrayInput>
                )
              }
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.paymentMethodIds) {
                return (
                  <ReferenceArrayInput
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={1000}
                    label="Método de pagamento"
                    source="paymentMethodIds"
                    reference="payment-methods"
                    style={{ minWidth: 300 }}
                  >
                    <SelectArrayInput optionText="name" />
                  </ReferenceArrayInput>
                )
              }
            }}
          </FormDataConsumer>
          <TextInput label="Conta da Quality" source="qualityAccount" />
          <TextInput label="Chave da Quality" source="qualityKey" />
          <BooleanInput label="Ativo" source="isActive" />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
};

const createStationEmailValidate = [email(), required()];

export const StationCreate = ({ permissions, ...props }) => {
  const classes = useStyles();
  return (
    <Create title='Novo Posto' {...props}>
      <TabbedForm defaultValue={permissions !== 'admin' && { chainId: localStorage.getItem('chainId') }}>
        <FormTab label="Identificação">
          <TextInput source="name" label="Nome" validate={required()} />
          <TextInput source="email" label="Email" type="email" validate={createStationEmailValidate} />
          <CNPJInput source="cnpj" label="CNPJ" validate={required()} />
          <PhoneInput source="phone" label="Telefone" />
          <ArrayInput source="numericIds" label="IDs Numéricos">
            <SimpleFormIterator
              disableReordering
              getItemLabel={() => null}
              TransitionProps={{ enter: false, exit: false, addEndListener: () => null }}
            >
              <NumberInput label={null} />
            </SimpleFormIterator>
          </ArrayInput>
          {
            permissions === 'admin' &&
            <ReferenceInput label="Rede" source='chainId' reference="chains" perPage={false} validate={required()}>
              <SelectInput optionText="name" />
            </ReferenceInput>
          }
          <TextInput
            label="Token RTI"
            source="rtiToken"
          />
          {
            permissions === 'admin' &&
            <TextInput
              label="Token Fixpay"
              source="fixpayToken"
            />
          }
          <ImageInput source="imagefile" label="Imagem quadrada" accept="image/*">
            <ImageField source="image" label="Imagem" />
          </ImageInput>
          <ImageInput source="coverimagefile" label="Imagem retangular" accept="image/*">
            <ImageField source="image" label="Imagem" />
          </ImageInput>
        </FormTab>
        <FormTab label="Endereço">
          <TextInput source="street" label="Rua" validate={required()} />
          <TextInput source="streetNumber" label="Número" validate={required()} />
          <TextInput source="neighbourhood" label="Bairro" validate={required()} />
          <TextInput source="city" label="Cidade" validate={required()} />
          <StateInput source="state" label="Estado" validate={required()} />
          <CEPInput source="cep" label="CEP" validate={required()} />
          <NumberInput source="latitude" label="Latitude" validate={required()} />
          <NumberInput source="longitude" label="Longitude" validate={required()} />
        </FormTab>
        <FormTab label='GPS'>
          <Labeled fullWidth label="Informe as coordenadas em 4 pontos que formam um retângulo" className={classes.title}></Labeled>
          <FormDataConsumer>
            {({ formData: { coordinates } }) =>
              <ArrayInput source='coordinates' label=''>
                <SimpleFormIterator disableAdd={coordinates && coordinates.length === 4}>
                  <NumberInput source="latitude" label="Latitude" validate={required()} />
                  <NumberInput source="longitude" label="Longitude" validate={required()} />
                </SimpleFormIterator>
              </ArrayInput>
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label='Configurações'>
          <ReferenceArrayInput
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
            label="Combustíveis"
            source="fuelIds"
            reference="fuels"
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
            label="Serviços"
            source="serviceIds"
            reference="services"
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
            label="Métodos de pagamentos"
            source="paymentMethodIds"
            reference="payment-methods"
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <BooleanInput label="Ativo" source="isActive" />
        </FormTab>
        <FormTab label="Horários">
          <FormDataConsumer>
            {({ formData }) => {
              if (!formData.openingHours) {
                formData.openingHours = weekDays.map(wd => ({
                  closed: true,
                  dayOfTheWeek: wd.id,
                }));
              }
              return (
                <ArrayInput source="openingHours" label="">
                  <SimpleFormIterator disableAdd disableRemove>
                    <FormDataConsumer>
                      {({ scopedFormData }) => {
                        const day = weekDays.find(wd => wd.id === scopedFormData.dayOfTheWeek).name;
                        return (
                          <p className={classes.day}>{day}</p>
                        );
                      }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                      {({ scopedFormData, getSource }) => {
                        return (
                          <div className={classes.inputWrapperStyle}>
                            <BooleanInput
                              source={getSource && getSource("closed")}
                              label='Fechado'
                              className={classes.openingHourInput}
                            />
                            {scopedFormData && !scopedFormData.closed &&
                              <BooleanInput
                                source={getSource && getSource("open24hrs")}
                                label='Aberto 24 horas'
                                className={classes.openingHourInput}
                              />
                            }
                            {scopedFormData && !scopedFormData.closed && !scopedFormData.open24hrs &&
                              <Fragment>
                                <TimePicker
                                  record={scopedFormData}
                                  variant='filled'
                                  label='Hora de abertura'
                                  source={getSource && getSource("startTime")}
                                  style={{ margin: '0 20px 0 0' }}
                                  fullDate
                                />
                                <TimePicker
                                  record={scopedFormData}
                                  variant='filled'
                                  label='Hora de fechamento'
                                  source={getSource && getSource("endTime")}
                                  fullDate
                                />
                              </Fragment>
                            }
                          </div>
                        );
                      }}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              );
            }}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Create>
  )
};

const StationName = ({ record }) => {
  return <span>{record.name}</span>;
};

const ListQrcodes = (props) => {
  const classes = useStyles();

  const QrcodePanel = ({ record }) => {
    let data = {};
    if (record.hoses) {
      // convert array to object passing 'id' as key
      record.hoses.forEach(element => {
        data[element.id] = element;
      });
    }
    return (
      <Datagrid classes={{ headerCell: classes.headerCell }} data={data} ids={Object.keys(data)}>
        <TextField source="hoseNumber" label="Bico" textAlign="center" />
        <TextField source="fuel.name" label="Combustível" textAlign="center" />
      </Datagrid>
    );
  };

  return (
    <List
      title=" "
      resource="station-qrcodes"
      basePath="station-qrcodes"
      filter={{ numericIds: props.record.numericIds }}
      pagination={false}
      bulkActionButtons={false}
      exporter={false}
      actions={false}
      syncWithLocation={false}
    >
      <Datagrid expand={<QrcodePanel />}>
        <TextField source="fuelPointNumber" label="Ponto de abastecimento" sortable={false} />
        <TextField source="qrcode" label="Qrcode" sortable={false} />
      </Datagrid>
    </List>
  );
};

export const StationShow = ({ permissions, ...props }) => (
  <Show title={<StationName />} {...props}>
    <TabbedShowLayout>
      <Tab label="Identificação">
        <TextField disabled label="ID" source="id" />
        <NumberField source="numericId" label="ID Numérico" />
        <TextField source="name" label="Nome" />
        <TextField label="Email" source="email" type="email" />
        <CNPJField source="cnpj" label="CNPJ" />
        <PhoneField source="phone" label="Telefone" />
        {
          permissions === 'admin' &&
          <ReferenceField label="Rede" reference="chains" source="chainId" >
            <TextField source="name" />
          </ReferenceField>
        }
      </Tab>
      <Tab label="Abastecimentos" path="fillins">
        <ReferenceManyField
          reference="fillins"
          target="stationId"
          addLabel={false}
          sort={{ field: "createdAt", order: "DESC" }}
          pagination={<Pagination />}
        >
          <FillinDatagrid permissions={permissions} />
        </ReferenceManyField>
      </Tab>
      <Tab label="Avaliações" path="ratings">
        <ReferenceManyField
          reference="ratings"
          addLabel={false}
          target="stationId"
          sort={{ field: 'createdAt', order: 'DESC' }}
          pagination={<Pagination />}
        >
          <RatingDatagrid />
        </ReferenceManyField>
      </Tab>
      <Tab label="QrCodes" path="qrcodes">
        <ListQrcodes />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
