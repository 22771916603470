import React from 'react';
import { Button } from 'react-admin';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';
import { withStyles } from '@material-ui/styles';

const styles = () => ({
    button: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        margin: '0 5px 0 0'
    }
})

class PrintButton extends React.Component {
    render() {
        const { printRef, classes } = this.props
        return (
            <ReactToPrint
                trigger={() => <Button label={
                    <div className={classes.button}>
                        <PrintIcon className={classes.icon} />
                    Imprimir
                    </div>
                } />}
                content={() => printRef.current}
            />
        );
    }
}

export default withStyles(styles)(PrintButton)