import React, { useState, useEffect } from "react";
import { ReportLayout, useReportContext, GenericDatagridField, GenericDatagrid, RotatedAxisTick } from '../../components';
import { useNotify } from 'react-admin';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import momement from 'moment';

const colors = ['#608DE0', '#E0348A', '#E0AD3F', '#34E034', '#17E0DC', '#9934E0', '#E0793F', '#E05C3F', '#4612E0', '#16E097', '#E0D134', '#E016CE'];

const SocketError = () => {
  const { data, error } = useReportContext();
  const [errorData, setErrorData] = useState([]);
  const notify = useNotify();

  if (error) { notify('Erro ao carregar Relatório de Erros', 'warning'); };

  const sortData = (a, b) => {
    return b.count - a.count;
  }

  useEffect(() => {
    if (data && data.data) {
      const formattedData = data.data.filter(error => !!error.message).sort(sortData);
      formattedData.unshift({
        message: 'Todos',
        count: data.total
      });
      setErrorData(formattedData);
    }
  }, [data]);

  return (
    <>
      <GenericDatagrid
        style={{ margin: '0 0 40px 0' }}
        textAlign='center'
        data={data && errorData}
      >
        <GenericDatagridField
          label='Erro'
          source='message'
        />
        <GenericDatagridField
          label='Total de Ocorrências'
          source='count'
        />
      </GenericDatagrid>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <LineChart width={790} height={600} data={data ? data.chartData : []}>
          <XAxis dataKey="date" tick={<RotatedAxisTick fontSize={16} />} height={100} />
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis domain={['auto', 'auto']} type='number' />
          <Tooltip />
          {data && errorData.map((error, i) =>
            data.chartData.some(point => point[error.message]) &&
            <Line
              type='monotone'
              dataKey={error.message}
              stroke={i <= 11 ? colors[i] : colors[((i + 1) % 12) - 1]}
              name={error.message}
              activeDot={{ r: 5 }}
              key={i}
            />
          )}
        </LineChart>
      </div>
    </>
  );
};

const SocketErrorReport = () => {
  return (
    <ReportLayout
      chainSelect
      stationSelect
      permissions={['admin']}
      resource='socket-error'
      initialFrom={momement().startOf('day').subtract(1, 'month')}
    >
      <SocketError />
    </ReportLayout>
  );
};

export default SocketErrorReport;