import React from 'react'
import {
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField,
    Create,
    SimpleForm,
    FormDataConsumer,
    TextInput,
    BooleanInput,
    required,
    minLength,
    maxLength,
    DateTimeInput,
    ReferenceInput,
    SelectInput,
    Edit,
    DeleteButton,
    ReferenceField
} from 'react-admin'
import { SearchFilter } from "../components/SearchFilter";
const moment = require('moment');

const dateValidate = [required(), (value, allValues) => {
    const tenMinutesFromNow = moment().add(10, 'minutes');
    if (value <= tenMinutesFromNow) {
        return `Data de envio deve ser maior que ${tenMinutesFromNow.format('DD/MM/YYYY HH:mm')}`
    }
    return [];
}];

const SmsForm = ({ permissions }) =>
    <div style={{ width: '350px' }}>
        <TextInput
            inputProps={{ autoComplete: 'off' }}
            source="body"
            label="Mensagem"
            multiline
            validate={[required(), minLength(3), maxLength(1500)]}
            fullWidth
        />
        <BooleanInput source="sendNow" label="Enviar agora" />
        <FormDataConsumer>
            {({ formData }) => formData && !formData.sendNow &&
                <DateTimeInput
                    source="sendAt"
                    locales="pt-BR"
                    validate={dateValidate}
                    label="Data de Envio"
                    fullWidth />
            }
        </FormDataConsumer>
        {permissions === 'admin' &&
            <ReferenceInput
                label="Rede de postos"
                source="chainId"
                reference="chains"
                sort={{ field: 'name', order: 'ASC' }}
                perPage={false}
                validate={[required()]}
                fullWidth
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        }
    </div>

export const SmsList = props => {
    return (
        <List
            {...props}
            perPage={25}
            exporter={false}
            bulkActionButtons={false}
            filters={<SearchFilter />}
            title="SMS"
        >
            <Datagrid rowClick="edit">
                <DateField source="sendAt" label="Data de Envio" locales="pt-BR" showTime />
                <TextField source="body" label="Mensagem" />
                <ReferenceField source="employeeId" reference="employees" label="Funcionário">
                    <TextField source="login" />
                </ReferenceField>
                <TextField source="cardinality" label="Cardinalidade" />
                <BooleanField source="sent" label="Enviado" />
                <DeleteButton />
            </Datagrid>
        </List>
    )
}

export const SmsCreate = props =>
    <Create {...props} title="Criar SMS">
        <SimpleForm>
            <SmsForm permissions={props.permissions} sendNow />
        </SimpleForm>
    </Create>

export const SmsEdit = props =>
    <Edit {...props} title="Editar SMS">
        <SimpleForm>
            <SmsForm permissions={props.permissions} />
        </SimpleForm>
    </Edit>