import React, { Component } from "react";
import { Admin, Resource } from "react-admin";
import "./App.css";
import { ChainCreate, ChainEdit, ChainList } from "./containers/Chain";
import { AvaliationList, AvaliationShow } from "./containers/AvaliationStation";
import { ProductCreate, ProductEdit, ProductList } from "./containers/Product";
import { ProductCategoryCreate, ProductCategoryEdit, ProductCategoryList } from "./containers/ProductCategory";
import {
  CustomerCreate,
  CustomerEdit,
  CustomerList,
  CustomerShow,
} from "./containers/Customer";
import {
  EmployeeCreate,
  EmployeeEdit,
  EmployeeList,
} from "./containers/Employee";
import { FillinShow } from "./containers/Fillin";
import { FuelCreate, FuelEdit } from "./containers/Fuel";
import {
  ParentFuelCreate,
  ParentFuelEdit,
  ParentFuelList,
} from "./containers/ParentFuel";
import { StationCreate, StationEdit, StationList, StationShow } from "./containers/Station";
import { PartnerCreate, PartnerEdit, PartnerList } from "./containers/Partner";
import {
  SupportSubjectCreate,
  SupportSubjectEdit,
  SupportSubjectList,
} from "./containers/SupportSubject";
import { SupportTicketList } from "./containers/SupportTicket";
import { VehicleList, VehicleShow } from "./containers/Vehicle";
import ApiProvider from "./providers/apiProvider";
import authProvider from "./providers/authProvider";
import i18nProvider from './providers/i18nProvider';
import { SmsCreate, SmsList, SmsEdit } from "./containers/Sms";
import { AttendantList, AttendantCreate, AttendantEdit } from "./containers/Attendant";
import ValidateCoupon from './containers/validateCoupon/validateCoupon';
import Rule from './containers/Rule';
import Menu from './containers/Menu';
import { RatingList } from './containers/Rating'
import CustomRoutes from './customRoutes';
import { theme } from './theme';
import Dashboard from './Dashboard';
import { NotificationCreate, NotificationEdit, NotificationList } from "./containers/Notification";
import { ServiceCreate, ServiceEdit, ServiceList } from "./containers/Service";
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { PaymentsList } from "./containers/Payments";
import { FirstFillinReport } from "./containers/reports";
import { PotencialFraudList } from "./containers/PotentialFrauds";
import { SaleProductsCreate, SaleProductsEdit, SaleProductsList } from "./containers/SaleProduct";

class App extends Component {
  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Admin
          dashboard={Dashboard}
          menu={Menu}
          i18nProvider={i18nProvider}
          title="Gash Backoffice"
          authProvider={authProvider}
          dataProvider={ApiProvider}
          customRoutes={CustomRoutes}
          theme={theme}
        >
          {(permissions) => [
            (permissions === 'admin' || permissions === 'manager') &&
            <Resource
              name="customers"
              show={CustomerShow}
              list={CustomerList}
              edit={permissions === "admin" && CustomerEdit}
              create={permissions === "admin" && CustomerCreate}
            />,
            (permissions === 'admin' || permissions === 'manager') && (
              <Resource
                name="employees"
                list={EmployeeList}
                edit={EmployeeEdit}
                create={EmployeeCreate}
              />
            ),
            (permissions === 'admin' || permissions === 'manager') && (
              <Resource
                name="vehicles"
                list={VehicleList}
              />
            ),
            permissions === "admin" && (
              <Resource
                name="chains"
                list={ChainList}
                edit={ChainEdit}
                create={ChainCreate}
              />
            ),
            (permissions === 'admin' || permissions === 'manager') &&
            <Resource
              name="stations"
              show={StationShow}
              list={StationList}
              edit={StationEdit}
              create={permissions === "admin" ? StationCreate : null}
            />,
            (permissions === "admin" || permissions === "manager") && (
              <Resource
                name="product-categories"
                list={ProductCategoryList}
                edit={permissions === "admin" && ProductCategoryEdit}
                create={permissions === "admin" && ProductCategoryCreate}
              />
            ),
            permissions === "manager" && (
              <Resource
                name="products"
                list={ProductList}
                edit={ProductEdit}
                create={ProductCreate}
              />
            ),
            permissions === "admin" && (
              <Resource
                name="notifications"
                list={NotificationList}
                edit={NotificationEdit}
                create={NotificationCreate}
              />
            ),
            (permissions === "admin" || permissions === "manager") && (
              <Resource
                name="services"
                list={ServiceList}
                edit={permissions === "admin" && ServiceEdit}
                create={permissions === "admin" && ServiceCreate}
              />
            ),
            (permissions === "admin" || permissions === "manager") && (
              <Resource name="reports/payment-by-pix" />
            ),
            (permissions === "admin" || permissions === "manager") && (
              <Resource name="reports/recurring-customers" />
            ),
            (permissions === "admin" || permissions === "manager") && (
              <Resource name="reports/fuel-by-attendant" />
            ),
            (permissions === "admin" || permissions === "manager") && (
              <Resource
                name="station-attendants"
                list={AttendantList}
                edit={AttendantEdit}
                create={AttendantCreate}
              />
            ),
            (permissions === "admin" || permissions === "manager" || permissions === "partner") && (
              <Resource
                name="partners"
                list={PartnerList}
                edit={PartnerEdit}
                create={PartnerCreate}
              />
            ),
            (permissions === 'admin' || permissions === 'manager') &&
            <Resource
              name="parent-fuels"
              list={ParentFuelList}
              edit={permissions === "admin" && ParentFuelEdit}
              create={permissions === "admin" && ParentFuelCreate}
            />,
            (permissions === 'admin' || permissions === 'manager') &&
            < Resource name="transactions" />,
            (permissions === 'admin' || permissions === 'manager') &&
            < Resource name="places" />,
            (permissions === 'admin' || permissions === 'manager') &&
            <Resource
              name="stations-rating"
              show={AvaliationShow}
              list={AvaliationList}
            />,
            (permissions === 'admin' || permissions === 'manager') &&
            <Resource name="vehicles" show={VehicleShow} />,
            (permissions === 'admin' || permissions === 'manager') &&
            <Resource name="fillins" show={FillinShow} />,
            <Resource name="reports/fillins" show={FillinShow} />,
            (permissions === 'admin' || permissions === 'manager') &&
            <Resource
              name="reports/first-fillin"
              list={FirstFillinReport}
            />,
            (permissions === 'admin' || permissions === 'manager' || permissions === 'cashier') &&
            <Resource
              name="fuels"
              create={permissions === "admin" && FuelCreate}
              edit={permissions === "admin" && FuelEdit}
            />,
            permissions === "admin" && (
              <Resource
                name="support-tickets"
                list={SupportTicketList}
              />
            ),
            permissions === "admin" && (
              <Resource
                name="support-subjects"
                list={SupportSubjectList}
                edit={SupportSubjectEdit}
                create={SupportSubjectCreate}
              />
            ),
            (permissions === "admin" || permissions === "manager") &&
            <Resource
              name="sms"
              list={SmsList}
              edit={SmsEdit}
              create={SmsCreate}
            />,
            (permissions === "cashier") &&
            <Resource
              name="payments"
              list={PaymentsList}
            />,
            (permissions === "cashier") &&
            <Resource
              name="validate-coupon"
              list={ValidateCoupon}
            />,
            (permissions === "admin") &&
            <Resource
              name="rules"
              list={Rule}
            />,
            (permissions === 'admin' || permissions === 'manager') &&
            <Resource name="ratings" list={RatingList} />,
            (permissions === 'admin' || permissions === 'manager') &&
            <Resource name="sale-products"
              list={SaleProductsList}
              edit={SaleProductsEdit}
              create={SaleProductsCreate}
            />,
            permissions === "admin" && <Resource name="config" />,
            (permissions === "admin" || permissions === "manager") &&
            <Resource name="station-qrcodes" />,
            <Resource name="payment-methods" />,
            (permissions === 'admin') &&
            <Resource
              name="potential-frauds"
              list={PotencialFraudList}
            />,
          ]}
        </Admin>
      </MuiPickersUtilsProvider>
    );
  }
}

export default App;
