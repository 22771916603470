import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = () => ({
    wrapper: {
        '@media print': {
            padding: '.6cm',
            overflow: 'hidden'
        }
    },
    title: {
        display: 'none',
        textAlign: 'center',
        fontSize: '.7cm',
        '@media print': {
            display: 'block'
        }
    },
})

class ReportPrint extends React.Component {
    render() {
        const { children, title, landscape, classes } = this.props;
        return (
            <div className={classes.wrapper}>
                <style type="text/css" media="print">{landscape && "@page { size: landscape; }"}</style>
                <p className={classes.title}>{title}</p>
                {children}
            </div>
        );
    }
}

export default withStyles(styles)(ReportPrint);