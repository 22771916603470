import React from 'react';
import {
  UrlField,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  DeleteButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  FileInput,
  FileField,
} from 'react-admin';
import { PhoneField } from "../components/Fields"
import { SearchFilter } from "../components/SearchFilter";
import { PhoneInput } from '../utils/Inputs';

const required = (message = 'Required') =>
  value => value ? undefined : message;

export const ChainList = (props) => (
  <List {...props} title="Rede de postos" filters={<SearchFilter />} >
    <Datagrid>
      <TextField source="name" label="Rede" />
      <PhoneField source="phone" label="Telefone" sortable={false} />
      <TextField source="contactName" label="Nome do contato" />
      <UrlField source="contractPath" label="Link do contrato" sortable={false} />
      <DateField source="createdAt" label="Criado em" locales="pt-BR" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export const ChainEdit = (props) => (
  <Edit title='Editar rede' {...props}>
    <SimpleForm>
      <TextInput disabled source="id" label="ID" />
      <TextInput source="name" label="Nome da rede" validate={required()} />
      <TextInput source="contactName" label="Nome do Contato" />
      <PhoneInput source="phone" label="Telefone" />
      <FileField label="Contrato atual" source="contractPath" title="contrato.pdf" />
      <FileInput source="contractFile" label="Contrato" accept="application/pdf">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
);

export const ChainCreate = (props) => (
  <Create title='Nova Rede' {...props}>
    <SimpleForm>
      <TextInput source="name" label="Nome da rede" validate={required()} />
      <TextInput source="contactName" label="Nome do Contato" validate={required()} />
      <PhoneInput source="phone" label="Telefone" />
      <FileInput source="contractFile" label="Contrato" accept="application/pdf">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);