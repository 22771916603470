import * as React from "react";
import { Grid, makeStyles } from '@material-ui/core';
import { Title } from 'react-admin';
import { FillinsChart, RankStationsByRating, FillinsAverageTime, FillinDuration, NewCustomersChart } from './containers/Dashboard';
import { Permissions } from "./components";

const useStyles = makeStyles(() => ({
  root: {
    flexWrap: 'wrap',
  },
  grid: {
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  leftGrid: {
    marginBottom: 'auto',
    marginRight: 'auto',
  },
}));

export default function Dashboard() {
  const classes = useStyles();

  return (
    <>
      <Title title={localStorage.getItem('chainName') || "Gash"} />
      <div className={classes.root}>
        <Permissions permissions={['admin', 'manager']}>
          <Grid container className={classes.grid}>
            <Grid container spacing={2} xs={12} sm={12} md={12} lg={8} className={classes.leftGrid}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FillinsChart />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <NewCustomersChart />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <FillinsAverageTime />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <FillinDuration />
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4}>
              <RankStationsByRating />
            </Grid>
          </Grid>
        </Permissions>
      </div>
    </>
  )
}