import React from "react";
import { useNotify } from 'react-admin';
import { GenericDatagridField, GenericDatagrid, ReportLayout, useReportContext } from '../../components';
import { formatNumber } from '../../utils/utils';
import moment from 'moment';

const NewCustomers = () => {
  const { data, error, setSort, sort, pagination, setPagination } = useReportContext();
  const notify = useNotify();

  if (error) { notify('Erro ao carregar Relatório de Novos Clientes', 'warning'); };

  return (
    <>
      <GenericDatagrid
        style={{ margin: '0 0 50px 0' }}
        textAlign='center'
        data={data && [{ total: data.total }]}
      >
        <GenericDatagridField label='Total de Clientes' render={({ total }) => formatNumber(total)} />
      </GenericDatagrid>
      <GenericDatagrid
        data={data && data.data}
        title='Relatório de Novos Clientes'
        sort={sort}
        setSort={setSort}
        pagination={pagination}
        setPagination={setPagination}
        total={data && data.total}
      >
        <GenericDatagridField label='Nome' source='name' />
        <GenericDatagridField label='CPF' source='cpf' unsortable />
        <GenericDatagridField label='Data' source='createdAt' />
      </GenericDatagrid>
    </>
  );
};

const NewCustomersReport = () =>
  <ReportLayout
    permissions={['admin']}
    resource='new-customers'
    initialSort={{ field: 'createdAt', order: 'DESC' }}
    initialPagination={{ page: 1, perPage: 10 }}
    initialFrom={moment().startOf('day').subtract(1, 'month')}
  >
    <NewCustomers />
  </ReportLayout>;

export default NewCustomersReport;