import React, { useEffect, useState } from 'react';
import { DashboardWrapper, RotatedAxisTick } from '../../components';
import { Area, AreaChart, XAxis, YAxis, Tooltip } from 'recharts';
import { RadioGroup, Radio, FormControlLabel, FormControl } from '@material-ui/core';
import { useDataProvider } from 'react-admin';
import { formatNumber } from '../../utils/utils';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const colors = ['#82ca9d', '#03c8ff', '#ffe203'];

const FillinsChart = () => {
    const dataProvider = useDataProvider();
    const [chartType, setChartType] = useState('totalValue');
    const [chartData, setChartData] = useState([]);
    const [chartColor, setChartColor] = useState(colors[0]);

    useEffect(() => {
        dataProvider.getList('reports/fuel-by-supply', {
            filter: {
                chainId: localStorage.getItem('chainId') || undefined,
                from: moment().startOf('day').subtract(1, 'month'),
                to: moment().endOf('day')
            }
        }).then(({ chartData }) => {
            setChartData(chartData.map(({ discount, ...rest }) => ({ ...rest, discount: discount || 0 })));
        });
    }, []);

    return (
        <DashboardWrapper
            permissions={['manager', 'admin']}
            filters={
                <FormControl>
                    <RadioGroup row defaultValue='totalValue' onChange={({ target: { value } }) => {
                        setChartType(value);
                        switch (value) {
                            case 'totalAmount':
                                setChartColor(colors[2]);
                                break;
                            case 'totalFillins':
                                setChartColor(colors[1]);
                                break;
                            default:
                                setChartColor(colors[0]);
                                break;
                        }
                        // work around for line animation
                        setChartData([...chartData]);
                    }}>
                        <FormControlLabel value='totalValue' label='Dinheiro' control={<Radio />} />
                        <FormControlLabel value='totalFillins' label='Abastecimentos' control={<Radio />} />
                        <FormControlLabel value='totalAmount' label='Volume' control={<Radio />} />
                        <FormControlLabel value='discount' label='Descontos' control={<Radio />} />
                    </RadioGroup>
                </FormControl>
            }
            content={
                <AreaChart data={chartData}>
                    <YAxis domain={['auto', 'auto']} type='number' />
                    <XAxis dataKey="date" tick={<RotatedAxisTick />} height={60} />
                    <Tooltip
                        labelFormatter={(value) => `${value} ${moment(value, 'DD/MM/YYYY').format('ddd').toLocaleUpperCase()}`}
                        formatter={(value) => {
                            switch (chartType) {
                                case 'totalAmount':
                                    return formatNumber(value, { style: 'decimal', unit: 'liter' });
                                case 'totalFillins':
                                    return `${formatNumber(value)} Abastecimentos`;
                                default:
                                    return formatNumber(value, { style: 'currency', currency: 'BRL' });

                            }
                        }}
                    />
                    <defs>
                        {colors.map((color, i) => (
                            <linearGradient key={i} id={color} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={color} stopOpacity={0.75} />
                                <stop offset="95%" stopColor={color} stopOpacity={0} />
                            </linearGradient>
                        ))}
                    </defs>
                    <Area
                        stroke={chartColor}
                        fillOpacity={1}
                        fill={`url(#${chartColor})`}
                        dataKey={chartType}
                        name='Total'
                        type="monotone"
                    />

                </AreaChart>
            }
        />
    );
}

export default FillinsChart;