import React from "react";
import { useNotify } from 'react-admin';
import { GenericDatagridField, GenericDatagrid, ReportLayout, useReportContext } from '../../components';
import { formatNumber } from '../../utils/utils';
import moment from "moment";

const NewCustomersSummary = () => {
  const { data, error, setSort, sort, pagination, setPagination } = useReportContext();
  const notify = useNotify();

  if (error) { notify('Erro ao carregar Relatório de Novos Clientes - Resumo', 'warning'); };

  return (
    <>
      <GenericDatagrid
        style={{ margin: '0 0 50px 0' }}
        textAlign='center'
        data={data && [{ total: data.totalCustomers }]}
      >
        <GenericDatagridField label='Total de Clientes' render={({ total }) => formatNumber(total)} />
      </GenericDatagrid>
      <GenericDatagrid
        data={data && data.data}
        title='Relatório de Novos Clientes - Resumo'
        sort={sort}
        setSort={setSort}
        pagination={pagination}
        setPagination={setPagination}
        total={data && data.total}
        textAlign='center'
      >
        <GenericDatagridField label='Data' source='day' render={({ day }) => moment(day).format('DD/MM/YYYY')} />
        <GenericDatagridField label='Número de Novos Clientes' source='id' render={({ id }) => formatNumber(id)} />
      </GenericDatagrid>
    </>
  );
};

const NewCustomersSummaryReport = () =>
  <ReportLayout
    permissions={['admin']}
    resource='new-customers-summary'
    initialSort={{ field: 'day', order: 'DESC' }}
    initialPagination={{ page: 1, perPage: 10 }}
    initialFrom={moment().startOf('day').subtract(1, 'month')}
  >
    <NewCustomersSummary />
  </ReportLayout>;

export default NewCustomersSummaryReport;