import dataProvider from './dataProvider';
import { fetchUtils } from 'react-admin';

export const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const authorization = localStorage.getItem('authorization');
  options.headers.set('Authorization', authorization);
  return fetchUtils.fetchJson(url, options);
}

const provider = dataProvider(apiUrl, httpClient);
export default provider;