import React from "react";
import { useNotify } from 'react-admin';
import { GenericDatagridField, GenericDatagrid, ReportLayout, useReportContext } from '../../components';
import { formatNumber } from '../../utils/utils';
import moment from 'moment';

const StationComments = () => {
  const { data, error, setSort, sort, pagination, setPagination } = useReportContext();
  const notify = useNotify();

  if (error) { notify('Erro ao carregar Relatório de Comentários', 'warning'); };

  return (
    <>
      <GenericDatagrid
        style={{ margin: '0 0 50px 0' }}
        textAlign='center'
        data={data && [{ total: data.total }]}
      >
        <GenericDatagridField label='Total de Comentários' render={({ total }) => formatNumber(total)} />
      </GenericDatagrid>
      <GenericDatagrid
        data={data && data.data}
        title='Relatório de Comentários'
        sort={sort}
        setSort={setSort}
        pagination={pagination}
        setPagination={setPagination}
        total={data && data.total}
      >
        <GenericDatagridField label='Comentário' source='comment' />
        <GenericDatagridField label='Data' source='created_at' />
      </GenericDatagrid>
    </>);
};

const StationCommentsReport = () =>
  <ReportLayout
    stationSelect
    permissions={['manager']}
    resource='station-avaliation-comments'
    initialSort={{ field: 'created_at', order: 'DESC' }}
    initialPagination={{ page: 1, perPage: 10 }}
    initialFrom={moment().startOf('day').subtract(1, 'month')}
  >
    <StationComments />
  </ReportLayout>;

export default StationCommentsReport;