import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  DeleteButton,
  List,
  TextField,
  TextInput,
  SimpleForm,
  required,
  ImageInput,
  ImageField,
} from "react-admin";
import { Permission } from "../components";

import { SearchFilter } from "../components/SearchFilter";
import { makeStyles } from '@material-ui/core/styles';

const listStyles = makeStyles({
  image: {
    '& img': {
      maxWidth: '4rem',
    },
  },
});

export const ServiceList = ({ permissions, ...props }) => {
  const classes = listStyles();

  return (
    <List {...props}
      title="Serviços"
      filters={<SearchFilter />}>
      <Datagrid>
        <TextField source="name" label="Nome" />
        <TextField source="description" label="Descrição" />
        <ImageField className={classes.image} source="imagePath" label="Imagem" />
        <Permission permission="admin">
          <EditButton />
        </Permission>
        <Permission permission="admin">
          <DeleteButton />
        </Permission>
      </Datagrid>
    </List>
  );
}

export const ServiceEdit = ({ permissions, ...props }) => (
  <Edit {...props} title='Editar serviço' >
    <SimpleForm>
      <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
      <TextInput source="description" label="Descrição" />
      <ImageInput source="imagefile" label="Imagem" accept="image/*">
        <ImageField source="image" label="Imagem" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const ServiceCreate = ({ permissions, ...props }) => (
  <Create {...props} title='Adicionar serviço' >
    <SimpleForm>
      <TextInput source="name" label="Nome" validate={required("Nome é obrigatório")} />
      <TextInput source="description" label="Descrição" />
      <ImageInput source="imagefile" label="Imagem" accept="image/*">
        <ImageField source="image" label="Imagem" />
      </ImageInput>
    </SimpleForm>
  </Create>
);