import React from "react";
import {
    Datagrid,
    DateField,
    downloadCSV,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    useListContext,
    usePermissions
} from 'react-admin';
import { ReportList, ListPagination } from '../../components';
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { makeStyles, Typography } from '@material-ui/core';
import jsonExport from 'jsonexport/dist';
import moment from 'moment';
import { DateInput } from "../../components/DateInput";

const useStyles = makeStyles(theme => ({
    title: {
        margin: '10px 0 10px 0',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    headerCell: {
        backgroundColor: '#E5E5E5',
    }
}));

const PaymentByPixList = () => {
    const classes = useStyles();
    const props = useListContext();

    const pixPaymentInfo = props.data['payment-by-pix'];

    let data = {};
    let ids = [];
    let summaryData = {};

    if (pixPaymentInfo) {
        // convert array to object passing 'id' as key
        pixPaymentInfo.data.forEach(element => {
            data[element.id] = element;
        });

        ids = pixPaymentInfo.data.map(item => item.id);
        summaryData = { summary: pixPaymentInfo.summaryData };
    }

    return (
        <>
            <Typography className={classes.title}>Total</Typography>
            <Datagrid classes={{ headerCell: classes.headerCell }} style={{ marginBottom: 60 }} data={summaryData} ids={['summary']} total={1}>
                <NumberField
                    source="total_value"
                    label="Total em Dinheiro"
                    locales="pt-BR"
                    textAlign="center"
                    emptyText="R$ 0,00"
                    options={{
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }}
                    sortable={false}
                />
                <TextField source="total_fillins" textAlign="center" label="Total de abastecimentos" sortable={false} />
                <NumberField
                    source="average_ticket"
                    label="Ticket Médio"
                    locales="pt-BR"
                    textAlign="center"
                    emptyText="R$ 0,00"
                    options={{
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }}
                    sortable={false}
                />
            </Datagrid>

            <Typography className={classes.title}>Pagamentos</Typography>
            <CustomizableDatagrid classes={{ headerCell: classes.headerCell }} resource={props.resource} data={data} ids={ids} rowClick="show" defaultColumns={['station_id', 'chain_id', 'value', 'amount', 'price_per_liter', 'old_price_per_liter', 'status', 'fuel_id', 'created_at']}>
                <ReferenceField label="Posto" emptyText="Posto não indentificado" source="station_id" basePath="stations" reference="stations" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Cliente" emptyText="Não indentificado" source="customer_id" basePath="customers" reference="customers" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <NumberField
                    source="value"
                    label="Valor"
                    locales="pt-BR"
                    textAlign="center"
                    emptyText="R$ 0,000"
                    options={{
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }}
                />
                <TextField
                    source="status"
                    label="Status do pagamento"
                    textAlign="center"
                    emptyText="Indefinido"
                />
                <ReferenceField label="Combustível" emptyText="Não indentificado" source="fuel_id" basePath="fuels" reference="fuels" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <TextField
                    source="devedor_nome"
                    label="Nome do devedor"
                    textAlign="center"
                    emptyText="Indefinido"
                />
                <NumberField
                    source="amount"
                    label="Litros"
                    textAlign="center"
                    emptyText="0"
                    options={{
                        maximumFractionDigits: 2
                    }}
                />
                <DateField source="created_at" label="Data" showTime locales="pt-BR" />
                <TextField source="txid" />
            </CustomizableDatagrid>
        </>
    );
};

const paymentByPixExporter = async (data, fetchRelatedRecords) => {
    let pixPaymentInfo = data[0].data;

    const fetchAttendants = fetchRelatedRecords(pixPaymentInfo, 'attendant_id', 'station-attendants');
    const fetchFuels = fetchRelatedRecords(pixPaymentInfo, 'fuel_id', 'fuels');
    const fetchStations = fetchRelatedRecords(pixPaymentInfo, 'station_id', 'stations');
    const fetchCustomer = fetchRelatedRecords(pixPaymentInfo, 'customer_id', 'customers');

    const fetchData = await Promise.all([fetchAttendants, fetchFuels, fetchStations, fetchCustomer]);

    pixPaymentInfo = pixPaymentInfo.map(pixPayment => {
        const { attendant_id, fuel_id, station_id, customer_id, ...pixPaymentRest } = pixPayment;
        return {
            ...pixPaymentRest,
            attendant_name: attendant_id ? fetchData[0][attendant_id].name : 'Não indentificado',
            fuel_name: fuel_id ? fetchData[1][fuel_id].name : 'Não indentificado',
            station_name: station_id ? fetchData[2][station_id].name : 'Não indentificado',
            customer_name: customer_id ? fetchData[3][customer_id].name : 'Não indentificado',
        }
    });

    jsonExport(pixPaymentInfo, {
        headers: ['id', 'station_name', 'fuel_name', 'attendant_name', 'customer_name']
    }, (err, csv) => {
        downloadCSV(csv, 'pix-payments');
    });
}

const FilterTablePrint = () => {
    const classes = useStyles();
    const { filterValues } = useListContext();
    return (
        <Datagrid classes={{ headerCell: classes.headerCell }} data={{ filters: { ...filterValues, generatedReportAt: moment() } }} ids={['filters']} total={1}>
            <DateField source="from" textAlign="center" label="De" sortable={false} locales="pt-BR" />
            <DateField source="to" textAlign="center" label="Até" sortable={false} locales="pt-BR" />
            <ReferenceField label="Posto" emptyText="Todos" source="stationId" textAlign="center" basePath="stations" reference="stations" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Produto" emptyText="Todos" source="fuelId" textAlign="center" basePath="fuels" reference="fuels" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <DateField source="generatedReportAt" textAlign="center" label="Gerado às" showTime sortable={false} locales="pt-BR" />
        </Datagrid>
    );
}

const PaymentByPixReport = (props) => {
    const { permissions } = usePermissions();
    const statusChoices = [
        { id: 'ATIVA', name: 'Ativa' },
        { id: 'EXPIRADA', name: 'Expirada' },
        { id: 'CONCLUIDA', name: 'Concluída' },
    ];

    const listFilters = [
        <DateInput source="from" label="De" options={{ format: 'DD/MM/YYYY' }} alwaysOn />,
        <DateInput source="to" label="Até" options={{ format: 'DD/MM/YYYY' }} alwaysOn />,
        <ReferenceInput
            label="Posto"
            source="stationId"
            reference="stations"
            sort={{ field: "name", order: "ASC" }}
            emptyText="Todos"
            perPage={false}
            filter={permissions === 'manager' ? { chainId: localStorage.getItem('chainId') } : {}}
            alwaysOn
        >
            <SelectInput
                optionText={'name'}
            />
        </ReferenceInput>,
        <ReferenceInput label="Produto" source="fuelId" reference="fuels" perPage={false} sort={{ field: "name", order: "ASC" }} emptyText="Todos" alwaysOn>
            <SelectInput
                optionText={'name'}
            />
        </ReferenceInput>,
        <SelectInput
            label="Status"
            source="status"
            emptyText="Todos"
            alwaysOn
            choices={statusChoices}
        />
    ];

    if (permissions === 'admin') {
        listFilters.splice(2, 0,
            <ReferenceInput label="Rede" source="chainId" reference="chains" perPage={false} sort={{ field: 'name', order: 'ASC' }} emptyText="Todos" alwaysOn>
                <SelectInput
                    optionText={'name'} />
            </ReferenceInput>,
        )
    }

    return (
        <ReportList
            {...props}
            basePath="payment-by-pix-report"
            title="Pagamentos por Pix"
            resource="reports/payment-by-pix"
            filters={listFilters}
            filter={localStorage.getItem('chainId') ? { chainId: localStorage.getItem('chainId') } : null}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={25}
            filterDefaultValues={{ from: moment().startOf('day').toISOString(), to: moment().endOf('day').toISOString() }}
            pagination={<ListPagination />}
            bulkActionButtons={false}
            exporter={paymentByPixExporter}
            titleOnPrint="Relatório de pagamentos por Pix"
            filterTablePrint={<FilterTablePrint />}
            landscape={true}
        >
            <PaymentByPixList />
        </ReportList>)
};

export default PaymentByPixReport;