import React, { Fragment, useState } from 'react'
import moment from 'moment'
import {
  TextInput,
  SimpleForm,
  Toolbar,
  SaveButton,
  required,
  minLength,
  maxLength,
  useDataProvider,
  useNotify
} from 'react-admin'
import { apiUrl, httpClient } from '../../providers/apiProvider';
import { format as formatCpf } from '@fnando/cpf';

import Button from '@material-ui/core/Button'
import styles from './CouponSearch.module.scss'

interface ICoupon {
  id: string
  createdAt: string
  value: number
  refunded: boolean
  product: {
    id: string
    name: string
    description: string
    imagePath: string,
  }
  transaction: {
    customer: {
      name: string
      email: string
      cpf: string,
    },
  }
}

const EMPTY_COUPON = {
  id: '',
  createdAt: '',
  value: 0,
  refunded: false,
  product: {
    id: '',
    name: '',
    description: '',
    imagePath: '',
  },
  transaction: {
    customer: {
      name: '',
      email: '',
      cpf: '',
    },
  },
}

const validate = [required(), minLength(10, "Precisa ter 10 dígitos"), maxLength(10, "Precisa ter 10 dígitos")];

const CustomToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.invalid} label="Buscar" submitOnEnter={true} />
    </Toolbar>
  );
};

const CouponSearch = () => {
  const [coupon, setCoupon] = useState(EMPTY_COUPON);
  const [couponBarcode, setCouponBarcode] = useState("");
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const executeSearch = ({ id }) => {
    setCoupon(EMPTY_COUPON)
    dataProvider.getOne(`coupons`, { id })
      .then(({ data }) => {
        if (data.redeemed) {
          notify("Cupom já resgatado!", 'warning');
        } else if (data.refunded) {
          notify("Cupom já foi estornado!", 'warning')
        } else {
          // @ts-ignore
          setCoupon(data);
          setCouponBarcode("");
        }
      })
      .catch(({ message }) => { notify(message, 'warning'); });
  }

  const redeemCoupon = id => {
    httpClient(`${apiUrl}/coupons/${id}`, { method: 'PUT' })
      .then(() => {
        notify('Cupom resgatado com sucesso!')
        setCoupon(EMPTY_COUPON)
      })
      .catch(error => { notify(error && error.message, 'warning') });
  };

  const {
    transaction: { customer },
  } = coupon

  return (
    <Fragment>
      <SimpleForm toolbar={<CustomToolbar />} save={executeSearch}>
        <TextInput
          source="id"
          resettable={true}
          label="Identificador do cupom"
          validate={validate}
          value={couponBarcode}
          onChange={(event) => setCouponBarcode(event.target ? event.target.value : "")}
        />
      </SimpleForm>
      {Boolean(coupon.id.length) && (
        <div className={styles.container}>
          <div className={styles.productImage}>
            <img src={coupon.product.imagePath} />
          </div>
          <div className={styles.couponInfo}>
            <div>
              <span className={styles.info}>
                <b>Cupom: </b>
                <i>{coupon.id}</i>
              </span>
              <span className={styles.info}>
                <b>Data: </b>
                {moment(coupon.createdAt).format('DD/MM/YYYY')}
              </span>
            </div>
            <div>
              <span className={styles.info}>
                <b>Produto: </b>
                {coupon.product.name}
              </span>
              <span className={styles.info}>
                <b>Valor: </b>
                {`${coupon.value} pontos`}
              </span>
            </div>
            <span className={styles.info}>
              <b>Descrição: </b>
              {coupon.product.description}
            </span>
            {Boolean(coupon.refunded) ?
              <span className={styles.info}>
                <b>Cupom estornado</b>
              </span> :
              <Button
                className={styles.submit}
                variant="contained"
                color="primary"
                onClick={() => redeemCoupon(coupon.id)}>
                Resgatar
              </Button>
            }
          </div>
          <div className={styles.customerInfo}>
            <span className={styles.info}>
              <b>Cliente que efetuou a troca: </b>
            </span>
            <span className={styles.info}>
              <b>Nome: </b>
              {`${customer.name} ${customer.email ? `(${customer.email})` : ''}`}
            </span>
            <span className={styles.info}>
              <b>CPF: </b>
              {formatCpf(customer.cpf)}
            </span>
            <span className={styles.info}>
              <b>Data de Troca: </b>
              {moment(coupon.createdAt).format('DD/MM/YYYY - HH:mm')}
            </span>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default CouponSearch;