import React, { useState, useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { DashboardWrapper } from '../../components';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 280,
  },
  tableRow: {
    textDecoration: 'none',
  },
});

const RankStationsByRating = () => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [rankData, setRankData] = useState([]);

  useEffect(() => {
    dataProvider.getList('stations-rating/rank', {}).then(response => {
      setRankData(response.data);
    })
      .catch((e) => { notify('Erro ao carregar o rank de postos', 'warning'); });
  }, []);

  return (
    <DashboardWrapper
      permissions={['manager', 'admin']}
      title="Ranking de Avaliação dos Postos"
      content={
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableBody>
              {rankData
                ? rankData.map((s, index) => (
                  <TableRow
                    className={classes.tableRow}
                    key={s.id} hover={true}
                    component={Link} to={`/stations/${s.id}/show`}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {s.stationName}
                    </TableCell>
                    <TableCell align="right">{Number(s.rating).toFixed(2)}</TableCell>
                  </TableRow>
                )) : null}
            </TableBody>
          </Table>
        </TableContainer>
      }
    />
  );
};

export default RankStationsByRating;