import React, { useState } from 'react'
import { connect } from 'react-redux'
import { MenuItemLink, getResources, DashboardMenuItem } from 'react-admin'
import { withRouter } from 'react-router-dom'
import Domain from "@material-ui/icons/Domain";
import Inbox from "@material-ui/icons/Inbox";
import List from "@material-ui/icons/List";
import LocalGasStation from "@material-ui/icons/LocalGasStation";
import CategoryIcon from '@material-ui/icons/Category';
import Opacity from "@material-ui/icons/Opacity";
import Store from "@material-ui/icons/Store";
import People from "@material-ui/icons/People";
import Person from "@material-ui/icons/Person";
import Redeem from '@material-ui/icons/Redeem'
import SmsIcon from '@material-ui/icons/Sms';
import InsertChart from "@material-ui/icons/InsertChart";
import BallotIcon from '@material-ui/icons/Ballot';
import Permission from '../../components/Permission';
import Permissions from '../../components/Permissions';
import SubMenu from './SubMenu';
import StarsIcon from '@material-ui/icons/Stars';
import ConfirmationIcon from '@material-ui/icons/ConfirmationNumber'
import SettingsIcon from '@material-ui/icons//Settings';
import NotificationIcon from '@material-ui/icons/Notifications';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SecurityIcon from '@material-ui/icons/Security';
import Vehicle from "@material-ui/icons/DirectionsCar";

const Menu = ({ dense }) => {
  const [state, setState] = useState({
    menuRegister: false,
    menuContact: false,
    menuReports: false,
    menuMarketing: false,
    menuSupport: false,
    menuSecurity: false,
  });

  const handleToggle = (menu) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div>
      <Permissions permissions={['admin', 'manager', 'partner']}>
        <DashboardMenuItem />
      </Permissions>
      <Permissions permissions={['admin', 'manager']}>
        <SubMenu
          handleToggle={() => handleToggle('menuRegister')}
          isOpen={state.menuRegister}
          name="Cadastro"
          dense={dense}
        >
          <Permissions permissions={['admin', 'manager']}>
            <MenuItemLink
              to="/customers"
              primaryText="Clientes"
              leftIcon={<Person />}
            />
          </Permissions>
          <Permissions permissions={['admin', 'manager']}>
            <MenuItemLink
              to="/employees"
              primaryText="Funcionário"
              leftIcon={<People />}
            />
          </Permissions>
          <Permissions permissions={['admin', 'manager']}>
            <MenuItemLink
              to="/station-attendants"
              primaryText="Frentistas"
              leftIcon={<People />}
            />
          </Permissions>
          <Permission permission={'admin'}>
            <MenuItemLink
              to="/chains"
              primaryText="Redes de Postos"
              leftIcon={<Domain />}
            />
          </Permission>
          <MenuItemLink
            to="/stations"
            primaryText="Postos"
            leftIcon={<LocalGasStation />}
          />
          <Permission permission={'admin'}>
            <MenuItemLink
              to="/product-categories"
              primaryText="Categorias de Produto"
              leftIcon={<CategoryIcon />}
            />
          </Permission>
          <Permissions permissions={['admin', 'manager']}>
            <MenuItemLink
              to="/partners"
              primaryText="Parceiros"
              leftIcon={<Store />}
            />
          </Permissions>
          <Permission permission={'admin'}>
            <MenuItemLink
              to="/parent-fuels"
              primaryText="Combustíveis"
              leftIcon={<Opacity />}
            />
          </Permission>
          <Permission permission={'manager'}>
            <MenuItemLink
              to="/products"
              primaryText="Produtos"
              leftIcon={<Redeem />}
            />
          </Permission>
          <Permissions permissions={['admin', 'manager']}>
            <MenuItemLink
              to="/sale-products"
              primaryText="Produtos de Venda"
              leftIcon={<Redeem />}
            />
          </Permissions>
          <Permission permission={'admin'}>
            <MenuItemLink
              to="/notifications"
              primaryText="Notificações"
              leftIcon={<NotificationIcon />}
            />
          </Permission>
          <Permission permission={'admin'}>
            <MenuItemLink
              to="/services"
              primaryText="Serviços"
              leftIcon={<RoomServiceIcon />}
            />
          </Permission>
          <Permissions permissions={['admin', 'manager']}>
            <MenuItemLink
              to="/vehicles"
              primaryText="Veículos"
              leftIcon={<Vehicle />}
            />
          </Permissions>
        </SubMenu>
      </Permissions>
      <Permissions permissions={['admin']}>
        <SubMenu
          handleToggle={() => handleToggle('menuMarketing')}
          isOpen={state.menuMarketing}
          name="Marketing"
          dense={dense}
        >
          <Permission permission={'admin'}>
            <MenuItemLink
              to="/rules"
              primaryText="Regras"
              leftIcon={<BallotIcon />}
            />
          </Permission>
        </SubMenu>
      </Permissions>
      <Permissions permissions={['admin', 'manager']}>
        <SubMenu
          handleToggle={() => handleToggle('menuContact')}
          isOpen={state.menuContact}
          name="Contato"
          dense={dense}
        >
          <MenuItemLink
            to="/stations-rating"
            primaryText="Avaliação do Posto"
            leftIcon={<InsertChart />}
          />
          <Permissions permissions={['admin', 'manager']}>
            <MenuItemLink
              to="/sms"
              primaryText="SMS"
              leftIcon={<SmsIcon />}
            />
          </Permissions>
        </SubMenu>
      </Permissions>
      <Permission permission='cashier'>
        <MenuItemLink
          to="/validate-coupon"
          primaryText="Validar Cupom"
          leftIcon={<ConfirmationIcon />}
        />
      </Permission>
      <Permission permission='cashier'>
        <MenuItemLink
          to="/payments"
          primaryText="Pagamentos"
          leftIcon={<AttachMoneyIcon />}
        />
      </Permission>
      <SubMenu
        handleToggle={() => handleToggle('menuReports')}
        isOpen={state.menuReports}
        name="Relatórios"
        dense={dense}
      >
        <Permissions permissions={['admin', 'manager']}>
          <MenuItemLink
            to="/fillin-report"
            primaryText="Abastecimento"
            leftIcon={<InsertChart />}
          />
        </Permissions>
        <Permissions permissions={['admin', 'manager']}>
          <MenuItemLink
            to="/fuel-by-suply-report"
            primaryText="Abast. por Combustível"
            leftIcon={<InsertChart />}
          />
        </Permissions>
        <Permissions permissions={['admin', 'manager']}>
          <MenuItemLink
            to="/fuel-by-attendant-report"
            primaryText="Abast. por Frentista"
            leftIcon={<InsertChart />}
          />
        </Permissions>
        <Permissions permissions={['admin', 'manager']}>
          <MenuItemLink
            to="/station-rating-report"
            primaryText="Avaliação do posto"
            leftIcon={<InsertChart />}
          />
        </Permissions>
        <Permissions permissions={['admin', 'manager']}>
          <MenuItemLink
            to="/attendant-rating-report"
            primaryText="Avaliações dos frentistas"
            leftIcon={<InsertChart />}
          />
        </Permissions>
        <Permissions permissions={['admin', 'manager']}>
          <MenuItemLink
            to="/recurring-customer-report"
            primaryText="Clientes Recorrentes"
            leftIcon={<InsertChart />}
          />
        </Permissions>
        <Permission permission='manager'>
          <MenuItemLink
            to="/station-comments-report"
            primaryText="Comentários"
            leftIcon={<InsertChart />}
          />
        </Permission>
        <Permissions permissions={['admin', 'manager', 'cashier', 'partner']}>
          <MenuItemLink
            to="/coupons-report"
            primaryText="Cupons"
            leftIcon={<InsertChart />}
          />
        </Permissions>
        <Permission permission='admin'>
          <MenuItemLink
            to="/evolution-customer-report"
            primaryText="Evolução de Clientes"
            leftIcon={<InsertChart />}
          />
        </Permission>
        <Permission permission='admin'>
          <MenuItemLink
            to="/new-customer-report"
            primaryText="Novos Clientes"
            leftIcon={<InsertChart />}
          />
        </Permission>
        <Permission permission='admin'>
          <MenuItemLink
            to="/new-customer-summary-report"
            primaryText="Novos Clientes - Resumido"
            leftIcon={<InsertChart />}
          />
        </Permission>
        <Permissions permissions={['admin', 'manager']}>
          <MenuItemLink
            to="/payment-by-pix-report"
            primaryText="Pagamentos por Pix"
            leftIcon={<InsertChart />}
          />
        </Permissions>
        <Permissions permissions={['admin', 'manager']}>
          <MenuItemLink
            to="/first-fillin-report"
            primaryText="Primeiro Abastecimento"
            leftIcon={<InsertChart />}
          />
        </Permissions>
        <Permission permission='manager'>
          <MenuItemLink
            to="/average-ticket-report"
            primaryText="Ticket Médio"
            leftIcon={<InsertChart />}
          />
        </Permission>
        <Permission permission='admin'>
          <MenuItemLink
            to="/socket-error-report"
            primaryText="Erros"
            leftIcon={<InsertChart />}
          />
        </Permission>
      </SubMenu>
      <Permissions permissions={['admin', 'manager']}>
        <SubMenu
          handleToggle={() => handleToggle('menuSupport')}
          isOpen={state.menuSupport}
          name="Suporte"
          dense={dense}
        >
          <Permission permission='admin'>
            <MenuItemLink
              to="/support-tickets"
              primaryText="Chamados"
              leftIcon={<Inbox />}
            />
          </Permission>
          <Permission permission='admin'>
            <MenuItemLink
              to="/support-subjects"
              primaryText="Assuntos"
              leftIcon={<List />}
            />
          </Permission>
          <MenuItemLink
            to="/ratings"
            primaryText="Avaliações"
            leftIcon={<StarsIcon />}
          />
        </SubMenu>
      </Permissions>
      <Permissions permissions={["admin"]}>
        <SubMenu
          handleToggle={() => handleToggle('menuSecurity')}
          isOpen={state.menuSecurity}
          name="Segurança"
          dense={dense}
        >
          <MenuItemLink
            to="/potential-frauds"
            primaryText="Análise de Segurança"
            leftIcon={<SecurityIcon />}
          />
        </SubMenu>
      </Permissions>
      <Permission permission='admin'>
        <MenuItemLink
          to="/config"
          primaryText="Configurações"
          leftIcon={<SettingsIcon />}
        />
      </Permission>
    </div>
  )
}

const mapStateToProps = state => ({
  resources: getResources(state),
})

export default withRouter(connect(mapStateToProps)(Menu))
