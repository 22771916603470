import React, { Fragment } from "react";
import {
  TabbedForm,
  Create,
  Datagrid,
  Edit,
  List,
  TextField,
  TextInput,
  FormTab,
  Filter,
  ReferenceInput,
  SelectInput,
  NumberInput,
  ImageInput,
  ImageField,
  FormDataConsumer,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  SelectArrayInput,
  ReferenceArrayInput
} from "react-admin";
import { CEPInput, CNPJInput, PhoneInput, StateInput } from "../utils/Inputs";
import { makeStyles } from '@material-ui/core/styles';
import { TimePicker } from '../components';

const weekDays = ['Domingo', 'Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado'].map((d, i) => ({ id: i, name: d }));

const listStyles = makeStyles({
  image: {
    '& img': {
      maxWidth: '4rem',
    },
  },
  inputWrapperStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 0 20px 0',
  },
  input: {
    margin: '0 20px 0 0',
  },
  openingHourInput: {
    margin: '0 20px 0 0',
  },
  day: {
    fontSize: 16,
    margin: '20px 0'
  }
});

const PartnerFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <TextInput label="Pesquisar" source="search" alwaysOn />
    {permissions === 'admin' &&
      <ReferenceInput label="Rede" source="chainId" reference="chains" perPage={false} alwaysOn>
        <SelectInput resettable={true} optionText="name" />
      </ReferenceInput>}
  </Filter>
);

const required = (message = "Required") => (value) =>
  value ? undefined : message;

export const PartnerList = ({ permissions, ...props }) => {
  const classes = listStyles();

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      title="Parceiros"
      filter={localStorage.getItem('chainId') ? { chainId: localStorage.getItem('chainId') } : null}
      filters={<PartnerFilter permissions={permissions} />}
    >
      <Datagrid rowClick="edit">
        <ImageField className={classes.image} source="imagePath" label="Imagem quadrada" />
        <TextField source="name" label="Nome" />
        <TextField source="cnpj" label="CNPJ" />
        <TextField source="phone" label="Telefone" />
        <TextField source="contact" label="Contato" />
      </Datagrid>
    </List>
  );
};

export const PartnerEdit = ({ permissions, ...props }) => {
  const classes = listStyles();

  return (
    <Edit title="Editar parceiro" {...props}>
      <TabbedForm defaultValue={permissions === 'manager' && { chainId: localStorage.getItem('chainId') }}>
        <FormTab label="Identificação">
          <TextInput source="name" label="Nome" />
          <CEPInput source="cep" label="CEP" />
          <TextInput source="street" label="Rua" />
          <TextInput source="streetNumber" label="Número" />
          <TextInput source="neighbourhood" label="Bairro" />
          <TextInput source="city" label="Cidade" />
          <StateInput source="state" label="Estado" />
          <PhoneInput source="phone" label="Telefone" />
          <CNPJInput source="cnpj" label="CNPJ" />
          <TextInput source="contact" label="Contato" />
          <NumberInput source="latitude" label="Latitude" />
          <NumberInput source="longitude" label="Longitude" />
          {
            permissions === 'admin' &&
            <ReferenceInput label="Rede" source='chainId' reference="chains" perPage={false} validate={required()}>
              <SelectInput optionText="name" />
            </ReferenceInput>
          }
          <ImageInput source="imagefile" label="Imagem quadrada" accept="image/*">
            <ImageField source="image" label="Imagem" />
          </ImageInput>
          <ImageInput source="coverimagefile" label="Imagem retangular" accept="image/*">
            <ImageField source="image" label="Imagem" />
          </ImageInput>
        </FormTab>
        <FormTab label="Horários">
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.openingHours && formData.openingHours.length !== 7) {
                const registeredDays = formData.openingHours.map(record => record.dayOfTheWeek);
                const notRegisteredDays = [...weekDays].filter(wd => !registeredDays.includes(wd.id));
                const openingHours = [...formData.openingHours];
                notRegisteredDays.forEach(nrd => {
                  openingHours.push({ dayOfTheWeek: nrd.id, closed: true });
                });
                formData.openingHours = openingHours.sort((a, b) => {
                  if (a.dayOfTheWeek > b.dayOfTheWeek) {
                    return 1;
                  } else if (a.dayOfTheWeek < b.dayOfTheWeek) {
                    return -1;
                  }
                  return 0;
                })
              }
              if (formData.openingHours) {
                return (
                  <ArrayInput source="openingHours" label="">
                    <SimpleFormIterator disableAdd disableRemove>
                      <FormDataConsumer>
                        {({ scopedFormData }) => {
                          const day = weekDays.find(wd => wd.id === scopedFormData.dayOfTheWeek).name;
                          return (
                            <p className={classes.day}>{day}</p>
                          );
                        }}
                      </FormDataConsumer>
                      <FormDataConsumer>
                        {({ scopedFormData, getSource }) => {
                          return (
                            <div className={classes.inputWrapperStyle}>
                              <BooleanInput
                                source={getSource && getSource("closed")}
                                label='Fechado'
                                className={classes.input}
                              />
                              {scopedFormData && !scopedFormData.closed &&
                                <BooleanInput
                                  source={getSource && getSource("open24hrs")}
                                  label='Aberto 24 horas'
                                  className={classes.input}
                                />
                              }
                              {scopedFormData && !scopedFormData.closed && !scopedFormData.open24hrs &&
                                <Fragment>
                                  <TimePicker
                                    record={scopedFormData}
                                    variant='filled'
                                    label='Hora de abertura'
                                    source={getSource && getSource("startTime")}
                                    style={{ margin: '0 20px 0 0' }}
                                    fullDate
                                  />
                                  <TimePicker
                                    record={scopedFormData}
                                    variant='filled'
                                    label='Hora de fechamento'
                                    source={getSource && getSource("endTime")}
                                    fullDate
                                  />
                                </Fragment>
                              }
                            </div>
                          );
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                );
              }
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Configurações">
          <ReferenceArrayInput
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
            label="Serviços"
            source="serviceIds"
            reference="services"
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <BooleanInput label="Ativo" source="isActive" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const PartnerCreate = ({ permissions, ...props }) => {
  // const classes = listStyles();

  return (
    <Create title="Novo Parceiro" {...props}>
      <TabbedForm defaultValue={permissions === 'manager' && { chainId: localStorage.getItem('chainId') }}>
        <FormTab label="Identificação">
          <TextInput source="name" label="Nome" validate={required()} />
          <CEPInput source="cep" label="CEP" validate={required()} />
          <TextInput source="street" label="Rua" validate={required()} />
          <TextInput
            source="streetNumber"
            label="Número"
            validate={required()}
          />
          <TextInput
            source="neighbourhood"
            label="Bairro"
            validate={required()}
          />
          <TextInput source="city" label="Cidade" validate={required()} />
          <StateInput source="state" label="Estado" validate={required()} />
          <PhoneInput source="phone" label="Telefone" />
          <CNPJInput source="cnpj" label="CNPJ" validate={required()} />
          <TextInput source="contact" label="Contato" />
          <NumberInput source="latitude" label="Latitude" />
          <NumberInput source="longitude" label="Longitude" />
          {
            permissions === 'admin' &&
            <ReferenceInput label="Rede" source='chainId' reference="chains" perPage={false} validate={required()}>
              <SelectInput optionText="name" />
            </ReferenceInput>
          }
          <ImageInput source="imagefile" label="Imagem quadrada" accept="image/*">
            <ImageField source="image" label="Imagem" />
          </ImageInput>
          <ImageInput source="coverimagefile" label="Imagem retangular" accept="image/*">
            <ImageField source="image" label="Imagem" />
          </ImageInput>
          <BooleanInput label="Ativo" source="isActive" />
        </FormTab>
        {/* <FormTab label="Horários">
          <FormDataConsumer>
            {({ formData }) => {
              if (!formData.openingHours) {
                formData.openingHours = weekDays.map(wd => ({
                  closed: true,
                  dayOfTheWeek: wd.id,
                }));
              }
              return (
                <ArrayInput source="openingHours" label="">
                  <SimpleFormIterator disableAdd disableRemove>
                    <FormDataConsumer>
                      {({ scopedFormData }) => {
                        const day = weekDays.find(wd => wd.id === scopedFormData.dayOfTheWeek).name;
                        return (
                          <p className={classes.day}>{day}</p>
                        );
                      }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                      {({ scopedFormData, getSource }) => {
                        return (
                          <div className={classes.inputWrapperStyle}>
                            <BooleanInput
                              source={getSource && getSource("closed")}
                              label='Fechado'
                              className={classes.openingHourInput}
                            />
                            {scopedFormData && !scopedFormData.closed &&
                              <BooleanInput
                                source={getSource && getSource("open24hrs")}
                                label='Aberto 24 horas'
                                className={classes.openingHourInput}
                              />
                            }
                            {scopedFormData && !scopedFormData.closed && !scopedFormData.open24hrs &&
                              <Fragment>
                                <TimePicker
                                  record={scopedFormData}
                                  variant='filled'
                                  label='Hora de abertura'
                                  source={getSource && getSource("startTime")}
                                  style={{ margin: '0 20px 0 0' }}
                                  fullDate
                                />
                                <TimePicker
                                  record={scopedFormData}
                                  variant='filled'
                                  label='Hora de fechamento'
                                  source={getSource && getSource("endTime")}
                                  fullDate
                                />
                              </Fragment>
                            }
                          </div>
                        );
                      }}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              );
            }}
          </FormDataConsumer>
        </FormTab> */}
        {/* <FormTab label="Configurações">
          <ReferenceArrayInput
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
            label="Serviços"
            source="serviceIds"
            reference="services"
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
        </FormTab> */}
      </TabbedForm >
    </Create >
  );
};
