import React from 'react';
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  DeleteButton,
  List,
  SimpleForm,
  TextField,
  Filter,
  TextInput,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  Button
} from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

const AddNewButton = ({ record }) => (
  <Button
    component={Link}
    variant="raised"
    to={`/fuels/create?parentFuelId=${record.id}&acronym=${record.acronym}`}
    label="Adicionar combustível filho"
    title="Adicionar combustível filho"
  >
    <AddIcon />
  </Button>
);

const FuelsList = ({ permissions, ...props }) => (
  <Show
    {...props}
    /* disable the app title change when shown */
    title=' '
  >
    <SimpleShowLayout>
      <ReferenceManyField reference="fuels" target="parentFuelId" addLabel={false}>
        <Datagrid rowClick="show">
          <TextField source="acronym" label="Sigla" />
          <TextField source="name" label="Nome" />
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </ReferenceManyField>
      {permissions === 'admin' && <AddNewButton />}
    </SimpleShowLayout>
  </Show>
);

const required = (message = 'Required') =>
  value => value ? undefined : message;

const ParentFuelFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Pesquisar" source="search" alwaysOn />
  </Filter>
);

export const ParentFuelList = (props) => (
  <List {...props} title="Combustíveis" filters={<ParentFuelFilter />} >
    <Datagrid expand={<FuelsList />}>
      <TextField source="acronym" label="Sigla" />
      <TextField source="name" label="Nome" />

      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List >
);

export const ParentFuelEdit = (props) => (
  <Edit title='Editar combustível' {...props}>
    <SimpleForm>
      <TextInput disabled label="ID" source="id" />
      <TextInput source="acronym" label="Sigla" maxlength="3" />
      <TextInput source="name" label="Nome" />
    </SimpleForm>
  </Edit>
);

export const ParentFuelCreate = (props) => (
  <Create title='Adicionar combustível' {...props}>
    <SimpleForm>
      <TextInput source="acronym" label="Sigla" maxLength="3" validate={required()} />
      <TextInput source="name" label="Nome" validate={required()} />
    </SimpleForm>
  </Create>
);