import { apiUrl } from '../providers/apiProvider';

const authProvider = {
  // called when the user attempts to log in
  login: params => {
    const { username, password } = params;
    const request = new Request(`${apiUrl}/employees/login`, {
      method: 'POST',
      body: JSON.stringify({ login: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ authorization, role, id, employeeName, chainId, chainName, stationId }) => {
        localStorage.setItem('id', id);
        localStorage.setItem('fullName', employeeName);
        localStorage.setItem('authorization', authorization);
        localStorage.setItem('role', role);
        if (chainId) {
          localStorage.setItem('chainId', chainId);
          localStorage.setItem('chainName', chainName);
        } else {
          localStorage.removeItem('chainId');
          localStorage.removeItem('chainName');
        }
        if (stationId) {
          localStorage.setItem('stationId', stationId);
        } else {
          localStorage.removeItem('stationId');
        }
      });
  },
  // called when the user clicks on the logout button
  logout: params => {
    localStorage.removeItem('id')
    localStorage.removeItem('fullName')
    localStorage.removeItem('authorization');
    localStorage.removeItem('chainId');
    localStorage.removeItem('role');
    localStorage.removeItem('chainName');
    localStorage.removeItem('stationId');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: error => {
    const { status } = error;
    if (status === 401 || status === 403) {
      localStorage.removeItem('authorization');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location
  checkAuth: params => {
    return localStorage.getItem('authorization')
      ? Promise.resolve()
      : Promise.reject();
  },

  getPermissions: params => {
    const role = localStorage.getItem('role');
    return role ? Promise.resolve(role) : Promise.reject();
  },

  getIdentity: params => {
    const id = localStorage.getItem('id');
    const fullName = localStorage.getItem('fullName');
    return Promise.resolve({ id, fullName });
  }
};

export default authProvider;